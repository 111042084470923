import { FETCH_LOGIN_USER_REQUEST, FETCH_LOGIN_USER_SUCCESS, FETCH_LOGIN_USER_FAILURE, LOGOUT } from '../actions/types';
import { FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster'
import { Intent } from "@blueprintjs/core";

const initialState = {
    loading: false,
    user: [],
    success: false
}

export function fetchLoginUser( state=initialState, action ){
    switch( action.type ){
        case FETCH_LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_LOGIN_USER_SUCCESS:

            console.log( "=== FETCH_LOGIN_USER_SUCCESS ===" )

            localStorage.setItem('session', action.payload.data.token );
            AppToaster.show({ message: `Bienvenido ${ action.payload.data.user.name }` });

            return {
                loading: false,
                user: action.payload.data.user,
                success: true
            }

        case FETCH_LOGIN_USER_FAILURE:
            console.log( "FETCH_LOGIN_USER_FAILURE" )

            if ( action.payload.response.data.error ) {

                if( action.payload.response.data.error === "invalid_credentials"){
                    AppToaster.show({ message: "Email y/o password incorrectos", intent: Intent.DANGER, });
                } else {
                    AppToaster.show({ message: action.payload.response.data.error, intent: Intent.DANGER, });
                }

            } else {

                const errors = [];

                for (const key of Object.keys(action.payload.response.data.errors)) {
                    action.payload.response.data.errors[key].forEach( err => {
                        errors.push(err)
                    });

                }

                errors.forEach((item, i) => {
                    if(item === "The login must be a valid email address.") {
                        AppToaster.show({ message: "Debes proporcionar un email válido.", intent: Intent.DANGER, });
                    } else if (item === "The login must be between 6 and 255 characters." ) {
                        AppToaster.show({ message: "El email debe de ser entre 6 y 255 caracteres.", intent: Intent.DANGER, });
                    } else if (item === "The password must be between 4 and 255 characters.") {
                        AppToaster.show({ message: "El password debe de ser entre 8 and 64 characters.", intent: Intent.DANGER, });
                    } else {
                        AppToaster.show({ message: item, intent: Intent.DANGER, });
                    }

                });


            }

            return  {
                loading: false,
                users:[],
                success: false
            }

        case FETCH_USER_SUCCESS:

            console.log( "=== FETCH_USER_SUCCESS ===" )

            return {
                loading: false,
                user: action.payload.data.user,
                success: true
            }

        case FETCH_USER_FAILURE:

            console.log( "FETCH_USER_FAILURE" )

            localStorage.removeItem("session")
            AppToaster.show({ message: "Tu sesión ha terminado. Por favor inicia sesión de nuevo", intent: Intent.DANGER, });

            return  {
                loading: false,
                users:[],
                success: false,
            }

        case LOGOUT:

            console.log( "LOGOUT" )
            localStorage.removeItem("session")
            AppToaster.show({ message: "Tu sesión ha terminado", intent: Intent.DANGER, });

            return  {
                loading: false,
                users:[],
                success: false,
            }

        default:
            return state;
    }
}
