import React from 'react';

const Isotipo = () => {

    return (

        <div>
            <svg className="isotipo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 23.5">
                <circle className="isotipo-circle" cx="11.7" cy="11.7" r="11.4"/>
                <g>
                	<path className="isotipo-cat" d="M11.6,14.7L11.6,14.7c0,0,0-0.1,0-0.1c0.1-0.1,0.4-0.1,0.6-0.1c0.3,0,0.5,0.1,0.6,0.2c0.1,0.1,0,0.3-0.2,1.3
                		c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.4-0.2,0.5-0.3,0.5c-0.2,0-0.3-0.2-0.4-0.6C11.6,15.8,11.6,14.8,11.6,14.7z M9.5,15.1L9.5,15.1
                		c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0
                		c0,0,0.2,0.1,0.3,0.1c0.2,0,0.2-0.3,0.2-0.8v-0.1l-0.1,0c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0
                		c-0.1,0-0.2,0-0.2,0c0,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.2,0-0.3,0-0.3,0
                		c0,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0l-0.2,0c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.6-0.1-0.7,0.1c0,0-0.1,0.1,0,0.1c0,0,0.1,0.9,0.2,1.4
                		c0,0.2,0.2,0.9,0.4,1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.9,0-1.3c0,0,0.1,0.1,0.2,0.1
                		c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0.1,0.2,0.1C9.3,15.2,9.4,15.2,9.5,15.1z M13.2,19.6
                		c0,0-0.1-0.1-0.1-0.2c-0.2-0.4-0.4-0.7-0.6-0.7c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.6-0.1,1c-0.1-0.1-0.2-0.1-0.2-0.1
                		c-0.1,0-0.2,0-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0
                		c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
                		c0.1-0.2,0.3-0.4,0.3-0.5c0-0.1,0-0.2,0-0.3l-0.1,0l-0.1,0c0,0-0.3,0.2-0.6,0c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.3-0.2-0.4-0.2
                		c0,0-0.1,0-0.1,0.1c-0.1,0.2,0,0.7,0.2,1C9.5,19.7,9.8,20,10,20c0,0,0,0,0,0h0c0,0.1,0.2,0.2,0.2,0.2l0,0c0,0,0.1,0.1,0.2,0.1
                		c0.1,0,0.1,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.3,0h0c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.1
                		c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0-0.1c0.1,0.1,0.2,0.1,0.3,0.1
                		c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3C13.3,19.8,13.3,19.7,13.2,19.6z M17.7,8c-0.2,0-0.8,0.2-1.5,0.2c-0.7,0-1.7-0.1-1.8-0.3
                		c-0.1-0.1-0.4-0.9-0.1-1.8s1.2-1.6,2.1-1.6c0.9,0,1.7,0.3,2,1.1C19,6.6,18,8,17.7,8z M16.7,5.8c0-0.4-0.3-1.1-0.6-1.1
                		c-0.3,0-0.5,1-0.5,1.4c0,0.4,0.4,1.2,0.5,1.2C16.4,7.3,16.7,6.2,16.7,5.8z M7.6,7.7c0,0-0.5,0.2-0.9,0.3C6.3,8,5.6,8.1,5.4,8
                		C5.2,7.9,4.9,7.4,4.9,6.3c0-1,1.2-1.6,1.4-1.6c0.2,0,0.8,0.3,1.1,1.1C7.7,6.5,7.7,7.6,7.6,7.7z M6.7,5.3C6.4,5.2,6,5.8,5.9,6.2
                		s0.1,1,0.3,1.1c0.2,0.1,0.4-0.9,0.5-1.1C6.8,6,6.8,5.3,6.7,5.3z"/>
                </g>
            </svg>
        </div>

    )

}

export default Isotipo;
