import { FETCH_UPDATE_ADDRESS_REQUEST, FETCH_UPDATE_ADDRESS_SUCCESS, FETCH_UPDATE_ADDRESS_FAILURE } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster'
import { Intent } from "@blueprintjs/core";


const initialState = {
    loading: false,
    success: false,
    error: 0
}

export function fetchUpdateAddress( state=initialState, action ){
    switch( action.type ){
        case FETCH_UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_UPDATE_ADDRESS_SUCCESS:

            AppToaster.show({ message: "Tu dirección de envío se ha actualizando correctamente", intent: Intent.PRIMARY, });

            return {
                loading: false,
                success: true,
                error: ''
            }

        case FETCH_UPDATE_ADDRESS_FAILURE:

            AppToaster.show({ message: "Hubo un error actualizando tu dirección. Inténtalo de nuevo más tarde.", intent: Intent.DANGER, });
            console.log("FETCH_UPDATE_ADDRESS_FAILURE", action.payload.response )

            return  {
                loading: false,
                success:false,
                error: action.payload.response.status
            }

        default:
            return state;
    }
}
