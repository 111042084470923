import React from 'react';
import { H1, H4 } from "@blueprintjs/core";

const AvisoDePrivacidad = () => {

    const getData = () => {

        const moreMargin = {
            marginTop: '2rem'
        }

        return (
            <div>
                <H1 className="text-center" style={{ margin:'3rem auto' }}>Aviso de Privacidad</H1>

                <p>SUPERSTICIÓN MX S.A. de C.V. Expide el presente Aviso de Privacidad para que el titular de los datos personales, de ahora en adelante denominado “Titular”, tenga conocimiento del trato que la Empresa les da a los datos personales.</p>
                <p>En SUPERSTICIÓN MX nos preocupamos mucho por tu privacidad y por mantener a salvo la información personal que compartes con nosotros. Protegemos y salvaguardamos los datos personales del Titular para evitar el daño pérdida, destrucción, robo, extravío, alteración, así como el tratamiento no autorizado, en cumplimento a lo establecido por los artículos 8, 15, 16, 33, 36 y demás relativos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
                <p>Cualquier duda se puede comunicar con la dirección de correo electrónico supersticion87@gmail.com</p>

                <H4 style={ moreMargin } >Intención del Aviso de Privacidad</H4>
                <p>El presente Aviso de Privacidad se pone a su disposición con la intención del Titular tenga conocimiento del tratamiento que se dará a los mismos, así como la información precisa para ejercer sus derechos de acceso, rectificación, cancelación y oposición (en adelante “Derechos ARCO”), los cuales a continuación se describen:</p>

                <ol>
                    <li>Derecho a acceder a sus datos personales en poder de SUPERSTICIÓN MX , a excepción de los casos mencionados en la Ley.</li>
                    <li>Derecho a rectificar sus datos personales cuando estos sean inexactos o incompletos.</li>
                    <li>Derecho en todo momento a cancelar sus datos personales. SUPERSTICIÓN MX podrá negar la cancelación de los datos en los términos que establece la Ley.</li>
                    <li>Derecho en todo momento y por causa legítima a oponerse al tratamiento.</li>
                </ol>

                <p>Es importante informarle que la Ley Federal de Protección de Datos Personales en Posesión de los Particulares protege su información personal de usos no autorizados y sin su consentimiento, por lo que el presente documento hará saber la información que recabamos del Titular, para qué y cómo la usamos, las posibles transferencias a terceros, las finalidades del tratamiento de los datos, sus derechos ARCO, así como la revocación de su consentimiento, los cuales el Titular puede hacer valer ante SUPERSTICIÓN MX con el objetivo de que tenga pleno control y decisión sobre sus datos personales. Por éste motivo, recomendamos que se lea atentamente la siguiente información:</p>
                <p>El presente Aviso de Privacidad es aplicable para los Titulares de Datos Personales obtenidos directa, indirecta o personalmente por SUPERSTICIÓN MX, a través de procesos de compra-venta de productos, contratos, cartas, solicitudes de información, así como de los distintos formularios contenidos en el Página Web de la Empresa www.supersticion.mx (en adelante “Sitio Web” y/o “Página Web”) o cualquier otro medio especificado para tales efectos, que hagan referencia al presente Aviso de Privacidad.</p>

                <H4 style={ moreMargin } >Datos Personales</H4>
                <p>Los Datos Personales se proporcionan a través de la creación de una Cuenta o el Registro de Usuario, y / o al enviar una solicitud de contacto a través del formulario de contacto en la página de atención al cliente. La Empresa recaba información de identificación personal que es cedida voluntariamente por el Usuario, o en respuesta a requerimientos explícitos por parte de la Empresa.</p>
                <p>La información deberá ser veraz y completa. El usuario responderá en todo momento por los datos proporcionados y en ningún caso SUPERSTICIÓN MX  será responsable de la veracidad de los mismos.</p>
                <p>La información solicitada es la siguiente: Nombre, Domicilio, Fecha de Nacimiento, Género, Transacciones, Cuenta de Correo Electrónico, Número (s) Telefonico(s), Datos Fiscales para Elaboración de Facturas, Número de tarjeta bancaria, y identificadores de Cuentas Bancarias.</p>
                <p>SUPERSTICIÓN MX no solicita Datos Personales Sensibles en el Sitio Web que administra.</p>

                <H4 style={ moreMargin } >Cookies</H4>
                <p>El Usuario de la Página de Internet de la Empresa conoce y acepta que la Empresa podrá utilizar un sistema de seguimiento mediante la utilización de cookies (las “Cookies”).</p>
                <p>Las Cookies son archivos de datos que se almacenan en el disco duro del equipo de cómputo o del dispositivo de comunicaciones electrónicas de un usuario al navegar en un sitio de Internet, el cual permite intercambiar información de estado entre dicho sitio y el navegador del usuario. La información de estado puede revelar medios de identificación de sesión, autenticación o preferencias del usuario, así como cualquier dato almacenado por el navegador respecto al sitio de</p> Internet.
                <p>Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demografía de quienes visitan o son visitantes de la Página de Internet y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle información relacionada. También ofrecemos ciertas funcionalidades que sólo están disponibles mediante el empleo de Cookies.</p>
                <p>También usaremos la información obtenida por intermedio de las Cookies para analizar las páginas navegadas por el visitante o Usuario, las búsquedas realizadas, mejorar nuestras iniciativas comerciales y promocionales, mostrar publicidad o promociones, banners de interés, perfeccionar nuestra oferta de contenidos y artículos, personalizar dichos contenidos, presentación y servicios.</p>
                <p>Las Cookies también se usan para que el Usuario no tenga que introducir su clave tan frecuentemente durante una sesión de navegación, también para contabilizar y corroborar los registros, la actividad del Usuario y otros conceptos y acuerdos comerciales, siempre teniendo como objetivo de la instalación de las Cookies, el beneficio del Usuario que la recibe, y no será usado con otros fines ajenos a la Empresa.</p>
                <p>Se establece que la instalación, permanencia y existencia de las Cookies en el computador del Usuario o del visitante depende de su exclusiva voluntad y puede ser eliminada de su computador cuando así lo desee. Para saber cómo quitar las Cookies del sistema es necesario revisar la sección Ayuda (Help) del navegador. También, se pueden encontrar Cookies u otros sistemas similares instalados por terceros en ciertas páginas de nuestro Sitio. La Empresa no controla el uso de Cookies por terceros.</p>
                <p>Si deseas obtener más información acerca de las cookies, ve a http://www.allaboutcookies.org.</p>
                <p>La Empresa también utiliza Web Beacons al momento en que el usuario está utilizando la Página. Los Web Beacons son imágenes visibles u ocultas insertadas dentro de un sitio Web, que se utiliza para monitorear el comportamiento del usuario en estos medios, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.</p>

                <H4 style={ moreMargin } >Protección</H4>
                <p>La seguridad y la confidencialidad de los datos que los usuarios proporcionen al contratar un servicio o comprar un producto en línea estarán protegidos por un servidor seguro bajo el protocolo Secure Socket Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados para asegurar su resguardo.</p>

                <p>Para verificar que se encuentra en un entorno protegido, asegúrese de que aparezca una “S” en la barra de navegación “httpS”://.</p>

                <p>Sin embargo, y a pesar de contar cada día con herramientas más seguras, la protección de los datos enviados a través de Internet no se puede garantizar al 100%; por lo que una vez recibidos, se hará todo lo posible por salvaguardar la información.</p>

                <p>Para garantizar que los datos personales sean tratados conforme a lo dispuesto en la Ley, SUPERSTICIÓN MX cumple con los siguientes principios:</p>

                <ol>
                    <li>Se le comunicaran las finalidades del tratamiento de sus datos personales recabando solo los datos necesarios para el cumplimiento de estas finalidades y no se les dará uso distinto al establecido en el presente aviso de privacidad.</li>
                    <li>Se realizara el mantenimiento adecuado para que sus datos personales sean correctos y actualizados, en caso de que sea necesario conservar sus datos será conforme a lo dispuesto por la Ley aplicable.</li>
                    <li>Se han implementado medidas de seguridad para garantizar la protección de sus datos personales.</li>
                </ol>

                <H4 style={ moreMargin } >Bases de Datos</H4>
                <p>La Base de Datos de la Empresa, donde se almacena la información recabada, mantendrá guardada y resguardada la Información proporcionada por el Titular, a pesar que sean modificadas, actualizadas, o incluso canceladas por cualquier motivo. Lo anterior con fines de mantener un Historial sobre el Titular y tutelando sus intereses jurídicos. La conservación de información señalada en este párrafo podrá ser borrada o eliminada en su totalidad mediante el ejercicio de los Derechos ARCO.</p>

                <p>Para darse de baja de nuestros boletines, por favor haga clic en el siguiente enlace se encuentra dentro de nuestros mensajes de correo electrónico: “Unsubscribe from this list”.</p>

                <p>Finalidades del Tratamiento de los Datos Personales</p>
                <p>La Información Personal es recabada y almacenada para fines de</p>

                <ul>
                    <li>Identificación y certeza del Usuario de la Página Web;</li>
                    <li>Funcionamiento, gestión, envío de mercancías, devoluciones, facturación, cobranza, administración, prestación de nuestros servicios;</li>
                    <li>Entrega de notificaciones, requerimientos, cartas o boletines informativos o atención a sus solicitudes relacionadas con los servicios que prestamos;</li>
                    <li>Ayudar a completar una transacción o pedido que se haya iniciado en el sitio web;</li>
                    <li>Analice del comportamiento y la demografía de los Usuarios;</li>
                    <li>Mejorar nuestras iniciativas comerciales y promocionales (mercadotecnia);</li>
                    <li>Enviar información o mensajes sobre nuevos productos y/o servicios, información de nuestros socios de negocios así como cualquier otra información;</li>
                    <li>Mostrar publicidad o promociones de interés para nuestros Usuarios;</li>
                    <li>Transferencia de información del Titular en los casos aplicables de conformidad con el apartado “Transferencia de Información con Terceros” del presente Aviso;</li>
                    <li>Precencion o denuncia ante diferentes autoridades de actos o hechos ilícitos;</li>
                    <li>Dar cumplimiento al ejercicio de derechos ARCO así como revocar el consentimiento del Titular.</li>
                </ul>

                <p>Con lo anterior, la Empresa puede proporcionar un servicio eficiente al Usuario.</p>

                <p>Las finalidades son necesarias para el cumplimiento de las obligaciones y la relación con el Titular, esto es que la Empresa proporcione un servicio eficiente al Usuario, siendo esta la obligación principal y que dio origen a la Relación Jurídica entre la Empresa y el Usuario.</p>
                <p>La recolección de información permite a la Empresa ofrecer servicios y funcionalidades que se adecuan mejor a las necesidades del Usuario. Asimismo le permite a la Empresa hacerle llegar al Usuario por distintos medios y vías (incluyendo correo convencional o electrónico mail, SMS, MSM, notificaciones electrónicas) ofertas de productos y servicios que pueden ser del interés del Usuario, además de cumplir con sus obligaciones por estos medios.</p>
                <p>La Empresa se reserva el derecho de solicitar algún comprobante y/o dato adicional a efecto de corroborar los datos personales de cada Usuario, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</p>
                <p>El Usuario reconoce que la cuenta o Registro de Usuario es personal, única e intransferible, y está prohibido que un mismo Usuario inscriba o posea más de una cuenta. En caso que la Empresa detecte distintas cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas. El Usuario será responsable por todas las operaciones efectuadas en su cuenta, pues el acceso a la misma está restringido al ingreso y uso de su contraseña personal, de conocimiento exclusivo del Usuario. En caso que la cuenta sea suspendida, la Empresa almacenará los datos personales del Usuario, a efecto que el Usuario y la Empresa seguirán sujetos a lo señalado en el presente Aviso de Privacidad.</p>
                <p>En caso dado de que la información del Usuario al momento de la inscripción estuviese equivocada o incompleta, siendo que se imposibilite la comprobación e identificación del Usuario, la Empresa tendrá el derecho de suspender inmediatamente la prestación de los Servicios por medio de la Página Web, sin necesidad de previo aviso, responsabilizándose en todo momento el Usuario por las pérdidas y daños finalmente sufridos.</p>

                <H4 style={ moreMargin } >Confidencialidad</H4>
                <p>Los datos de los Titulares serán suministrados únicamente por la Empresa en las formas establecidas en este Aviso de Privacidad. La Empresa hará todo lo que esté a su alcance para proteger la privacidad de la información. Puede suceder que en virtud de órdenes judiciales, o de regulaciones legales, la Empresa se vea compelida a revelar información a las autoridades o terceras partes bajo ciertas circunstancias, o bien en casos que terceras partes puedan interceptar o acceder a cierta información o transmisiones de datos en cuyo caso la Empresa no responderá por la información que sea revelada. En estos casos, la Empresa notificará al Titular sobre esta situación.</p>

                <p>No asumimos ninguna obligación de mantener confidencial cualquier otra información que el Titular proporcione a través de boletines y pláticas en línea (chats), así como la información que obtenga a través de los cookies, información que no haya sido directamente entregada a la Empresa.</p>
                <p>Se debe aclarar que NO se vende, regala, facilita ni alquila la información del Titular a ningún tercero. Si el usuario no desea que sus datos sean compartidos, puede decidir NO utilizar un servicio determinado o NO participar en algunas promociones o concursos.</p>
                <p>La Empresa tomará todas las medidas posibles para mantener la confidencialidad y la seguridad descritas anteriormente, pero no responderá por los daños o perjuicios que pudieran derivarse de la violación de esas medidas por parte de terceros que utilicen las redes públicas o el Internet, alterando los sistemas de seguridad para obtener acceso a la información de los Titulares.</p>
                <p>Las vulneraciones de seguridad ocurridas en cualquier fase del tratamiento que afecten de forma significativa los derechos patrimoniales o morales de los Titulares, serán informadas de forma inmediata por la Empresa al Titular, a fin de que este último pueda tomar las medidas correspondientes a la defensa de sus derechos.</p>

                <H4 style={ moreMargin } >Transferencia de Información con Terceros</H4>
                <p>El Titular autoriza expresamente a la Empresa a mantener en su registro las informaciones proporcionadas por el Titular, también autoriza a la Empresa a proporcionar información constante sobre el referido registro a (i) autoridades que lo solicitaren conforme lo permitido por la legislación en vigor y (ii) a sus asociados estratégicos, comerciales o técnicos con la finalidad de ofrecer mejores condiciones de Promociones y/o contenidos del Usuario. Además, el Usuario permite expresamente a la Empresa a recopilar información para la realización de seguimiento de tráfico, con intención de identificar grupos y perfiles de usuarios, así como para fines de orientación publicitaria.</p>

                <p>Las Transferencias de Datos son con fines lícitos y acorde a lo señalado en la Ley de Protección de Datos Personales en Posesión de los Particulares.</p>

                <p>La Empresa declara que no transferirá ningún tipo de información del Titular a Terceros nacionales o extranjeros. En caso contrario, se le hará del conocimiento al Titular mediante la publicación de un nuevo aviso de Privacidad. Lo anterior de acuerdo con lo previsto en el artículo 36 del Reglamento de la Ley de Protección de Datos Personales en Posesión de Particulares.</p>

                <H4 style={ moreMargin } >Transferencia en circunstancias especiales</H4>
                <p>Si existe una venta, una fusión, consolidación, cambio en el control societario, transferencia de activos sustancial, reorganización o liquidación de la Empresa entonces podemos transferir, o asignar la información recabada en este Sitio Web a una o más partes relevantes, además de obligarnos a expedir un nuevo Aviso de Privacidad actualizado a los cambios internos sufridos.</p>

                <H4 style={ moreMargin } >Remisión de Datos</H4>
                <p>La Empresa declara que puede comunicar datos personales del Titular con el Encargado, dentro o fuera del territorio nacional en términos de lo dispuesto en la ley o el Reglamento.</p>
                <p>El Encargado es persona física o moral, pública o privada, ajena a la organización de la Empresa, que sola o conjuntamente con otras, trata datos personales por cuenta de la Empresa, como consecuencia de la existencia de una relación jurídica que le vincula con la misma y delimita el ámbito de su actuación para la prestación de un servicio.</p>
                <p>Esta remisión de datos es para cumplir con los fines especificados en el tratamiento, mismos directamente relacionados con el cumplimiento de los objetos y relaciones contractuales o extracontractuales con la Empresa.</p>
                <p>El tratamiento de datos en esta remisión está sujeto a los siguientes términos acordados entre la empresa y el encargado:</p>

                <ul>
                    <li>Tratar únicamente los datos personales conforme a las instrucciones de la Empresa;</li>
                    <li>Abstenerse de tratar los datos personales para finalidades distintas a las instruidas por la Empresa;</li>
                    <li>Implementar las medidas de seguridad conforme a la Ley, el Reglamento y las demás disposiciones aplicables;</li>
                    <li>Guardar confidencialidad respecto de los datos personales tratados;</li>
                    <li>Suprimir los datos personales objeto de tratamiento una vez cumplida la relación jurídica con la Empresa o por instrucciones de la Empresa, siempre y cuando no exista una previsión legal que exija la conservación de los datos personales;</li>
                    <li>Abstenerse de transferir los datos personales.</li>
                </ul>

                <p>El ejercicio del Titular de sus derechos ARCO o cualquier otro derecho señalado en la ley o reglamento, será acorde al presente Aviso de Privacidad.</p>

                <H4 style={ moreMargin } >Limite de uso o divulgación de Datos Personales</H4>
                <p>Para que el Usuario ejerza su derecho de limitar el uso o divulgación de los Datos Personales, pero con intención de continuar usando de los servicios que presta la Empresa, el usuario debe de manifestarlo mediante un escrito al siguiente correo electrónico supersticion87@gmail.com Dicho escrito debe contener lo siguiente:</p>

                <p>El nombre del titular y correo electrónico donde reciba la respuesta.• Dirigida a la Empresa.• Registro de cliente o cuenta con la Empresa para acreditar su identidad o la relación que sostiene con la Empresa.• La descripción clara y precisa de los datos que desea limitar de su uso o divulgación.
                SUPERSTICIÓN MX gozará de un plazo de 20 días hábiles para contestar y en su caso ejecutar la limitación de uso. En dicha respuesta, dará la resolución y la posibilidad de realizar lo solicitado en el Escrito, sin perjuicio de la posibilidad del ejercicio de sus demás derechos.</p>

                <H4 style={ moreMargin } >Derechos ARCO (Acceso, Rectificación, Cancelación, y Oposición)</H4>
                <p>El Titular tiene derecho, de propia persona o a través de un Representante, de solicitar a la Empresa, en cualquier momento el acceso, rectificación, cancelación u oposición, respecto de los datos personales que esté almacenada en la Base de Datos de la Empresa.</p>
                <p>El marco legal aplicable a esta solicitud se encuentra en el Capítulo IV de la Ley Federal de Protección de Datos Personales (artículos 28 al 35), por el Capítulo VII Secciones I a la V del Reglamento de la Ley Federal de Protección de Datos Personales (artículos 92 al 111), y por los Lineamientos del Aviso de Privacidad Vigésimo quinto, Vigésimo Octavo, Vigésimo Noveno y Trigésimo.</p>
                <p>La Solicitud debe ser por escrito con los siguientes requisitos:</p>

                <ul>
                    <li>El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud.</li>
                    <li>Los documentos que acrediten la identidad (copia de credencial para votar por ejemplo) o, en su caso, la representación legal del titular. Las Identificaciones deben de ser Oficiales.</li>
                    <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos de acceso, rectificación, cancelación u oposición a los mismos.</li>
                    <li>En su caso, las modificaciones a realizarse y aportar la documentación que sustente su petición.</li>
                    <li>Cualquier otro elemento que facilite la localización de los datos personales.</li>
                    <li>Que la carta esté dirigida a la Empresa.</li>
                </ul>

                <p>La Solicitud será enviada al Comité o el Oficial de Datos Personales a través del correo electrónico SUPERSTICIÓN MX, y de forma interna se le hará llegar a la persona que recibe y contesta estas solicitudes.</p>
                <p>La Empresa comunicará al Titular, a través del correo electrónico del que provenga la petición, en un plazo de 20 (veinte) días hábiles contados desde la fecha en que se recibió la solicitud de acceso, rectificación, cancelación u oposición, la determinación adoptada. El momento en que se recibe la solicitud es aquel en que haya entrado en nuestro servidor.</p>
                <p>Una vez que haya sido enviada la contestación en el plazo señalado, la Empresa tendrá 15 (quince) días hábiles para ejecutar el Acceso, Rectificación, Cancelación u Oposición de los datos personales conforme a la solicitud o derechos similares.</p>
                <p>SUPERSTICIÓN MX solicita al Usuario que actualice sus datos cada vez que éstos sufran alguna modificación, ya que esto permitirá brindarle un servicio eficiente y personalizado.</p>
                <p>Cuando los datos hayan dejado de ser necesarios para el cumplimiento de las finalidades previstas por este aviso de privacidad y las disposiciones legales aplicables, deberán ser cancelados, bloqueados y suprimidos por ministerio de Ley.</p>

                <H4 style={ moreMargin } >Procedimientos para bloqueo y supresión de los datos personales</H4>
                <p>Una vez que los datos hayan sido cancelados, la Empresa conservará un mes más los datos personales del Titular, para fines de aclaraciones y preparación para la supresión. Una vez vencido este plazo, la Empresa suprimirá de manera definitiva los datos personales del titular, no teniendo oportunidad de localización ni contacto con el titular, siendo eliminados de la base de datos y desecho todo expediente relacionado con el titular. Si el titular llegare a realizar otro acto con la empresa, deberá de iniciarlo como si la relación nunca hubiera existido.</p>
                <p>Lo anterior, observando lo señalado en las Políticas de Privacidad para efectos de conservar información por ministerio de ley o de autoridad.</p>
                <p>Los datos personales que hayan sido cumplidos sus fines, pero que no puedan ser cancelados y suprimidos por ministerio de ley o contractual, serán bloqueados de los fines a los que eran sometidos, hasta que se pueda suprimir. Durante dicho periodo, los datos personales no podrán ser objeto de tratamiento.</p>

                <H4 style={ moreMargin } >Privacidad de los menores</H4>
                <p>SUPERSTICIÓN MX no recopila de forma intencional la información de menores de edad, por lo que se recomienda a los padres y tutores lleven a cabo las actividades de compra de productos o registro en el Sitio Web ellos mismos.</p>

                <H4 style={ moreMargin } >Consentimiento</H4>
                El Usuario manifiesta que al uso de la página, la proporción de sus Datos Personales, o la realización directa de origen contractual o extracontractual con SUPERSTICIÓN MX, ya ha leído el presente aviso y consiente lo estipulado en el mismo. El Usuario consiente el presente Aviso de Privacidad, de conformidad con la Ley de Protección de Datos Personales en Posesión de los Particulares.

                <H4 style={ moreMargin } >Revocación del consentimiento</H4>
                <p>El consentimiento podrá ser revocado en cualquier momento sin que se le atribuyan efectos retroactivos. Para revocar el consentimiento el Usuario debe de enviar una Solicitud, la cual, debe ser por escrito con los siguientes requisitos:</p>
                <p>El nombre del titular y domicilio u otro medio para comunicar la respuesta a la solicitud.• Los documentos que acrediten la identidad (copia de credencial para votar por ejemplo) o, en su caso, la representación legal del titular.</p>
                <p>Las Identificaciones deben de ser Oficiales.</p>

                <ul>
                    <li>La descripción clara y precisa del vínculo que el Usuario tiene con la Empresa.</li>
                    <li>Aportar la documentación que sustente la petición del Usuario.</li>
                    <li>Que la carta esté dirigida a la Empresa.</li>
                    <li>Que compruebe que la relación que sostiene con la Empresa haya terminado.</li>
                </ul>

                <p>La Empresa emitirá una respuesta en la cual confirmará la revocación del consentimiento, o en su caso, señalará el razonamiento dependiendo del caso en concreto, contando la empresa con 15 días para emitir esta respuesta. Los plazos serán contados a partir del momento en que el correo entra al servidor de SUPERSTICIÓN MX.</p>
                <p>Cualquier solicitud ulterior a la mencionada en el párrafo anterior tendrá el mismo efecto que una de inicio, estando el Usuario y la Empresa obligadas a los mismos plazos señalados anteriormente.</p>
                <p>La Solicitud no será válida ante la omisión de lo señalado anteriormente.</p>

                <H4 style={ moreMargin } >Modificaciones al Aviso de Privacidad</H4>
                <p>La Empresa, así como el Usuario, reconocen que este Aviso de Privacidad es de vigencia ilimitada. Sin embargo, la Empresa procurará mantener actualizado el presente Aviso.</p>
                <p>SUPERSTICIÓN MX se reserva el derecho de efectuar en cualquier momento, modificaciones en las presentes Políticas de Privacidad y adaptarlas a novedades legislativas, jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos así como a prácticas del mercado. Queda bajo responsabilidad del Usuario leer periódicamente los términos y condiciones de este Aviso de Privacidad para estar al tanto de dichas modificaciones, las cuales al ser introducidas en el Sitio Web entrarán automáticamente en vigor.</p>
                <p>Las Reformas al Aviso de Privacidad se harán saber al Usuario a través de un aviso en la Home Page del Sitio Web de la Empresa.</p>

                <H4 style={ moreMargin } >Exclusión de responsabilidad</H4>

                <p>El Sitio Web podría contener enlaces, hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en Internet que al ser utilizados por los usuarios transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. SUPERSTICIÓN MX no controla dichos sitios ni se hace responsable por los Avisos de Privacidad que ostenten, los datos personales que los usuarios llegaren a proporcionar a través de estos portales o sitios de Internet distintos al Sitio Web, lo que deberá verificar en el Aviso de Privacidad en cada sitio al que acceda.</p>

                <p>Asimismo, la Empresa puede facilitar en el Sitio Web funciones de medios sociales que le permiten compartir la información del Sitio Web en sus redes sociales e interactuar con SUPERSTICIÓN MX en diversos sitios de medios sociales. La utilización de estas funciones puede implicar que se recabe o comparta información sobre el Titular, lo cual dependerá de cada función concreta. Recomendamos que se revise la configuración y las políticas de privacidad de los sitios de los medios sociales con los que se interactúa dichos sitios.</p>

                <H4 style={ moreMargin } >Disposiciones finales</H4>
                <p>Este documento es parte integrante de los Términos y Condiciones de Uso del Sitio de SUPERSTICIÓN MX, lo cual constituye un acuerdo legal entre el Usuario y la Empresa. Si el Usuario utiliza los servicios del Sitio de SUPERSTICIÓN MX, significa que ha leído, entendido y acordado los términos antes expuestos.</p>
                <p>Si usted acepta haber leído el presente Aviso de Privacidad y no manifiesta su oposición para que sus datos personales sean tratados o transferidos, se entenderá que ha otorgado su consentimiento para ello.</p>
                <p>Este Aviso de Privacidad estará regido y será interpretado de conformidad con las leyes de México.</p>
                <p>Si el Usuario considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir a la autoridad correspondiente para defender su ejercicio. La autoridad es el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), su sitio web es: www.inai.org.mx .</p>

                <H4 style={ moreMargin } >Fecha de la última actualización</H4>
                <p>10 de Octubre del 2020</p>


            </div>
        )

    }

    return getData()

}

export default AvisoDePrivacidad;
