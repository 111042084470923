import React from 'react';
import { H3, H5, Button, Card } from "@blueprintjs/core";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Components
import Login from './Login';

const LoginPage = ({ from_url, format }) => {

    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

    const getData = () => {

        return (

            <div className="row">

                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">

                    <div className="row">

                        <div className="col-12" >
                            <H5 className="text-center">¿No tienes cuenta? <Link to={{
                                pathname: '/signup',
                                from: from_url
                            }}>Regístrate</Link></H5>
                        </div>

                        <div className="col-12" >
                            <Login />
                        </div>

                    </div>

                </div>

            </div>

        )

    }


    if( format === "double" ) {

        if (isTablet) {

            return getData()

        } else {

            return (

                <div className="row">
                    <div className="col-md-6" >
                        <Login />
                    </div>
                    <div className="col-md-6" >
                        <Card style={{ height:'100%' }}>
                            <H3 className="text-center">Soy nuevo</H3>
                            <p style={{ margin:'3.45rem 0px' }}>Crea una cuenta para hacer compras más fáciles y rápidas, ¡y además disfruta de un acceso privilegiado a ofertas exclusivas!</p>
                            <Link to={{
                                pathname: '/signup',
                                from: from_url
                            }}>
                                <Button large={ true }  style={{ width:'100%' }} > REGISTRARME </Button>
                            </Link>
                        </Card>
                    </div>
                </div>

            )

        }

    } else {

        return getData()

    }

}

export default LoginPage;
