import { ADDRESS_FORM } from '../actions/types';

export function fetchSetAddressForm( state=false, action ) {

    switch( action.type ){

        case ADDRESS_FORM:
            return action.payload;

        default:
            return state;

    }

}
