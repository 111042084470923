import React from 'react';
import { Card, H6 } from "@blueprintjs/core";
// import { useSelector } from "react-redux";

import numeral from 'numeral';
// import _ from 'lodash'

const OrderList = ({ order }) => {

    // console.log("order detail -->", order.order_detail)
    const split_order_detail = order.order_detail.split("\n")
    // console.log("SPLIT STRING -->", split_order_detail)

    const displayDetails = split_order_detail.map( (item,i) => {
        const trim = item.trim();
        if (trim === "" ) {
            return false
        } else if (trim === ",") {
            return <br key={ i } />
        } else {
            return <div key={ i } >{trim}</div>
        }
    })


    const getData = () => {

        return (
            <Card>
                <div className="row">
                    <div className="col-2">
                        <H6>ORDER ID:</H6>
                        { order.id }
                    </div>
                    <div className="col-4">
                        <H6>DETALLE DE LA COMPRA:</H6>
                        <div className="profile-order--detail">
                            {
                                displayDetails
                            }
                        </div>

                    </div>
                    <div className="col-2">
                        <H6>TOTAL:</H6>
                        ${ numeral(order.price).format('0,0.00') }
                    </div>
                    <div className="col-2">
                        <H6>FECHA:</H6>
                        { order.created_at}
                    </div>

                    <div className="col-2">
                        <H6>STATUS:</H6>
                        { order.status }
                    </div>

                </div>
            </Card>
        )
    }

    return getData()
}

export default OrderList;
