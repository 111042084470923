import React from 'react';

const Whatsapp = () => {

    return (

        <a href="https://wa.me/525561810784" rel="noopener noreferrer" target="_blank">
            <div className="footer-redes__el">
                <svg className="whatsapp-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                    <path d="M23.3,10.1c0,5.2-4.2,9.4-9.5,9.4c-1.7,0-3.2-0.4-4.6-1.2L4,20l1.7-5c-0.9-1.4-1.4-3.1-1.4-4.8 c0-5.2,4.2-9.4,9.5-9.4C19,0.7,23.3,4.9,23.3,10.1z M13.8,2.2c-4.4,0-7.9,3.5-7.9,7.9c0,1.7,0.6,3.3,1.5,4.6l-1,2.9l3.1-1 c1.3,0.8,2.8,1.3,4.4,1.3c4.4,0,7.9-3.5,7.9-7.9S18.2,2.2,13.8,2.2z M18.6,12.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-1.4-0.7-1.6-0.7 c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.6,0.7-0.7,0.9c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-1-0.4-1.9-1.1c-0.7-0.6-1.2-1.4-1.3-1.6 c-0.1-0.2,0 0.4,0.1-0.5C11.9,9,12,8.9,12.1,8.7c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.2,0-0.3,0-0.4c-0.1-0.1-0.5-1.2-0.7-1.7 c-0.2-0.5-0.4-0.4-0.5-0.4c-0.1,0-0.3,0-0.4,0S10.2,5.9,10,6.1C9.8,6.3,9.2,6.9,9.2,8c0,1.1,0.8,2.2,0.9,2.4c0.1,0.2,1.6,2.5,4,3.5 c2.4,0.9,2.4,0.6,2.8,0.6c0.4,0,1.4-0.6,1.6-1.1C18.7,12.8,18.7,12.4,18.6,12.3z"/>
                    </g>
                </svg>
            </div>
        </a>

    )

}

export default Whatsapp;
