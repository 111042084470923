import { FETCH_REGISTER_USER_REQUEST, FETCH_REGISTER_USER_SUCCESS, FETCH_REGISTER_USER_FAILURE } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster'
import { Intent } from "@blueprintjs/core";


const initialState = {
    loading: false,
    success: false,
    error: null
}

export function fetchRegisterUser( state=initialState, action ){
    switch( action.type ){
        case FETCH_REGISTER_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_REGISTER_USER_SUCCESS:
            console.log( "=== FETCH_REGISTER_USER_SUCCESS ===" )
            return {
                loading: true,
                success: true,
                error: ''
            }

        case FETCH_REGISTER_USER_FAILURE:
            console.log("FETCH_REGISTER_USER_FAILURE", action.payload.response )

            if ( action.payload.response.data.error ) {

                AppToaster.show({ message: action.payload.response.data.error, intent: Intent.DANGER, });

            } else {

                const errors = [];

                for (const key of Object.keys(action.payload.response.data.errors)) {
                    action.payload.response.data.errors[key].forEach( err => {
                        errors.push(err)
                    });

                }

                errors.forEach((item, i) => {
                    if(item === "The email has already been taken.") {
                        AppToaster.show({ message: "El email ya ha sido registrado.", intent: Intent.DANGER, });
                    } else if (item === "The password confirmation does not match." ) {
                        AppToaster.show({ message: "La confirmación de la contraseña no coincide.", intent: Intent.DANGER, });
                    } else {
                        AppToaster.show({ message: item, intent: Intent.DANGER, });
                    }

                });


            }

            return  {
                loading: false,
                success:false,
                error: action.payload.response.status
            }

        default:
            return state;
    }
}
