import React from 'react';

const Logotipo = () => {

    return (

        <div>
            <svg className="logotipo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.2 19.5">
                <g>
                <path d="M76.8,7.2c-0.2-0.2-0.1-0.5,0-0.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.5,0.2
                	c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2l0,0c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.1-0.3,0.1
                	c-0.1,0-0.2,0-0.3-0.1C77,7.3,76.9,7.3,76.8,7.2z M105.2,7c0.1,0,0.3-0.1,0.6-0.4s0.6-0.5,0.9-0.8c0.3-0.3,0.4-0.5,0.4-0.6
                	c0-0.2-0.2-0.3-0.5-0.3c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.3,0.3-0.5,0.5c-0.4,0.6-0.7,1-0.7,1.2c0,0.1,0,0.1,0.1,0.2
                	C105,7,105.1,7,105.2,7z M95.2,7.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.4-0.2,0.4-0.4l0,0c0-0.1,0-0.1,0.1-0.2
                	c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.3C95,6.2,95,6.3,95,6.4
                	c-0.1,0.3-0.1,0.5,0,0.8C95.1,7.3,95.2,7.3,95.2,7.4z M126.7,8c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.3-0.1,0.5
                	c-0.1,0.1-0.2,0.2-0.4,0.3c-1.8,0.9-3.3,1.6-4.6,2c-1.2,0.4-2.3,0.7-3.1,0.7c-0.9,0-1.6-0.3-2.1-0.8c-0.3-0.4-0.5-0.8-0.5-1.4
                	c-0.4,0.5-0.8,1.1-1.2,1.8c-0.2,0.3-0.5,0.4-0.7,0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.6,0-1,0-1.4
                	c-0.8,0.4-1.4,0.7-2,0.9c-0.9,0.4-1.8,0.6-2.5,0.7c-0.7,0.1-1.4,0.2-1.9,0.2c-0.4,0-0.8-0.1-1.1-0.1c-0.1,0.1-0.1,0.2-0.2,0.2
                	c-0.4,0.4-1,0.6-1.7,0.5l-0.2,0c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.1,0-0.2,0-0.4c-1.6,0.8-3,1.3-4.2,1.6
                	c-1.5,0.3-2.6,0.2-3.4-0.4c-0.4-0.4-0.7-0.8-0.8-1.4c-0.8,0.6-1.7,1-2.5,1.4c-1.2,0.5-2.3,0.6-3.3,0.6c-0.9-0.1-1.7-0.3-2.3-0.8
                	c-0.5-0.4-0.8-0.8-1-1.2c-1.9,1-3.5,1.6-4.9,1.9c-1.5,0.3-2.6,0.2-3.4-0.4c-0.5-0.4-0.7-0.9-0.8-1.5c-1.7,1.1-3.2,1.8-4.6,2.1
                	c-1.6,0.3-2.9,0.1-3.8-0.8c-0.4-0.4-0.8-1-1-1.6c-1.6,0.9-3.9,1.6-6.8,2c-1,0.1-2,0.2-3.1,0.3c-0.5,0-0.7,0-0.8,0l-0.4-0.1
                	c-0.2-0.1-0.3-0.3-0.5-0.5L55,11.3l0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.4-0.3l0,0l0.1,0H56c0,0,0,0,0.1,0
                	c-0.3-0.2-0.6-0.5-0.8-0.8l-1.1,0.4c-1.2,0.5-2.2,0.9-3.2,1.2c-0.9,0.4-1.5,0.6-1.7,0.7c-1.3,0.6-2.3,0.7-3,0.5
                	c-0.4-0.1-0.7-0.4-0.9-0.8c-0.3-0.7-0.3-1.3-0.1-2c0,0,0,0-0.1,0c-0.2,0-0.4-0.1-0.7-0.2c0,0,0,0-0.1,0c-0.6,0.4-1.3,0.8-2,1.2
                	c-0.9,0.4-1.9,0.8-3,1.1c-1.1,0.3-2.2,0.5-3.2,0.5c-0.9,0-1.6-0.3-2.1-1c-0.2-0.3-0.4-0.7-0.4-1.1c-0.2,0.1-0.4,0.2-0.6,0.4
                	c-0.9,0.5-1.7,0.9-2.6,1.2c-1.2,0.5-2.1,0.7-2.8,0.6c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.6-0.8-0.7-1.4c0-0.4-0.1-0.8-0.2-1
                	c0,0,0,0,0,0.1c-0.3,1.1-0.5,3-0.7,5.6c-0.1,1.1-0.1,1.9-0.1,2.5v1c0,0.2,0,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.3
                	c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2-0.1-0.3-0.2l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l0-0.1l0-8.8c-1.8,1-3.3,1.7-4.5,2
                	c-1.4,0.3-2.5,0.2-3.3-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3,0.4-0.6,0.8-1,1.1c-0.3,0.3-0.7,0.4-0.9,0.5c-0.2,0-0.4,0-0.6-0.1
                	c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.3-0.4-0.8-0.4-1.5c0-0.1,0-0.3,0-0.5c-1.6,0.8-3.7,1.4-6.3,1.7c-1,0.1-2,0.2-3.1,0.3
                	c-0.5,0-0.7,0-0.8,0l-0.4-0.1c-0.3-0.1-0.3-0.3-0.5-0.5L0,11.3L0.2,11c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.2-0.2,0.4-0.3l0,0l0.1,0H1
                	c0,0,0,0,0,0c-0.5-0.3-0.8-0.7-0.9-1.2c-0.2-0.5-0.1-1,0.2-1.5c0.2-0.3,0.5-0.6,0.9-0.8L1.4,7c0.1,0,0.2-0.1,0.2-0.1l0.1-0.1
                	C1.7,6.7,1.9,6.7,2,6.7c0.1,0,0.2,0,0.3,0.1l0.2,0.1c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.2,0.5c0,0.1-0.1,0.2-0.3,0.2
                	c0,0,0,0-0.1,0l-0.1,0C2.1,8.4,2,8.4,2,8.5C1.9,8.5,1.7,8.6,1.7,8.7c0,0.1,0,0.1,0,0.1c0,0,0.1,0.2,0.7,0.5l0.1,0.1
                	c0.4,0.3,0.6,0.6,0.7,1c0.5,0,1-0.1,1.6-0.2c1.4-0.2,2.7-0.5,4-0.8c1.2-0.4,2.2-0.8,3-1.4c0.1-0.1,0.3-0.2,0.5-0.2h0.1
                	c0.2,0,0.4,0,0.6,0.3C13,8.2,13,8.4,13,8.6v0.1l0,0.3c0,0.2-0.1,0.4-0.1,0.8c0,0.3,0,0.6,0,0.8c0.4-0.5,0.7-0.9,0.9-1.4
                	c0.1-0.4,0.2-0.5,0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.1,0.2,0.2,0.4,0.2,0.6v0.2
                	c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.3,1.1,0.3,2,0.1
                	c1.3-0.3,3-1.1,5.1-2.5c0.2-0.1,0.3-0.1,0.4-0.1h0.1c0.2,0,0.4,0.1,0.6,0.2l0,0c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.5-0.5
                	c0.2-0.1,0.4-0.1,0.6-0.1l0,0L26,7.3v0c0.4,0.2,0.7,0.5,0.9,1.1c0.2,0.5,0.3,1.2,0.4,1.9c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.3,0.1
                	c0.5,0,1.3-0.2,2.2-0.5C31.1,9.7,32.5,9,34.1,8c0.1-0.2,0.2-0.4,0.3-0.5C34.5,7.2,34.7,7,35,6.9l0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.1
                	c0,0,0.1,0,0.2,0l0.1,0c0.3,0,0.5,0.1,0.6,0.2c0.3,0.2,0.5,0.5,0.5,0.7c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
                	c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.6,0.3l-0.1,0c0,0,0,0,0,0c0,0.4,0,0.7,0.2,0.9c0.1,0.2,0.4,0.3,0.8,0.3
                	c0.8,0,1.7-0.2,2.7-0.4c1-0.3,1.9-0.6,2.7-1c0.8-0.4,1.5-0.8,2.1-1.2c0.1-0.1,0.3-0.1,0.5-0.1l0.1,0c0,0,0,0,0.1,0
                	c0.1,0,0.2,0,0.3,0L45,7.9C45.3,8,45.5,8,45.5,8.1c0.2,0,0.3,0,0.3,0c0.2-0.2,0.4-0.3,0.7-0.3l0.1,0L47.1,8c0.2,0.2,0.3,0.6,0.2,1
                	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.4-0.2,0.7c-0.2,0.6-0.1,0.9-0.1,1c0.2,0.1,0.7,0.1,1.8-0.4c0.4-0.2,2.6-1,6.6-2.6
                	c0,0,0-0.1,0.1-0.1c0.2-0.3,0.5-0.6,0.9-0.8L56.3,7c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1
                	l0.2,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.2,0.5c0,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0.1l-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
                	c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.1,0,0.1s0.1,0.2,0.7,0.5l0.1,0.1c0.4,0.3,0.6,0.6,0.7,1c0.5,0,1.1-0.1,1.6-0.2
                	c1.4-0.2,2.7-0.5,4-0.8c1.1-0.3,2-0.8,2.8-1.2c0-0.4,0-0.8,0-1.2c-1.1,0.3-1.8,0.5-2.1,0.6l-0.1,0c0,0-0.1,0-0.2,0
                	c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.5-0.6-0.1-1.1C63.6,6.1,63.7,6,63.9,6c0.9-0.3,1.9-0.6,2.9-0.9c0.1-0.3,0.2-0.6,0.3-0.9
                	c0.3-0.8,0.7-1.4,1-1.7l0.1-0.1l0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.4
                	c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.2-0.2,0.5-0.4,0.9l0,0.1c0.7-0.2,1.2-0.3,1.7-0.4l0.1,0l0.3,0
                	c0.2,0,0.4,0,0.6-0.1c0.1,0,0.2,0,0.2,0c0.3,0,0.5,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.4,0.6c0,0.2,0,0.5-0.2,0.6l-0.3,0.2
                	c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.2,0h0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0-0.8,0.1c-0.4,0.1-1.3,0.3-2.5,0.6l-0.3,0.1
                	c0,0.1,0,0.2,0,0.4C68,7.6,68,8.3,68.1,9c0.1,0.6,0.3,1,0.7,1.4c0.5,0.5,1.3,0.6,2.4,0.3c1.4-0.3,3.2-1.2,5.2-2.7
                	c0,0,0.1-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6l0,0.3c0,0.1,0,0.2,0,0.3
                	c-0.1,0.4,0,0.9,0.3,1.2c0.3,0.3,1,0.3,2,0.1c1.4-0.3,3.4-1.1,5.8-2.5c0.2-0.2,0.3-0.3,0.5-0.4c0.4-0.2,0.7-0.4,1.2-0.4h0.1
                	c0.1,0,0.4,0,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.2-0.3,0.2-0.6,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
                	c-0.2,0.1-0.3,0.4-0.3,0.7c0,0.1,0.1,0.3,0.3,0.4c0.4,0.3,0.9,0.4,1.5,0.5c0.4,0,0.8,0,1.2,0c0.4-0.1,0.9-0.2,1.4-0.4s1-0.5,1.6-0.8
                	c0.6-0.4,1.2-0.8,1.8-1.3c0,0,0.2-0.2,0.5-0.2h0c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6l0,0.3
                	c0,0.1,0,0.2-0.1,0.3c-0.1,0.4,0,0.9,0.3,1.2c0.3,0.3,1,0.3,2,0.1c1.4-0.3,3.4-1.2,5.9-2.6c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0
                	c0.1,0,0.1,0,0.2,0h0c0.2,0,0.4,0.1,0.5,0.2c0.1,0,0.1,0,0.2,0c0.5,0.1,0.9,0.4,1.1,1c0.1,0.3,0.2,0.7,0.2,1c0.2,0,0.4,0,0.6,0
                	c0.4,0,0.9,0,1.6-0.2c0.6-0.1,1.4-0.3,2.2-0.7c0.9-0.3,1.8-0.8,2.9-1.3c0.1,0,0.2-0.1,0.4-0.1l0.1,0c0,0,0,0,0,0
                	c0.2,0,0.4,0.1,0.5,0.2s0.2,0.3,0.3,0.5c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.3,0.6-0.4,0.9-0.4
                	c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.3,0.4,0.6c0.1,0.3,0.1,0.7,0,1.2c0,0.6,0.1,0.8,0.1,0.8c0.1,0.1,0.3,0.2,0.8,0.2
                	c1.3,0,3.6-0.9,7-2.6c0.1-0.1,0.2-0.1,0.4-0.1C126.3,7.8,126.5,7.8,126.7,8z M104.7,10.7c-0.2-0.1-0.3-0.3-0.4-0.4
                	c0-0.1-0.1-0.2-0.1-0.2c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.1,0,0.1,0,0.1c0,0,0,0,0.1,0.1C104.4,10.8,104.6,10.8,104.7,10.7z M130.1,2.2
                	c0,0.6-0.5,1.1-1.2,1.1c-0.7,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1C129.6,1.1,130.1,1.6,130.1,2.2z M129.8,2.2
                	c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9C129.4,3.1,129.8,2.7,129.8,2.2z M129.4,2.5
                	c0,0.2,0.1,0.2,0.1,0.3h-0.3c0,0-0.1-0.1-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2h-0.1v0.5h-0.3V1.7c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.4,0.1
                	c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3v0C129.3,2.3,129.4,2.4,129.4,2.5z M129.2,2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.1,0
                	v0.3h0.1C129,2.2,129.2,2.1,129.2,2z"/>
                </g>
            </svg>
        </div>

    )

}

export default Logotipo;
