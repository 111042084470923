import { TOGGLE_DARKTHEME } from '../actions/types';

export function fetchDarkMode( state=false, action ) {

    switch( action.type ){

        case TOGGLE_DARKTHEME:
            return !state

        default:
            return state;

    }

}
