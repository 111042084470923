import React from 'react';
import { Button, Navbar, Position, Tooltip, Icon, Intent, Menu, MenuItem } from "@blueprintjs/core";
import { Link, useHistory } from 'react-router-dom';
import DarkThemeToggle from "../../DarkThemeToggle";
import { useSelector, useDispatch } from "react-redux";
import Logotipo from './Logotipo';
import Isotipo from './Isotipo';
import _ from 'lodash';

import { LOGOUT }  from '../../actions/types'

import './styles.scss'

const Header = ( props ) => {

    const cartCount = useSelector( state => state.cart.cart );
    const cartUpdated = useSelector( state => () => { return true } );
    let listado_productos = useSelector( state => state.listaProductos.productos );
    listado_productos = _.filter( listado_productos, item => item.active === 1 );
    const userData = useSelector( state => state.user_login.user );
    const isCategoryPath = props.location.pathname.startsWith("/categoria/");
    // const isItemPath = props.location.pathname.startsWith("/item/");
    const token = localStorage.getItem( "session" );
    const validSession = ( token !== null );
    const dispatch = useDispatch();
    const history = useHistory();

    let array_categories = _.map( listado_productos, ( val, i ) => {
        return { 'id': val.category.id, 'title': val.category.name, 'slug': val.category.slug };
    });

    array_categories = _.uniqBy(array_categories, 'id');

    const navStyle = {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    };


    const getCarticon = () => {

        if( cartCount.length !== 0 ) {

            return (
                <Link to="/cart">
                    <Button className="bp3-minimal cart-icon" icon="shopping-cart">
                        { cartCount.length !== 0 ? <span className="cart-notification">{cartCount.length}</span> : ''}
                    </Button>
                </Link>
            )

        } else {

            return (
                <Tooltip content="Tu carrito está vacío!" position={Position.LEFT}>
                    <Button className="bp3-minimal cart-icon" icon="shopping-cart"  />
                </Tooltip>
            )

        }

    }

    const getUserName = () => {

        if( validSession ){

            return userData.loading ? false : (

                <div className="username">
                    {
                        (props.location.pathname === "/profile") ? (

                            <div>
                                Hola, { userData.name } <span className="btn" onClick={ () => { dispatch({ type: LOGOUT }) } } ><Tooltip content="Cerrar sesión" className="logoutBtn" position={Position.RIGHT}><Icon icon="delete"  intent={Intent.DANGER} /></Tooltip></span>
                            </div>

                        ) : <Link to={ '/profile' } ><Button className="bp3-minimal" icon="user" /></Link>
                    }
                </div>

            )

        } else {

            return <Link to={ '/profile' } ><div className="username"><Button className="bp3-minimal" icon="blocked-person" /></div></Link>;

        }
    }

    const getCategoryName = () => {


        if ( isCategoryPath ) {

            const name = props.location.pathname.replace("/categoria/",'');
            const getMatch = array_categories.find( category => category.slug === name );

            return getMatch ? getMatch.title : "";

        } else {

            return "Categorias";

        }

    }

    const getCatalogoBtn = () => {

        if( props.location.pathname !== "/" ) {

            return <Button className="bp3-minimal"  onClick={ () => history.push("/") } text="INICIO" />
        }

    }

    const getData = () => {

        cartUpdated();

        const getCategories = array_categories.map( i => (

            <MenuItem key={ i.id } text={ i.title } onClick={ () => history.push("/categoria/" + i.slug) } />

        ));


        return (
            <Navbar style={ navStyle }>

                <div className="row">
                    <Navbar.Group className="col-lg-12 navbar-right">
                        <DarkThemeToggle />
                    </Navbar.Group>
                </div>


                <div className="row">

                    <Navbar.Group className="col-lg-6 navbar-left">
                        <Isotipo />
                        <Link to="/"><Logotipo /></Link>
                    </Navbar.Group>

                    <Navbar.Group className="col-lg-6 navbar-right">

                        { getCatalogoBtn() }

                        {  isCategoryPath ? <Navbar.Divider /> : "" }

                        {
                            isCategoryPath ? (
                                <Menu className="category_btn">
                                    <MenuItem text={ getCategoryName() } >
                                        { getCategories }
                                    </MenuItem>
                                </Menu>
                            ) : ''
                        }

                        { props.location.pathname !== "/"  ? <Navbar.Divider /> : "" }

                        { getCarticon() }

                        <Navbar.Divider />

                        { getUserName() }
                        
                    </Navbar.Group>

                </div>

            </Navbar>
        )

    }

    return (

        <div className="header">
            { getData() }
        </div>

    )

}

export default Header;
