import React, { useEffect, useState } from 'react';
import { Card, FormGroup, InputGroup, H2, Button, Alert } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegisterUser, fetchSetAddressForm } from '../../actions';
import { useImmerReducer } from 'use-immer';
import { CSSTransition } from  'react-transition-group';

import AvisoDePrivacidad from '../Legal/AvisoDePrivacidad'


const RegisterAddress = (props) => {

    const [ isOpenAviso, setIsOpenAviso ] = useState(false);
    const isDarkMode = useSelector( state => state.isDark);

    const dispatchApp = useDispatch();

    const initialState = {
        street: {
            value: "",
            hasErrors: true,
            message: ""
        },
        streetReference: {
            value: "",
            hasErrors: true,
            message: ""
        },
        postalCode: {
            value: "",
            hasErrors: true,
            message: ""
        },
        neighborhood: {
            value: "",
            hasErrors: true,
            message: ""
        },
        city: {
            value: "",
            hasErrors: true,
            message: ""
        },
        province: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "streetInmmediately":
                draft.street.hasErrors = false
                draft.street.value = action.value

                if ( draft.street.value.length > 30 ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "La calle no puede exceder los 30 caracteres"
                }

                if (draft.street.value.trim() === "" ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "Debes proporcionar una calle válida."
                }
                return

            case "streetAfterDelay":

                draft.street.value = draft.street.value.trim()

                if ( draft.street.value !== "" && draft.street.value.length < 3 ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "La calle debe tener al menos 3 caracteres"
                }

                return

            case "streetReferenceInmmediately":
                draft.streetReference.hasErrors = false
                draft.streetReference.value = action.value

                if ( draft.streetReference.value.length > 50 ) {
                    draft.streetReference.hasErrors = true;
                    draft.streetReference.message = "La referencia no puede exceder los 50 caracteres"
                }

                return

            case "streetReferenceAfterDelay":
                draft.streetReference.value = draft.streetReference.value.trim()

                if ( draft.streetReference.value !== "" && draft.streetReference.value.length < 3 ) {
                    draft.streetReference.hasErrors = true;
                    draft.streetReference.message = "La referencia debe tener al menos 3 caracteres"
                }
                return

            case "postalCodeInmmediately":
                draft.postalCode.hasErrors = false
                draft.postalCode.value = action.value

                if (draft.postalCode.value.trim() === "" ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "Debes proporcionar un código postal válido"
                }

                if ( draft.postalCode.value.length > 5 ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "El código postal no puede exceder los 5 dígitos"
                }

                return

            case "postalCodeAfterDelay":

                if ( draft.postalCode.value.length < 5 ) {
                    draft.postalCode.hasErrors = true
                    draft.postalCode.message = "El código postal debe tener 5 digitos"
                }

                if ( draft.postalCode.value && !/^([0-9]+)$/.test(draft.postalCode.value) ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "El código postal solo puede contener números"
                }

                return

            case "neighborhoodInmmediately":
                draft.neighborhood.hasErrors = false
                draft.neighborhood.value = action.value

                if ( draft.neighborhood.value.length > 30 ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "La colonia no puede exceder los 30 caracteres"
                }

                if (draft.neighborhood.value.trim() === "" ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "Debes proporcionar una colonia válida."
                }

                return

            case "neighborhoodAfterDelay":
                draft.neighborhood.value = draft.neighborhood.value.trim()

                if ( draft.neighborhood.value !== "" && draft.neighborhood.value.length < 3 ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "La colonia debe tener al menos 3 caracteres"
                }

                return

            case "cityInmmediately":
                draft.city.hasErrors = false
                draft.city.value = action.value

                if ( draft.city.value.length > 30 ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "La ciudad no puede exceder los 30 caracteres"
                }

                if (draft.city.value.trim() === "" ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "Debes proporcionar una ciudad válida."
                }
                return

            case "cityAfterDelay":
                draft.city.value = draft.city.value.trim()

                if ( draft.city.value !== "" && draft.city.value.length < 3 ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "La ciudad debe tener al menos 3 caracteres"
                }

                return

            case "provinceInmmediately":
                draft.province.hasErrors = false
                draft.province.value = action.value

                if ( draft.province.value.length > 30 ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "El estado no puede exceder los 30 caracteres"
                }

                if (draft.province.value.trim() === "" ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "Debes proporcionar un estado válida."
                }
                return

            case "provinceAfterDelay":
                draft.province.value = draft.province.value.trim()

                if ( draft.province.value !== "" && draft.province.value.length < 3 ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "El estado debe tener al menos 3 caracteres"
                }
                return

            default:
                break;
        }
    }

    const [ state, dispatch ] = useImmerReducer(ourReducer, initialState);
    const loading = useSelector( state => state.user_register.loading );

    useEffect(() => {
        if (state.street.value) {
            const delay  = setTimeout(() => dispatch({ type: "streetAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.street.value])

    useEffect(() => {
        if (state.streetReference.value) {
            const delay  = setTimeout(() => dispatch({ type: "streetReferenceAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.streetReference.value])

    useEffect(() => {
        if (state.postalCode.value) {
            const delay  = setTimeout(() => dispatch({ type: "postalCodeAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.postalCode.value])

    useEffect(() => {
        if (state.neighborhood.value) {
            const delay  = setTimeout(() => dispatch({ type: "neighborhoodAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.neighborhood.value])

    useEffect(() => {
        if (state.city.value) {
            const delay  = setTimeout(() => dispatch({ type: "cityAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.city.value])

    useEffect(() => {
        if (state.province.value) {
            const delay  = setTimeout(() => dispatch({ type: "provinceAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.province.value])

    const handleRegisterUser = () => {

        // const user_address = `${ state.street.value }, ${ state.streetReference.value ? state.streetReference.value + '.' : '' } ${ state.neighborhood.value }, ${ state.city.value }, ${ state.province.value }. México. CP. ${ state.postalCode.value }`;

        const user = new URLSearchParams()
        user.append( 'name', props.user.name );
        user.append( 'surname', props.user.surname );
        user.append( 'email', props.user.email );
        user.append( 'phone', props.user.phone );
        user.append( 'password', props.user.password );
        user.append( 'password_confirmation', props.user.password );
        // user.append( 'address', user_address );

        user.append( 'address_line_1', state.street.value );
        user.append( 'address_colonia', state.neighborhood.value );
        user.append( 'address_ciudad', state.city.value );
        user.append( 'address_estado', state.province.value );
        user.append( 'address_postal_code', state.postalCode.value );
        user.append( 'address_ref', state.streetReference.value );

        dispatchApp( fetchRegisterUser(user) )

    }

    const getAddressFrom = () => {

        return (

            <Card>
                 <span className="btn" onClick={ () => dispatchApp( fetchSetAddressForm( false ) ) }>Regresar al paso anterior</span>
                <H2>Dirección de envío</H2>

                <div>

                    <FormGroup
                        label="País"
                    >
                        <InputGroup value="Mexico" id="country" disabled={true} />
                    </FormGroup>

                    <FormGroup
                        label="Calle y número"
                        labelInfo="*"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "streetInmmediately", value: e.target.value }) } id="street" />
                        <CSSTransition in={ state.street.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.street.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup
                        label="Referencias"
                        helperText="(Entre calles, Piso, # Depto, Oficina, etc)"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "streetReferenceInmmediately", value: e.target.value }) } id="street_reference" />
                        <CSSTransition in={ state.streetReference.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.streetReference.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup
                        label="Colonia"
                        labelInfo="*"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "neighborhoodInmmediately", value: e.target.value }) } id="neighborhood" />
                        <CSSTransition in={ state.neighborhood.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.neighborhood.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup
                        label="Delegación / Ciudad"
                        labelInfo="*"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "cityInmmediately", value: e.target.value }) } id="city" />
                        <CSSTransition in={ state.city.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.city.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup
                        label="Estado"
                        labelInfo="*"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "provinceInmmediately", value: e.target.value }) } id="province" />
                        <CSSTransition in={ state.province.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.province.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup
                        label="Codigo postal"
                        labelInfo="*"
                    >
                        <InputGroup onChange={ e => dispatch({ type: "postalCodeInmmediately", value: e.target.value }) } id="postal_code" />
                        <CSSTransition in={ state.postalCode.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.postalCode.message } </div>
                        </CSSTransition>
                    </FormGroup>


                    { !state.street.hasErrors && !state.postalCode.hasErrors && ( state.postalCode.value.length === 5 ) && !state.neighborhood.hasErrors && !state.city.hasErrors && !state.province.hasErrors ? <Button loading={ loading } onClick={ () => handleRegisterUser() } large={true} style={{width:'100%'}} > REGÍSTRATE </Button> :  <Button disabled large={true} style={{width:'100%'}} > REGÍSTRATE </Button> }
                    <span className="disclaimer">Al hacer clic en 'Regístrate', aceptas nuestra <span className="btn" onClick={ e => setIsOpenAviso(true) }>Política de Privacidad</span></span>

                </div>

            </Card>

        )
    }

    return (
        <div>
            { getAddressFrom() }

            <Alert
                isOpen={ isOpenAviso }
                className={ isDarkMode ? 'bp3-dark' : '' }
                onCancel={ () => setIsOpenAviso(false) }
                onConfirm={ () => setIsOpenAviso(false) }
                canEscapeKeyCancel={ true }
                canOutsideClickCancel={ true }
                confirmButtonText="OK"
                style={ {width:'100%', maxWidth:'80%'} }
            >

                <AvisoDePrivacidad />

            </Alert>

        </div>
    );

}

export default RegisterAddress;
