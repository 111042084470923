import { FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE } from '../actions/types';
// import { AppToaster } from '../Components/Shared/Toaster'
// import { Intent } from "@blueprintjs/core";

const initialState = {
    loading: false,
    user_orders: [],
    success: false
}

export function fetchUserProfile( state=initialState, action ){
    switch( action.type ){

        case FETCH_USER_PROFILE_REQUEST:
            console.log("== FETCH_USER_PROFILE_REQUEST ==" )
            return {
                ...state,
                loading: true
            }

        case FETCH_USER_PROFILE_SUCCESS:
            
            return {
                loading: false,
                user_orders: action.payload,
                success: true
            }

        case FETCH_USER_PROFILE_FAILURE:


            console.log( "FETCH_USER_PROFILE_FAILURE" )
            return  {
                loading: false,
                user_orders:[],
                success: false,
            }

        default:
            return state;
    }
}
