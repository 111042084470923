import React, { useState, useEffect } from 'react';
import { Card, FormGroup, InputGroup, Button, H3, H5, Tooltip, Intent} from "@blueprintjs/core";
import { useImmerReducer } from 'use-immer';
import { CSSTransition } from  'react-transition-group'
import { Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import axios from 'axios';
import { API_URL } from '../../config.js'
import { fetchSetAddressForm } from "../../actions";

import RegisterAddress from './RegisterAddress'

const Register = (props) => {

    // const [ addressForm, setAddressForm ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const sessionUpdated = useSelector( state => () => { return true } );
    const addressForm = useSelector( state => state.address_form );
    const dispatchApp = useDispatch();

    const initialState = {
        name: {
            value: "",
            hasErrors: true,
            message: ""
        },
        surname: {
            value: "",
            hasErrors: true,
            message: ""
        },
        email: {
            value: "",
            hasErrors: true,
            message: "",
            isUnique: false,
            checkCount: 0
        },
        phone: {
            value: "",
            hasErrors: false,
            message: ""
        },
        password: {
            value: "",
            hasErrors: true,
            message: ""
        },
        passwordConfirmation: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "nameInmmediately":
                draft.name.hasErrors = false
                draft.name.value = action.value

                if ( draft.name.value.length > 30 ) {
                    draft.name.hasErrors = true;
                    draft.name.message = "El nombre no puede exceder los 30 caracteres"
                }

                if (draft.name.value.trim() === "" ) {
                    draft.name.hasErrors = true;
                    draft.name.message = "Debes proporcionar un nombre válido."
                }

                return

            case "nameAfterDelay":

                draft.name.value = draft.name.value.trim()

                if ( draft.name.value !== "" && draft.name.value.length < 3 ) {
                    draft.name.hasErrors = true;
                    draft.name.message = "El nombre debe tener al menos 3 caracteres"
                }

                if ( draft.name.value && !/^([a-zA-ZÀ-ž\s]+)$/.test(draft.name.value) ) {
                    draft.name.hasErrors = true;
                    draft.name.message = "El nombre solo puede contener letras"
                }

                return

            case "surnameInmmediately":
                draft.surname.hasErrors = false
                draft.surname.value = action.value

                if ( draft.surname.value.length > 30 ) {
                    draft.surname.hasErrors = true;
                    draft.surname.message = "El apellido no puede exceder los 30 caracteres"
                }

                if ( draft.surname.value.trim() && !/^([a-zA-ZÀ-ž\s]+)$/.test(draft.surname.value) ) {
                    draft.surname.hasErrors = true;
                    draft.surname.message = "El apellido solo puede contener letras"
                }

                if (draft.surname.value.trim() === "" ) {
                    draft.surname.hasErrors = true;
                    draft.surname.message = "Debes proporcionar un apellido válido."
                }

                return

            case "surnameAfterDelay":

                if ( draft.surname.value !== "" && draft.surname.value.trim().length < 3 ) {
                    draft.surname.hasErrors = true;
                    draft.surname.message = "El apellido debe tener al menos 3 caracteres"
                }

                return

            case "emailInmmediately":
                draft.email.hasErrors = false
                draft.email.value = action.value

                if (draft.email.value.trim() === "" ) {
                    draft.email.hasErrors = true;
                    draft.email.message = "El email no puede quedar vacío"
                }

                return

            case "emailAfterDelay":

                if ( !/^\S+@\S+$/.test(draft.email.value) ) {
                    draft.email.hasErrors = true
                    draft.email.message = "Debes proporcionar un email válido."
                }

                if (!draft.email.hasErrors) {
                    draft.email.checkCount++
                }

                return

            case "emailUniqueResults":
                if(action.value){
                    draft.email.hasErrors = true;
                    draft.email.isUnique = false;
                    draft.email.message = "El email ya ha sido registrado"
                } else {

                }

                return

            case "phoneInmmediately":
                draft.phone.hasErrors = false
                draft.phone.value = action.value

                if (draft.phone.value.trim() === "" ) {
                    draft.phone.hasErrors = true;
                    draft.phone.message = "Añade tu celular para el seguimiento de tus pedidos"
                }

                if ( draft.phone.value.length > 10   ) {
                    draft.phone.hasErrors = true
                    draft.phone.message = "El teléfono no puede superar los 10 digitos"
                }

                return

            case "phoneAfterDelay":

                if ( draft.phone.value.length < 10 ) {
                    draft.phone.hasErrors = true
                    draft.phone.message = "El teléfono debe tener 10 digitos"
                }

                if ( draft.phone.value && !/^([0-9]+)$/.test(draft.phone.value) ) {
                    draft.phone.hasErrors = true;
                    draft.phone.message = "El teléfono solo puede contener números"
                }

                return

            case "passwordInmmediately":
                draft.password.hasErrors = false
                draft.password.value = action.value

                if ( draft.password.value.length > 64 ) {
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña no puede superar los 64 caracteres"
                }

                if (draft.password.value === "" ) {
                    draft.password.hasErrors = true;
                    draft.password.message = "Debes proporcionar una contraseña válida."
                }

                return

            case "passwordAfterDelay":

                if( draft.password.value.length < 8 ){
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña debe tener al menos 8 caracteres"
                }

                return

            case "submitForm":
                return
            default:

        }
    }

    const [ state, dispatch ] = useImmerReducer(ourReducer, initialState);

    useEffect(() => {
        if (state.name.value) {
            const delay  = setTimeout(() => dispatch({ type: "nameAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.name.value])

    useEffect(() => {
        if (state.surname.value) {
            const delay  = setTimeout(() => dispatch({ type: "surnameAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.surname.value])

    useEffect(() => {
        if (state.email.value) {
            const delay  = setTimeout(() => dispatch({ type: "emailAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.email.value])

    useEffect(() => {
        if (state.email.checkCount) {
            const ourRequest = axios.CancelToken.source();
            const email = new URLSearchParams()
            email.append( 'email', state.email.value );
            email.append( 'password', '' );

            async function fetchResults(){
                try {
                    await axios.post(API_URL + "api/auth/register", email)
                } catch (e) {

                    if (e.response.data.errors.email) {
                        e.response.data.errors.email.forEach((item, i) => {
                            if ( item === "The email has already been taken.") {
                                dispatch({ type: "emailUniqueResults", value: true })
                            }
                        })
                    }
                    console.log("THERE WAS A PROBLEM", e.response)
                }
            }
            fetchResults()
            return () => ourRequest.cancel()
        }
    }, [state.email.checkCount])

    useEffect(() => {
        if (state.phone.value) {
            const delay  = setTimeout(() => dispatch({ type: "phoneAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.phone.value])

    useEffect(() => {
        if (state.password.value) {
            const delay  = setTimeout(() => {
                dispatch({ type: "passwordAfterDelay" })
            }, 800)

            return () => clearTimeout(delay)
        }
    }, [state.password.value])

    const lockButton = (
        <Tooltip content={`${showPassword ? "Ocultar" : "Mostrar"} Password`}>
            <Button
                icon={showPassword ? "eye-off" : "eye-open"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={ () => { setShowPassword(!showPassword) } }
            />
        </Tooltip>
    )

    const getData = () => {

        let user = {
            name: state.name.value,
            surname: state.surname.value,
            email: state.email.value,
            phone: state.phone.value,
            password: state.password.value,
            passwordConfirmation: state.passwordConfirmation.value
        }

        if( addressForm ){

            return (
                <div>
                    <RegisterAddress user={ user } />
                </div>
            )

        } else {
            return (

                <Card>
                    <H3 className="text-center">Crear nueva cuenta</H3>

                    <div>

                        <FormGroup
                            label="Nombre"
                            labelInfo="*"
                        >
                            <InputGroup value={ state.name.value } onChange={ e =>  dispatch({ type: "nameInmmediately", value: e.target.value }) } id="name" />
                            <CSSTransition in={ state.name.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.name.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Apellidos"
                            labelInfo="*"
                        >
                            <InputGroup value={ state.surname.value } onChange={ e =>  dispatch({ type: "surnameInmmediately", value: e.target.value }) } id="surname" />
                            <CSSTransition in={ state.surname.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.surname.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Email"
                            labelInfo="*"
                        >
                            <InputGroup value={ state.email.value } onChange={ e =>  dispatch({ type: "emailInmmediately", value: e.target.value }) } id="email" />
                            <CSSTransition in={ state.email.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.email.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <br />
                        <FormGroup
                            label="Phone"
                            labelInfo="*"
                            helperText="ej: 5512345678 (10 digitos sin espacios ni guiones)"
                        >
                            <InputGroup value={ state.phone.value } onChange={ e =>  dispatch({ type: "phoneInmmediately", value: e.target.value }) } id="phone" />
                            <CSSTransition in={ state.phone.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.phone.message } </div>
                            </CSSTransition>
                        </FormGroup>
                        <br />

                        <FormGroup
                            label="Password"
                            labelInfo="*"
                        >
                            <InputGroup value={ state.password.value } type={showPassword ? "text" : "password"} onChange={ e =>  dispatch({ type: "passwordInmmediately", value: e.target.value }) } id="password" rightElement={ lockButton } />
                            <CSSTransition in={ state.password.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.password.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        {
                            !state.name.hasErrors && !state.surname.hasErrors && !state.email.hasErrors && !state.phone.hasErrors && (state.phone.value.length === 10) && !state.password.hasErrors && ( state.password.value.length >= 8 ) ? (
                                <Button large={ true }  style={{ width:'100%' }} onClick={ () => dispatchApp(fetchSetAddressForm(true)) } > CONTINUAR </Button>
                            ) : (
                                <Button large={ true } style={{ width:'100%' }} disabled > CONTINUAR </Button>
                            )
                        }

                    </div>

                </Card>

            )
        }
    }

    const token = localStorage.getItem( "session" );
    const validSession = ( token !== null );

    const back_url = props.location.from ? ("/" + props.location.from) : "/profile";

    console.log("SIGN UP back_url: ", back_url)

    if( !validSession ) {

        return (
            <div className="row">
            <div className="col-12" >
                <H5 className="text-center"></H5>

                <H5 className="text-center">Crea tu cuenta o <Link to={ back_url }>inicia sesión</Link> para continuar con la compra </H5>
            </div>
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    { getData() }
                </div>
            </div>
        )

    } else {

        sessionUpdated()

        return (
            <Redirect to={ back_url } />
        )

    }
}

export default Register;
