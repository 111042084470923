import React, { useState } from 'react';
import { H4, H5, Icon, Button, Alert, Intent } from "@blueprintjs/core";
import Truncate from '../Shared/Truncate';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { useSelector, useDispatch } from "react-redux";
import { updateCartQuantity, removeFromCart } from '../../actions';
// import { useMediaQuery } from 'react-responsive';
import NumericInput from 'react-numeric-input';
import _ from 'lodash'

const ItemById = ({ item, parentCallback }) => {

    const listado_productos = useSelector( state => state.listaProductos.productos );
    const product = _.find( listado_productos, e => e.offer.some( o => o.id === item.product.id ) );
    const dispatch = useDispatch();
    const isDark = useSelector( state => state.isDark);
    const [quantity, setQuantity] = useState(item.quantity);
    const [isOpenMaxAlert,setIsOpenMaxAlert] = useState(false);
    const [isOpenMinAlert,setIsOpenMinAlert] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    // const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    // const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });

     const quantityMinVal = 1;
    const quantityMaxVal = 50;

    const handleChange = (e) => {

        if( e < quantityMinVal ) {
            setIsOpenMinAlert(true)
            return;
        }

        if( e > quantityMaxVal ) {
            setIsOpenMaxAlert(true)
            return;
        }


        if( quantity !== e ) {
            setQuantity(e)
            dispatch( updateCartQuantity( item.product.id, e ) );
        }

    }

    const handleRemove = (talla) => {
        const modelName = product.name + " Talla " + talla.name;
        dispatch( removeFromCart( item.product.id, modelName) );
    }


    const getData = () => {

        if ( product ) {

            const talla = product.offer.find( e => e.id === item.product.id );
            const seasonSlug = product.brand ? product.brand.slug : 'coleccion';
            const categorySlug = product.category ? product.category.slug : 'categoria';

            return (
                <div className="row">

                    <div className="col-md-2">
                        <div className="square-container">
                            <Link to={ "/" + seasonSlug + "/" + categorySlug + "/" + product.slug } >
                                { product.preview_image ? (<img src={ product.preview_image.path } className="img-responsive" alt={ product.name } />) : "" }
                            </Link>
                        </div>
                    </div>

                    <div className="col-md-4 title">
                        <Link to={ "/" + seasonSlug + "/" + categorySlug + "/" + product.slug } >
                            <H4 className="cart-item__name"><strong>{product.name}</strong> { product.brand ? product.brand.name : '' }</H4>
                        </Link>
                        <p>Talla: { talla.name }</p>
                        <Truncate text={ product.preview_text } />
                    </div>

                    <div>

                    </div>

                    <div className="col-md-6 item-data">
                        <table className="price-table">

                            <thead>
                                <tr>
                                    <th>Precio U.</th>
                                    <th></th>
                                    <th>Cant.</th>
                                    <th>Item total</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td>
                                        <H5 style={{marginBottom:'0'}}>${ numeral( talla.price_value ).format( '0,0.00' ) }</H5>
                                        { parseInt( talla.old_price_value ) !== 0 ? (<span className="old-price">${ numeral( talla.old_price_value).format( '0,0.00' ) }</span>) : false }
                                    </td>

                                    <td>x</td>

                                    <td>
                                        <NumericInput mobile min={quantityMinVal} max={quantityMaxVal} onChange={ e => handleChange(e) } value={quantity} size={3} />
                                    </td>

                                    <td>
                                        <H5 style={{marginBottom:'0'}}>${ numeral( parseInt(item.quantity) *  parseFloat( talla.price_value ) ).format('0,0.00') }</H5>
                                    </td>

                                    <td>
                                        <Button onClick={ () => setIsOpenDelete(true) } className="bp3-minimal">
                                            <Icon icon="trash" />
                                        </Button>
                                    </td>

                                </tr>

                            </tbody>


                        </table>
                    </div>

                    <Alert
                        icon="trash"
                        intent={ Intent.DANGER }
                        isOpen={ isOpenDelete }
                        className={ isDark ? 'bp3-dark' : '' }
                        onCancel={ () => setIsOpenDelete(false) }
                        onConfirm={ () => handleRemove(talla) }
                        cancelButtonText="Cancelar"
                        canEscapeKeyCancel={ true }
                        canOutsideClickCancel={ true }
                        confirmButtonText="Eliminar"
                    >
                        <p>
                            ¿Estás seguro de eliminar <strong>{ product.name }</strong> talla <strong>{ talla.name }</strong> del carrito?
                        </p>

                    </Alert>

                    <Alert
                        isOpen={ isOpenMaxAlert }
                        className={ isDark ? 'bp3-dark' : '' }
                        onClose={ () => setIsOpenMaxAlert(false) }
                        canEscapeKeyCancel={ false }
                        canOutsideClickCancel={ false }
                        confirmButtonText="Okay"
                    >
                        <p>
                            ¡Has superado los artículos disponibles ({ quantityMaxVal }) para este producto!
                        </p>

                    </Alert>

                    <Alert
                        isOpen={ isOpenMinAlert }
                        className={ isDark ? 'bp3-dark' : '' }
                        onClose={ () => setIsOpenMinAlert(false) }
                        canEscapeKeyCancel={ false }
                        canOutsideClickCancel={ false }
                        confirmButtonText="Okay"
                    >
                        <p>
                            La cantidad debe ser mayor o igual a 1
                        </p>

                    </Alert>

                </div>
            )

        } else {

            return "LOADING ITEMS"

        }

    }

    return (

        <div>

            { getData() }



        </div>

    )

}

export default ItemById;
