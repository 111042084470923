import React from 'react';
import { useSelector } from "react-redux";
import { H4, H5, Tooltip, Position } from "@blueprintjs/core";
import numeral from 'numeral';
import { useMediaQuery } from 'react-responsive';
import Truncate from '../Shared/Truncate';
import _ from 'lodash'

const ResumeItem = ({ item }) => {

    const isTablet = useMediaQuery({ query: '(max-width: 991px) and (min-width: 768px)' });
    const listado_productos = useSelector( state => state.listaProductos.productos );
    const product = _.find( listado_productos, e => e.offer.some( o => o.id === item.product.id ) );

    const getData = () => {

        return (
            <div className="row">

                {
                    product.preview_image ? (

                        <div className="col-3" style={{ paddingRight:0 }}>

                                <Tooltip content={<img alt={ product.name } className="img-responsive" src={ product.preview_image.path } style={{ maxWidth: '300px' }} />} style={{ maxWidth: '300px' }} position={Position.RIGHT}>
                                    <img alt={ product.name } className="img-responsive" src={ product.preview_image.path } />
                                </Tooltip>

                        </div>

                    ) : false
                }

                <div className="col-9 title">

                    <H4 className="cart-item__name">{ item.quantity } X <strong>{ product.name }</strong> { product.brand ? product.brand.name : '' }</H4>

                    <p>Talla: { item.product.name }</p>

                    { item.quantity !== 1 ? (<p>Precio U: ${ item.product.price_value }</p>) : ""}

                    <H5>{ item.quantity !== 1 ? "Item total:" : "Precio: " } { "$" + numeral( item.product.price_value * item.quantity ).format('0,0.00') }</H5>

                    { isTablet ? (<Truncate text={ item.product.preview_text } />) : "" }

                </div>

            </div>
        )

    }

    return getData()

}

export default ResumeItem;
