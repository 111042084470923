import React, { useEffect } from 'react';
import { Spinner } from "@blueprintjs/core";
import { useSelector, connect } from "react-redux";
// import { useHistory } from 'react-router-dom';
import { fetchProducts } from '../../actions';
import _ from 'lodash';

import ProductCard from '../ProductCard';
import './styles.scss'

const Category = ({ match, fetchProducts }) => {

    const slug = match.params.slug;
    const listaProductos = useSelector( state => state.listaProductos );
    // const history = useHistory();
    const { loading, productos } = listaProductos;

    // GET CATEGORIES
    let listado_productos = _.filter( productos, item => item.active === 1 );

    // let array_categories = _.map( listado_productos, ( val, i ) => {
    //     return { 'id': val.category.id, 'title': val.category.name, 'slug': val.category.slug };
    // });
    //
    // array_categories = _.uniqBy(array_categories, 'id');
    //
    // array_categories = _.orderBy( array_categories, ['title'], ['asc'] );


    // GET CATEGORY SLUG
    // const getMatch = array_categories.find( category => category.slug === slug );
    // const getSlug = getMatch ? getMatch.slug : "";


    useEffect(() => {
        fetchProducts()
    },[]);

    const getData = () => {


        listado_productos = productos ? listado_productos.filter( producto => producto.category.slug === slug ) : [] ;
        let displayItems = listado_productos.map( ( producto,i ) => <ProductCard key={ i } producto={ producto } /> )


        if (listado_productos.length > 0) {

            return  displayItems

        } else {

            return (

                <div className="spinner-container" style={{ alignItems:'center' }}>
                    ARTICULOS NO DISPONIBLE
                </div>

            )
        }

    }

    // const getCategories = array_categories.map( i => (
    //
    //     <li key={ i.id } text={ i.title } onClick={ () => history.push("/categoria/" + i.slug) } className={ getSlug === i.slug ? "selected--category": "" } >{i.title}</li>
    //
    // ));

    if( loading ){

        return (
            <div className="row">
                <div className="spinner-container"><Spinner /></div>
            </div>

        )

    } else {
        return (

            <div className="row">

                {/*<div className="col-md-2">

                    <H6>FILTRAR:</H6>
                    <ul className="category-list">
                        { getCategories }
                    </ul>

                </div>*/}

                <div className="col-md-12">

                    <div className="row">
                        { getData() }
                    </div>

                </div>

            </div>

        )
    }



}

const mapDispatchToProps = dispatch => {
  return {
      fetchProducts: () => dispatch(fetchProducts())
  }
}

export default connect(null, mapDispatchToProps)(Category);
