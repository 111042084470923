import React, { useEffect } from 'react';
import ItemById from './ItemById';
import { Card, H4, H5, Icon, Button, Spinner, FormGroup, InputGroup } from "@blueprintjs/core";
import { CSSTransition } from  'react-transition-group'
import { useSelector, connect, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import _ from 'lodash';
import { fetchProducts, removeFromCart, fetchCoupons, fetchPrice, fetchIsDiscount } from '../../actions';
import { CLEAR_CART } from '../../actions/types';
import TarjetasDePago from '../Shared/Components/TarjetasDePago';
import { useImmerReducer } from 'use-immer';

import './styles.scss'

const Cart = ({ fetchProducts, fetchCoupons }) => {

    let cart = useSelector( state => state.cart.cart );
    const isDiscount = useSelector (state => state.isDiscount.isDiscount);
    const coupons = useSelector( state => state.coupons.coupons.data );
    const listado_productos = useSelector( state => state.listaProductos.productos );
    const success = useSelector( state => state.listaProductos.success );
    
    const dispatch = useDispatch();

    // PRECIO ENVIO
    const shipping_price = 0;


    const initialState = {
        codigo: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "codigoInmmediately":
                draft.codigo.hasErrors = false
                draft.codigo.value = action.value

                // if ( draft.codigo.value.length > 30 ) {
                //     draft.codigo.hasErrors = true;
                //     draft.codigo.message = "El nombre no puede exceder los 30 caracteres"
                // }

                // if (draft.codigo.value.trim() === "" ) {
                //     draft.codigo.hasErrors = true;
                //     draft.codigo.message = "Debes proporcionar un nombre válido."
                // }

                return

            case "codigoAfterDelay":

                draft.codigo.value = draft.codigo.value.trim()

                // if ( draft.codigo.value !== "" && draft.codigo.value.length < 3 ) {
                //     draft.codigo.hasErrors = true;
                //     draft.codigo.message = "El nombre debe tener al menos 3 caracteres"
                // }

                // if ( draft.codigo.value && !/^([a-zA-ZÀ-ž\s]+)$/.test(draft.codigo.value) ) {
                //     draft.codigo.hasErrors = true;
                //     draft.codigo.message = "El nombre solo puede contener letras"
                // }

                return
            
            case "invalidCode":
                draft.codigo.hasErrors = true;
                draft.codigo.message = "Código No Válido"
                return
            
            case "inactiveCode":
                draft.codigo.hasErrors = true;
                draft.codigo.message = "Código Inactivo"
                return

            case "usedCode":
                draft.codigo.hasErrors = true;
                draft.codigo.message = "El código ya fue usado"
                return
            
            case "submitForm":
                return
            default:

        }
    }
    
    const [ state, dispatchForm ] = useImmerReducer(ourReducer, initialState);
    
    useEffect(() => {
        fetchProducts();
        fetchCoupons();
    },[]);

    useEffect(() => {
        if (state.codigo.value) {
            
            const delay  = setTimeout(() => dispatchForm({ type: "codigoAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.codigo.value])


    ////////////////// Removes deactivated items from cart

    const cartItems = _.map( cart, (val) => {
        return {
            'model':val.product.product_id,
            'id': val.product.id
        };
    });


    const deactivatedItems = useSelector( state => state.listaProductos.productos.filter( producto => producto.active === 0 ) );

    deactivatedItems.forEach( deactivatedItem => {

        cartItems.forEach( cartItem => {
            setTimeout(() => {
                // Elimina todas las tallas del modelo desactivado
                if (deactivatedItem.id === cartItem.model) {
                    dispatch( removeFromCart( cartItem.id ) )
                }
            }, 10)
        });

    });


    const handleCoupons = (codigo) => {
        
        if(codigo){

            // SI EXISTE EL CUPÓN
            if( coupons.some( v =>  codigo.includes(v.code) )) {
                
                const cupon = coupons.find( v => v.code === codigo);
                const start = new Date(cupon.start_date);
                const end = new Date(cupon.end_date);
                const actual = new Date();

                
                // VALIDAR SI EL CODIGO ESTA ACTIVO
                if(cupon.status === "1") {
                    
                    // VALIDAR SI EL CODIGO ESTA DENTRO DE LA FECHA
                    if(actual > start && actual < end){

                        // VALIDAR SI EL CODIGO TIENE UNA COMPRA
                        // if(!cupon.ticket_id) {  
                            const isDiscountObj = {
                                id: cupon.id,
                                value: cupon.value
                            }
                            dispatch(fetchIsDiscount(isDiscountObj));

                        // } else {
                        //     dispatchForm({ type: "usedCode" });
                        // }

                    } else {
                        dispatchForm({ type: "inactiveCode" });
                    }

                } else {
                    dispatchForm({ type: "inactiveCode" });
                }
                
            } else {
                dispatchForm({ type: "invalidCode" })
            }

        }
    } 

    const handleGoToCheckout = (priceObject) => {
        dispatch(fetchPrice(priceObject));
    }

    const getCart = () => {

        if( cart.length > 0 ) {

            if ( success ) {
                

                //////////////// SE CALCULA EL Subtotal

                // Se buscan los ids del carrito en el catálogo.
                let cartArray = _.map( cart, val => _.find( listado_productos, item => item.offer.some( item => item.id === val.product.id ) ) );

                // Se dejan solamente los modelos
                cartArray = _.uniqBy(cartArray, 'id');

                // Se limpia el carrito si hay un producto inactivo
                cartArray.forEach( products => {
                    if(parseInt(products.active) === 0){
                        dispatch({ type: CLEAR_CART });
                    }
                });


                let offersArr = [];
                const cartCopy = cart.map( e => e )

                // undefined ID
                if (cartArray[0]) {

                    const offers = _.flatten( cartArray.map( x => x.offer ) );
                    offersArr = cartCopy.map( el => offers.find( i => i.id === el.product.id ) )

                    // cartCopy.map(obj => offersArr.find(o => o.id === obj.product.id) || obj);

                    cartCopy.map( (x, idx) => cartCopy[idx].product = offersArr.find( i => i.id === x.product.id ) )

                } 


                // Subtotal price
                let subtotal = 0;
                cartCopy.map( item => subtotal += parseFloat(item.product.price_value) * item.quantity );

                // DESCUENTO POR CUPONES
                let descuentoPrecio = 0;
                let productosConDescuento = 0;

                if(isDiscount) {
                    const descuentoPorcentaje = parseInt(isDiscount.value) / 100;
                    descuentoPrecio = subtotal * descuentoPorcentaje;
                    productosConDescuento = subtotal - descuentoPrecio;
                }
                
                const totalPrice = descuentoPrecio !== 0 ? productosConDescuento + shipping_price : subtotal + shipping_price; 

                const priceObject = {
                    subtotal,
                    discount: isDiscount,
                    productosConDescuento,
                    totalPrice,
                }

                // Order by product ID
                cart = _.orderBy( cart, 'product.id', 'desc' );


                return (

                    <div>

                        <Card>

                            {
                                cart.map( item => {

                                    return (
                                        <div className="cart-row" key={ item.product.id }>
                                            <ItemById item={ item } />
                                            <hr />
                                        </div>
                                    )
                                })
                            }

                        </Card>

                        {
                            <div className="cart-footer">
                                <div className="row">
                                    <div className="col-sm col-md-5 offset-md-7 text-right">

                                        <H4 style={{ marginTop:'1rem' }}>Productos: <span>${ numeral(subtotal).format('0,0.00') } MXN</span></H4>

                                        {
                                            parseInt(descuentoPrecio) === 0 ? (
                                                <div className='cart-footer__discount'>
                                                    <FormGroup>
                                                        <InputGroup value={ state.codigo.value } onChange={ e =>  dispatchForm({ type: "codigoInmmediately", value: e.target.value }) } placeholder=" INGRESA CÓDIGO DE DESCUENTO" id="codigo" />
                                                        <CSSTransition in={ state.codigo.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                                            <div className="alert alert-danger small liveValidateMessage"> { state.codigo.message } </div>
                                                        </CSSTransition>
                                                    </FormGroup>

                                                    <Button large={ true } onClick={() => handleCoupons(state.codigo.value)}> APLICAR </Button>

                                                </div>
                                            ) : (
                                                <div>
                                                    <H4>Subtotal: ${ numeral(productosConDescuento).format('0,0.00')} <span>({ isDiscount.value }% de descuento)</span></H4>
                                                </div>
                                            )
                                        }
                                        
                                        <H5>Envío: <span>${ numeral(shipping_price).format('0,0.00') } MXN</span><span><em> *Envío gratuito</em></span></H5>
                                        <hr />
                                        <H4 style={{ marginTop:'1rem' }}>TOTAL: <span>${ numeral(totalPrice).format('0,0.00') } MXN</span></H4>
                                    </div>

                                </div>
                            </div>
                        }

                        <div style={{ marginTop: '2rem' }} >
                            <Link to={ '/checkout' } >
                                <Button large={true} style={{ width:'100%' }} onClick={() => handleGoToCheckout(priceObject)}>CONTINUAR CON EL PAGO</Button>
                            </Link>
                        </div>
                            
                        <TarjetasDePago />

                    </div>

                )

            } else {

                return (

                    <div className="row">
                        <div className="spinner-container">
                            <Spinner />
                        </div>
                    </div>

                )

            }


        } else {

            return (

               <Card className="text-center">
                   <p>Tu carrito de compras está vacío.</p>
                   <Link to="/">
                       <Button text="Ir de shopping" large={true} />
                   </Link>
               </Card>

           )

        }
    }

    return (

        <div className="cart-container">

            <div className="row">
                <div className="col-sm text-center" >
                    <H5><Icon icon="shopping-cart" /> Carrito</H5>
                </div>
            </div>

            { getCart() }

        </div>

    )

}

const mapDispatchToProps = dispatch => {

  return {
      fetchProducts: () => dispatch(fetchProducts()),
      removeFromCart: (productId) => dispatch(removeFromCart(productId)),
      fetchCoupons: () => dispatch(fetchCoupons())
  }

}


export default connect(null, mapDispatchToProps)(Cart);
