import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_DARKTHEME } from "./actions/types";
import { Switch, Icon, Alignment } from "@blueprintjs/core";

const DarkThemeToggle = () => {

    const isDarkMode = useSelector( state => state.isDark );
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch({ type: TOGGLE_DARKTHEME })
    }

    const switchStyle = {
        marginBottom: '0',
        marginRight: '11px'
    };

    return (
        <div className="flex-container">
            { isDarkMode ? (<Icon icon="flash" className="bp3-dark" />) : '' }
            <Switch checked={ isDarkMode } onChange={ handleClick } icon="refresh" alignIndicator={ Alignment.RIGHT } style={ switchStyle }/>
            <Icon icon="moon" className={ isDarkMode ? "bp3-dark" : ""} />
        </div>
    )
};

export default DarkThemeToggle;
