// import React, { useState } from 'react';
import React, { useEffect } from 'react';
import { Card, H4, H5, Button, Intent, Spinner } from "@blueprintjs/core";
import { useSelector, useDispatch, connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import ResumeItem from './ResumeItem';
import LoginPage from '../Login';
import _ from 'lodash';
import PaypalBtn from 'react-paypal-checkout';
import { AppToaster } from '../Shared/Toaster'
import{ fetchCreateOrder } from '../../actions';
import moment from 'moment';
import { fetchProducts } from '../../actions';

import numeral from 'numeral';

import './styles.scss'

const Checkout = ({ fetchProducts }) => {

    const cart = useSelector( state => state.cart.cart );
    const price = useSelector (state => state.price.price);
    const user_login = useSelector( state => state.user_login );
    const isOrderCreated = useSelector( state => state.create_order.success );
    const listaProductos = useSelector( state => state.listaProductos );
    const { success, productos } = listaProductos;
    const token = localStorage.getItem( "session" );
    const validSession = ( token !== null );
    const dispatch = useDispatch();


    // PRECIO ENVIO
    const shipping_price = 0;


    useEffect(() => {
        fetchProducts()
    },[]);

    const getCartResume = ( totalPrice, subtotal ) => {

        // sort by product ID
        const cart_ordered = _.orderBy( cart, 'product.id', 'desc' );

        if( cart.length > 0 ) {

            return (
                <div>

                    <Card>

                        {
                            cart_ordered.map( item => {

                                return (
                                    <div className="cart-row" key={ item.product.id }>
                                        { <ResumeItem item={ item } /> }
                                        <hr />
                                    </div>
                                )
                            })
                        }

                    </Card>
                        <Link to={ '/cart' } >
                            <Button minimal={true} large={true} style={{width:'100%'}} > EDITAR COMPRA</Button>
                        </Link>

                    <div className="cart-footer">
                        <div className="row">
                            <div className="col-10 offset-2">
                                {
                                    price.discount ? (
                                        <H5 className="text-right" style={{ marginTop:'1rem' }}>Productos: <span>${ numeral(price.subtotal).format('0,0.00') } MXN</span></H5>
                                    ) : ''
                                }

                                <H5 className="text-right" style={{ marginTop:'1rem' }}>Subtotal: <span>${ numeral(subtotal).format('0,0.00') } MXN </span>
                                    {
                                        price.discount ? (
                                            <span>({ price.discount.value }% de descuento)</span>
                                        ) : ''
                                    }
                                </H5>
                                <H5 className="text-right">Envío: <span>${ numeral(shipping_price).format('0,0.00') } MXN</span></H5>
                                <hr />
                                <H4 className="text-right">Total: <span>${ numeral(totalPrice).format('0,0.00') } MXN</span></H4>
                            </div>

                        </div>
                    </div>

                </div>

            )
        }
    }

    const getData = ( user_order, totalPrice, subtotal ) => {

        let locale = 'es_MX';

        let env = 'production';
		let currency = 'MXN';

        const client = {
            sandbox:    'AXQcHsIeAS9LsKRFMsAUXoJ-mcrpF9Dn48cmommBhdlv6Uf4J-EMhfuBO5vKoPlaYuQX0vBirvkIS0Jr',
            production: 'AQY2stcrtiMgmTuYKY88bCoe0fyceaibbQldv4R0sLc2HsroeqrwxwmJxKWVk5bmEQOHfvnHP6SGQiMF',
		}

        const items_list = _.map( cart, ( val, i ) => {

            const product_name = productos.find( e => e.id === parseInt(val.product.product_id) )

            const completeProductName = product_name.name + " " + product_name.brand.name;

            return {
                "name": completeProductName,
                "description": "Talla " + val.product.name,
                "quantity": val.quantity,
                "price": val.product.price_value,
                "sku": "sku-" + val.product.id,
                "currency": currency
            };

        });

        const onSuccess = (payment) => {

            // console.log("-------------- payment -------------")
            // console.log(payment)

            const today = moment().format("YYYY-MM-DD HH:mm:ss");
            const full_name = user_login.user.name + " " + user_login.user.surname
            const cupon_id = price.discount ? price.discount.id : null;

            const order = new URLSearchParams()
            order.append( 'name', full_name );
            order.append( 'email', user_login.user.email );
            order.append( 'phone', user_login.user.phone );
            order.append( 'price', totalPrice );
            order.append( 'payment_method', "Paypal" );
            order.append( 'order_detail', user_order );
            order.append( 'address', user_login.user.address );
            order.append( 'user_id', user_login.user.id );
            order.append( 'status', "PROCESANDO" );
            order.append ( 'created_at', today )
            order.append ( 'coupon_id', cupon_id )

            dispatch( fetchCreateOrder(order) )

            AppToaster.show({ message: "¡GRACIAS!, El pago fue exitoso .." });

		}

		const onCancel = (data) => {

			// console.log('PAGO CANCELADO!', data);

		}

		const onError = (err) => {

			console.log("Error!", err);
            AppToaster.show({ message: "Error al cargar Paypal!, Intente de nuevo mas tarde", intent: Intent.DANGER, });

		}

        const paypalBtnStyle = {
            layout: 'vertical',
            size: 'responsive',
            label: 'pay',
            shape: 'rect',
            tagline: false,
        };

        const paymentOptions = {
            transactions: [{
                amount: {
                    total: totalPrice,
                    currency: currency,
                    details:{
                        subtotal: subtotal,
                        // shipping: shipping_price,
                      }
                },
                description: "Supersticion MX Shopping Website.",
                item_list: {
                    items: items_list,
                }
            }],
        }

        if( validSession ) {

            if( isOrderCreated ) {

                return <Redirect to={{ pathname: '/profile', from: 'thankyou' }}  />

            } else {

                return (

                    <div className="row flex-column-reverse flex-lg-row">

                        <div className="col-lg-5 offset-lg-1">

                            <Card>

                                <div className="row">
                                    <div className="col-md-6">
                                        <H4>Nombre:</H4>
                                        <p>{ user_login.user.name + " " + user_login.user.surname }</p>
                                    </div>
                                    <div className="col-md-6">
                                        <H4>Email:</H4>
                                        <p>{ user_login.user.email }</p>
                                    </div>
                                </div>

                                <br />

                                <div className="row">
                                    <div className="col-md-12">
                                        <H4>Dirección de envío:</H4>
                                        <p>{ user_login.user.address_line_1 + ", " + ( user_login.user.address_ref ? user_login.user.address_ref + '. ' : '' ) + user_login.user.address_colonia + ", " + user_login.user.address_ciudad + ", " + user_login.user.address_estado + ", México. CP. " + user_login.user.address_postal_code   }</p>
                                    </div>
                                </div>

                                <br />

                                {
                                    user_login.user.phone ? (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <H4>Número de contacto:</H4>
                                                <p>{ user_login.user.phone }</p>
                                            </div>
                                            <br />
                                        </div>
                                    ) : ""
                                }

                            </Card>

                            <H4 className="text-center" style={{marginTop:'2rem'}}>Elige tu forma de pago: </H4>

                            <PaypalBtn
                                client={ client }
                                currency={ currency }
                                total={ totalPrice }
                                style={ paypalBtnStyle }
                                paymentOptions={ paymentOptions }
                                shipping={ 1 }
                                onError={ onError }
                                onSuccess={ onSuccess }
                                onCancel={ onCancel }
                                env={ env }
                                locale={ locale }
                            />

                        </div>

                        <div className="col-lg-5">

                            { getCartResume( totalPrice, subtotal ) }

                        </div>

                    </div>

                )

            }

        } else {

            return (
                <LoginPage from_url="checkout" format="double" />
            )
        }

    }

    if( cart.length > 0 ){

        if ( success ) {

            let cartArray = _.map( cart, val => _.find( productos, item => item.offer.some( item => item.id === val.product.id ) ) );
            cartArray = _.uniqBy(cartArray, 'id');
            let algunProductoInactivo = false;

            cartArray.forEach( products => {
                if(parseInt(products.active) === 0){
                    algunProductoInactivo = true;
                }
            });


            if(price && !algunProductoInactivo) {

                ////////////////////////////
                // DETALLE DE LA ORDEN

                let user_order = [];

                cart.forEach( function( item,i ) {

                    const product_name = productos.find( e => e.id === parseInt(item.product.product_id) )

                    let order_item = `
${ product_name.name } ${ product_name.brand ? product_name.brand.name : "" } ${ item.quantity > 1 ? "(Cant. " + item.quantity + ")" : "" }
Talla: ${ item.product.name }
Precio: $${ parseFloat( item.product.price_value ) * item.quantity }
`;

                    user_order.push( order_item );

                })

                user_order = user_order.toString();

                ////////////////////////////

                const totalPrice = price.totalPrice;
                const subtotal = price.discount ? price.productosConDescuento : price.subtotal;

                return getData( user_order, totalPrice, subtotal  )

            } else {
                return <Redirect to={{ pathname: '/cart' }}  />
            }


        } else {

            return (

                <div className="row">
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                </div>

            )

        }

    } else {

        return (

            <Card className="text-center">
                <p>Tu carrito de compras está vacío.</p>
                <Link to="/">
                    <Button text="Ir de shopping" large={true} />
                </Link>
            </Card>

        )

    }



}

const mapDispatchToProps = dispatch => {

  return {
      fetchProducts: () => dispatch(fetchProducts()),
  }

}


export default connect(null, mapDispatchToProps)(Checkout);
