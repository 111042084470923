import {  combineReducers } from 'redux';
import { fetchProducts } from './reducer_productos';
import { fetchDarkMode } from './reducer_darkmode';
import { fetchProductFilter } from './reducer_productfilter';
import { fetchCategoryFilter } from './reducer_categoryfilter';
import { cartReducer } from './reducer_basket';
import { fetchLoginUser } from './reducer_user_login';
import { fetchRegisterUser } from './reducer_user_register';
import { fetchCreateOrder } from './reducer_create_order';
import { fetchSetAddressForm } from './reducer_address_form';
import { fetchUserProfile } from './reducer_user_orders';
import { fetchResetPassword } from './reducer_user_reset_password'
import { fetchForgotPassword } from './reducer_user_forgot_password'
import { fetchUpdateAddress } from './reducer_update_address'
import { fetchMostSelledProducts } from './reducer_mostselled';
import { fetchCoupons } from './reducer_coupons';
import { fetchPrice } from './reducer_price';
import { fetchIsDiscount } from './reducer_iscoupon';

const rootReducer = combineReducers({
    listaProductos: fetchProducts,
    isDark: fetchDarkMode,
    products_filter: fetchProductFilter,
    category_filter: fetchCategoryFilter,
    cart: cartReducer,
    user_login: fetchLoginUser,
    user_register: fetchRegisterUser,
    create_order: fetchCreateOrder,
    address_form: fetchSetAddressForm,
    user_orders:fetchUserProfile,
    password_reset: fetchResetPassword,
    password_forgot: fetchForgotPassword,
    address_updated: fetchUpdateAddress,
    most_selled: fetchMostSelledProducts,
    coupons: fetchCoupons,
    price: fetchPrice,
    isDiscount: fetchIsDiscount
});

export default rootReducer;
