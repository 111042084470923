import React, { useEffect } from 'react';
import { Spinner } from '@blueprintjs/core';
import { connect, useSelector } from 'react-redux';
import { fetchProducts } from '../../actions';
import _ from 'lodash';

import ProductGrid from '../ProductGrid';

const Rebajas = ({fetchProducts}) => {

    const listaProductos = useSelector( state => state.listaProductos );
    let listado_productos = listaProductos.productos;
    const { loading, success } = listaProductos;
    
    // Se obtienen los productos activos
    listado_productos = _.filter( listado_productos, item => item.active === 1 );
    listado_productos = _.orderBy( listado_productos, ['updated_at'], ['desc'] );
    listado_productos = _.orderBy( listado_productos , ['brand.id'], ['desc'] );

    useEffect(() => {
        fetchProducts()
    },[]);

    const getData = () => {

        if (success) {

            if( listado_productos.length > 0 ){
                
                return (
                    <div className='homepage'>
                        <ProductGrid listado_productos={listado_productos} productFilterType={"sales"} isShowAll={true} />
                    </div>
                )

            } else {

                return (
                    <div className="row">
                        <div className="spinner-container" style={{ alignItems:'center' }}>
                            NO PRODUCTS
                        </div>
                    </div>
                )
            }

        } else {

            return (
                <div className="row">
                    <div className="spinner-container" style={{ alignItems:'center' }}>
                        HUBO UN ERROR
                    </div>
                </div>
            )
        }


    }

    if ( !loading ) {

        return getData()

    } else {

        return (
            <div className="row">
                <div className="spinner-container">
                    <Spinner />
                </div>
            </div>
        )

    }

}


const mapDispatchToProps = dispatch => {
    return {
        fetchProducts: () => dispatch( fetchProducts() )
    }
}
  
export default connect(null, mapDispatchToProps)(Rebajas);
