import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { loadState, saveState } from './SaveStore'


export default ({ children, initialState = loadState() }) => {

    const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunk)) );

    store.subscribe( function () {
        saveState( store.getState() )
    })

    return <Provider store={ store }>{ children }</Provider>;
};
