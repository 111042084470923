import { FETCH_MOSTSELLED_REQUEST, FETCH_MOSTSELLED_SUCCESS, FETCH_MOSTSELLED_FAILURE } from '../actions/types';

const initialState = {
    loading: true,
    products: [],
    error: '',
    success: false
}

export function fetchMostSelledProducts( state=initialState, action ){
    switch( action.type ){
        case FETCH_MOSTSELLED_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_MOSTSELLED_SUCCESS:
            console.log("FETCH_MOSTSELLED_SUCCESS")
            return {
                loading: false,
                products: action.payload,
                error: '',
                success: true
            }

        case FETCH_MOSTSELLED_FAILURE:
            return  {
                loading: false,
                products:[],
                error: action.payload,
                success: false,
            }

        default:
            return state;
    }
}
