import { FETCH_CREATE_ORDER_REQUEST, FETCH_CREATE_ORDER_SUCCESS, FETCH_CREATE_ORDER_FAILURE, FETCH_END_ORDER } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster';
import { Intent } from "@blueprintjs/core";

const initialState = {
    loading: false,
    success: false,
    error: null
}

export function fetchCreateOrder( state=initialState, action ){
    switch( action.type ){
        case FETCH_CREATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_CREATE_ORDER_SUCCESS:
            console.log( "=== FETCH_CREATE_ORDER_SUCCESS ===" )
            return {
                loading: false,
                success: true,
                error: ''
            }

        case FETCH_CREATE_ORDER_FAILURE:

            console.log("*** FETCH_CREATE_ORDER_FAILURE ***", action.payload.response )

            if( action.payload.response.statusText === "Unauthorized" ) {
                localStorage.removeItem("session")
                AppToaster.show({ message: "Tu sesión ha terminado. Por favor inicia sesión de nuevo", intent: Intent.DANGER, });
            } else {
                AppToaster.show({ message: "Hubo un error al crear la orden, por favor intente de nuevo mas tarde", intent: Intent.DANGER, });
            }

            return  {
                loading: false,
                success:false,
                error: true
            }

        case FETCH_END_ORDER:
            console.log( "=== FETCH_END_ORDER ===" )
            return {
                loading: false,
                success: false,
                error: ''
            }

        default:
            return state;
    }
}
