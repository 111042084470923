import React from 'react';
import VisaLogo from "../images/visa.svg";
import MasterCardLogo from "../images/mastercard.svg"
import AmexLogo from "../images/amex.svg"
import PayPalLogo from "../images/paypal.svg"

const TarjetasDePago = (props) => {

    return (
        <div className="row justify-content-end mt2 tarjetas_de_pago">
            <div className="col-12 text-right">
                <p>Aceptamos todas las tarjetas</p>
            </div>

            <img src={PayPalLogo} alt="PayPal" />
            <img src={ VisaLogo } alt="Visa" />
            <img src={ MasterCardLogo } alt="MasterCard" />
            <img src={ AmexLogo } alt="American Express" />

        </div>

    )

}

export default TarjetasDePago;
