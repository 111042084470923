import { FETCH_BRANDS_REQUEST, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAILURE } from '../actions/types';

const initialState = {
    loading: true,
    productos: [],
    error: '',
    success: false
}

export function fetchProducts( state=initialState, action ){
    switch( action.type ){
        case FETCH_BRANDS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_BRANDS_SUCCESS:
            console.log("FETCH_BRANDS_SUCCESS")
            return {
                loading: false,
                productos: action.payload,
                error: '',
                success: true
            }

        case FETCH_BRANDS_FAILURE:
            return  {
                loading: false,
                productos:[],
                error: action.payload,
                success: false,
            }

        default:
            return state;
    }
}
