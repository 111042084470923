import React, { useEffect } from 'react';
import { Card, FormGroup, InputGroup, H3, Button } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import{ fetchLoginUser } from '../../actions';
import { useImmerReducer } from 'use-immer';
import { CSSTransition } from  'react-transition-group';
import { Link } from 'react-router-dom';

const Login = () => {

    const loading = useSelector( state => state.user_login.loading );

    const dispatchApp = useDispatch();

    const handleLogin = ( e ) => {
        e.preventDefault();

        const user = new URLSearchParams()
        user.append( 'login', state.email.value );
        user.append( 'password', state.password.value );

        dispatchApp( fetchLoginUser(user) )

    }

    const initialState = {
        email: {
            value: "",
            hasErrors: true,
            message: ""
        },
        password: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "emailInmmediately":
                draft.email.hasErrors = false
                draft.email.value = action.value

                return

            case "emailAfterDelay":

                if ( !/^\S+@\S+$/.test(draft.email.value) ) {
                    draft.email.hasErrors = true
                    draft.email.message = "Debes proporcionar un email válido."
                }

                return

            case "passwordInmmediately":
                draft.password.hasErrors = false
                draft.password.value = action.value

                if ( draft.password.value.length > 64 ) {
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña no puede superar los 64 caracteres"
                }

                return

            case "passwordAfterDelay":

                if( draft.password.value.length < 8 ){
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña debe tener al menos 8 caracteres"
                }

                return

            default:

        }
    }

    const [ state, dispatch ] = useImmerReducer(ourReducer, initialState);

    useEffect(() => {
        if (state.email.value) {
            const delay  = setTimeout(() => dispatch({ type: "emailAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.email.value])

    useEffect(() => {
        if (state.password.value) {
            const delay  = setTimeout(() => {
                dispatch({ type: "passwordAfterDelay" })
            }, 800)

            return () => clearTimeout(delay)
        }
    }, [state.password.value])

    const getData = () => {

        return (
            <Card>

                <H3 className="text-center">Ya soy cliente</H3>

                <form onSubmit={ handleLogin }>

                    <FormGroup label="Email" >
                        <InputGroup onChange={ e =>  dispatch({ type: "emailInmmediately", value: e.target.value }) } id="email" />
                        <CSSTransition in={ state.email.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.email.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <FormGroup label="Password" >
                        <InputGroup type="password" onChange={ e =>  dispatch({ type: "passwordInmmediately", value: e.target.value }) } id="password" />
                        <CSSTransition in={ state.password.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                            <div className="alert alert-danger small liveValidateMessage"> { state.password.message } </div>
                        </CSSTransition>
                    </FormGroup>

                    <Link to={ "/auth/reset-password" }><span className="btn text-center" style={{ float:'right',marginTop:"-.2rem", marginBottom:"1rem" }}> Olvidé mi contraseña </span></Link>

                    { !state.email.hasErrors && !state.password.hasErrors && ( state.password.value.length >= 8 ) && ( state.email.value.length > 0 ) ? <Button type="submit" loading={ loading } large={true} style={{ width:'100%' }}>INICIAR SESIÓN</Button> : <Button large={true} style={{ width:'100%' }} disabled >INICIAR SESIÓN</Button> }

                </form>

            </Card>
        )

    }

    return getData()

}

export default Login;
