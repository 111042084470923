import React, { useEffect, useState }  from 'react';
import { Card, H1, H4, H5, Spinner, Button, Dialog, Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { CSSTransition } from  'react-transition-group';
import { useSelector, useDispatch } from "react-redux";
import { useImmerReducer } from 'use-immer';
import{ fetchUserProfile , fetchUpdateAddress } from '../../actions';
import { FETCH_END_ORDER } from '../../actions/types'

import _ from 'lodash';
import { Link } from 'react-router-dom';

import LoginPage from '../Login';
import OrderList from './OrderList';
import './styles.scss'

const Profile = ({ location }) => {

    const loading = useSelector( state => state.user_register.loading );
    const isDark = useSelector( state => state.isDark);
    const user = useSelector( state => state.user_orders );
    const user_login = useSelector( state => state.user_login );
    const isOrderCreated = useSelector( state => state.create_order.success );
    const cart = useSelector( state => state.cart );
    const isAddressUpdate = useSelector( state => state.address_updated )
    const dispatchApp = useDispatch();

    const [isOpenEditProfile, setIsOpenEditProfile] = useState(false);

    const initialState = {
        street: {
            value: "",
            hasErrors: true,
            message: ""
        },
        streetReference: {
            value: "",
            hasErrors: true,
            message: ""
        },
        postalCode: {
            value: "",
            hasErrors: true,
            message: ""
        },
        neighborhood: {
            value: "",
            hasErrors: true,
            message: ""
        },
        city: {
            value: "",
            hasErrors: true,
            message: ""
        },
        province: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "streetInmmediately":
                draft.street.hasErrors = false
                draft.street.value = action.value;

                if ( draft.street.value.length > 30 ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "La calle no puede exceder los 30 caracteres"
                }

                if (draft.street.value.trim() === "" ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "Debes proporcionar una calle válida."
                }
                return

            case "streetAfterDelay":

                draft.street.value = draft.street.value.trim()

                if ( draft.street.value !== "" && draft.street.value.length < 3 ) {
                    draft.street.hasErrors = true;
                    draft.street.message = "La calle debe tener al menos 3 caracteres"
                }

                return

            case "streetReferenceInmmediately":
                draft.streetReference.hasErrors = false
                draft.streetReference.value = action.value

                if ( draft.streetReference.value.length > 50 ) {
                    draft.streetReference.hasErrors = true;
                    draft.streetReference.message = "La referencia no puede exceder los 50 caracteres"
                }

                return

            case "streetReferenceAfterDelay":
                draft.streetReference.value = draft.streetReference.value.trim()

                if ( draft.streetReference.value !== "" && draft.streetReference.value.length < 3 ) {
                    draft.streetReference.hasErrors = true;
                    draft.streetReference.message = "La referencia debe tener al menos 3 caracteres"
                }
                return

            case "postalCodeInmmediately":
                draft.postalCode.hasErrors = false
                draft.postalCode.value = action.value

                if (draft.postalCode.value.trim() === "" ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "Debes proporcionar un código postal válido"
                }

                if ( draft.postalCode.value.length > 5 ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "El código postal no puede exceder los 5 dígitos"
                }

                return

            case "postalCodeAfterDelay":

                if ( draft.postalCode.value.length < 5 ) {
                    draft.postalCode.hasErrors = true
                    draft.postalCode.message = "El código postal debe tener 5 digitos"
                }

                if ( draft.postalCode.value && !/^([0-9]+)$/.test(draft.postalCode.value) ) {
                    draft.postalCode.hasErrors = true;
                    draft.postalCode.message = "El código postal solo puede contener números"
                }

                return

            case "neighborhoodInmmediately":
                draft.neighborhood.hasErrors = false
                draft.neighborhood.value = action.value

                if ( draft.neighborhood.value.length > 30 ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "La colonia no puede exceder los 30 caracteres"
                }

                if (draft.neighborhood.value.trim() === "" ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "Debes proporcionar una colonia válida."
                }

                return

            case "neighborhoodAfterDelay":
                draft.neighborhood.value = draft.neighborhood.value.trim()

                if ( draft.neighborhood.value !== "" && draft.neighborhood.value.length < 3 ) {
                    draft.neighborhood.hasErrors = true;
                    draft.neighborhood.message = "La colonia debe tener al menos 3 caracteres"
                }

                return

            case "cityInmmediately":
                draft.city.hasErrors = false
                draft.city.value = action.value

                if ( draft.city.value.length > 30 ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "La ciudad no puede exceder los 30 caracteres"
                }

                if (draft.city.value.trim() === "" ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "Debes proporcionar una ciudad válida."
                }
                return

            case "cityAfterDelay":
                draft.city.value = draft.city.value.trim()

                if ( draft.city.value !== "" && draft.city.value.length < 3 ) {
                    draft.city.hasErrors = true;
                    draft.city.message = "La ciudad debe tener al menos 3 caracteres"
                }

                return

            case "provinceInmmediately":
                draft.province.hasErrors = false
                draft.province.value = action.value

                if ( draft.province.value.length > 30 ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "El estado no puede exceder los 30 caracteres"
                }

                if (draft.province.value.trim() === "" ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "Debes proporcionar un estado válida."
                }
                return

            case "provinceAfterDelay":
                draft.province.value = draft.province.value.trim()

                if ( draft.province.value !== "" && draft.province.value.length < 3 ) {
                    draft.province.hasErrors = true;
                    draft.province.message = "El estado debe tener al menos 3 caracteres"
                }
                return

            default:
                break;
        }
    }

    const [ state, dispatch ] = useImmerReducer(ourReducer, initialState);

    useEffect(() => {
        if (state.street.value) {
            const delay  = setTimeout(() => dispatch({ type: "streetAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.street.value])

    useEffect(() => {
        if (state.streetReference.value) {
            const delay  = setTimeout(() => dispatch({ type: "streetReferenceAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.streetReference.value])

    useEffect(() => {
        if (state.postalCode.value) {
            const delay  = setTimeout(() => dispatch({ type: "postalCodeAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.postalCode.value])

    useEffect(() => {
        if (state.neighborhood.value) {
            const delay  = setTimeout(() => dispatch({ type: "neighborhoodAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.neighborhood.value])

    useEffect(() => {
        if (state.city.value) {
            const delay  = setTimeout(() => dispatch({ type: "cityAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.city.value])

    useEffect(() => {
        if (state.province.value) {
            const delay  = setTimeout(() => dispatch({ type: "provinceAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.province.value])

    useEffect(() => {

        if( user_login.success ) {
            dispatchApp( fetchUserProfile( user_login.user.id ) );
        }

    }, [user_login.success])

    useEffect(() => {
        if (isOrderCreated) {
            dispatchApp({ type: FETCH_END_ORDER })
        }
    })

    const handleUpdateUserAddress = () => {

        const address = new URLSearchParams();
        address.append( 'address_line_1', state.street.value );
        address.append( 'address_colonia', state.neighborhood.value );
        address.append( 'address_ciudad', state.city.value );
        address.append( 'address_estado', state.province.value );
        address.append( 'address_postal_code', state.postalCode.value );
        address.append( 'address_ref', state.streetReference.value );

        dispatchApp( fetchUpdateAddress(address) )

        setIsOpenEditProfile(false)

    }

    const getUserOrders = () => {

        const { loading, success, user_orders } = user;

        let display_orders = _.orderBy( user_orders, (obj) => parseInt(obj.id), 'desc');
        display_orders = display_orders.map( order => <OrderList key={ order.id } order={ order } /> )


        if ( loading ) {

            return (

                <div className="row">
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                </div>

            )

        } else if (success) {

            return (

                    <div className="row flex-lg-row">

                        { location.from === 'thankyou' ? (
                            <div className="col-lg-10 offset-lg-1">
                                <Card className="text-center">
                                    <H1>¡THANK YOU!</H1>
                                    <H5>Hemos recibido tu pedido. </H5>
                                    <H5>Los pedidos tardan aprox. 7 días hábiles y posteriormente se ponen en paquetería. </H5>
                                    <H5>Puedes monitorear el status aquí</H5>
                                </Card>
                            </div>
                        ) : false }

                        <div className="col-lg-10 offset-lg-1">
                            <H4 className="text-center" style={{marginTop:'2rem'}}>Usuario: </H4>
                            <Card>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <H4>Nombre:</H4>
                                        <p>{ user_login.user.name + " " + user_login.user.surname }</p>
                                        <H4 style={{ display:'inline-block' }}>Email:</H4>
                                        <p>{ user_login.user.email }</p>
                                    </div>
                                    <div className="col-sm-6">

                                        <H4>Dirección de envío:</H4>

                                        {
                                            isAddressUpdate.loading ? (

                                                <p>loading</p>

                                            ) : isAddressUpdate.success ? (

                                                <p>{ state.street.value + ", " + ( state.streetReference.value ? state.streetReference.value + '. ' : '' ) + state.neighborhood.value + ", " + state.city.value + ", " + state.province.value + ", México. CP. " + state.postalCode.value   }</p>

                                            ) : (

                                                <p>{ user_login.user.address_line_1 + ", " + ( user_login.user.address_ref ? user_login.user.address_ref + '. ' : '' ) + user_login.user.address_colonia + ", " + user_login.user.address_ciudad + ", " + user_login.user.address_estado + ", México. CP. " + user_login.user.address_postal_code   }</p>

                                            )

                                        }

                                        <Button onClick={ () => setIsOpenEditProfile(true) }>Editar</Button>

                                        {
                                            user_login.user.phone ? (

                                                <div style={{ marginTop: '2rem' }}>
                                                    <H4 style={{ display:'inline-block' }}>Número de contacto:</H4>
                                                    <p>{ user_login.user.phone }</p>
                                                </div>

                                            ) : false
                                        }

                                    </div>
                                </div>

                            </Card>

                            { user_orders.length > 0 ? (
                                <div>
                                    <H4 className="text-center" style={{marginTop:'2rem'}}>Pedidos: </H4>
                                    { display_orders }
                                </div>
                            ) : (

                                <div className="text-center" >
                                    { cart.cart.length > 0 ? (
                                        <div>
                                            <H4 style={{marginTop:'2rem'}}>¡Completa tu primer pedido!</H4>
                                            <Link to="/checkout">
                                                <Button text="Ir al checkout" large={true} />
                                            </Link>
                                        </div>
                                    ) : (
                                        <div>
                                            <H4 style={{marginTop:'2rem'}}>¡Visita nuestro catalogo y completa tu primer pedido!</H4>
                                            <Link to="/">
                                                <Button text="Ir de shopping" large={true} />
                                            </Link>
                                        </div>
                                    ) }
                                </div>
                            )}



                        </div>

                    </div>

            );

        } else {

            return (
                <div className="row">
                    <div className="spinner-container" style={{ alignItems:'center' }}>
                        TÚ SESISÓN HA TERMINADO. Por favor inica sesión de nuevo.
                    </div>
                </div>

            )

        }
    }

    const getData = () => {

        if( user_login.success ) {

            return getUserOrders()

        } else {

            return <LoginPage from_url="profile" />

        }

    }

    if ( !user_login.loading ) {

        return (
            <div>

                {getData()}

                <Dialog
                    title="Cambiar dirección de envío"
                    isOpen={ isOpenEditProfile }
                    onClose={ () => setIsOpenEditProfile(false) }
                    className={ isDark ? 'bp3-dark' : false }
                    // onConfirm={ () => handleRemove() }
                >

                    <div className={Classes.DIALOG_BODY}>

                        <FormGroup
                            label="País"
                        >
                            <InputGroup value="Mexico" id="country" disabled={true} />
                        </FormGroup>

                        <FormGroup
                            label="Calle y número"
                            labelInfo="*"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "streetInmmediately", value: e.target.value }) } id="street" />
                            <CSSTransition in={ state.street.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.street.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Referencias"
                            helperText="(Entre calles, Piso, # Depto, Oficina, etc)"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "streetReferenceInmmediately", value: e.target.value }) } id="street_reference" />
                            <CSSTransition in={ state.streetReference.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.streetReference.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Colonia"
                            labelInfo="*"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "neighborhoodInmmediately", value: e.target.value }) } id="neighborhood" />
                            <CSSTransition in={ state.neighborhood.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.neighborhood.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Delegación / Ciudad"
                            labelInfo="*"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "cityInmmediately", value: e.target.value }) } id="city" />
                            <CSSTransition in={ state.city.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.city.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Estado"
                            labelInfo="*"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "provinceInmmediately", value: e.target.value }) } id="province" />
                            <CSSTransition in={ state.province.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.province.message } </div>
                            </CSSTransition>
                        </FormGroup>

                        <FormGroup
                            label="Codigo postal"
                            labelInfo="*"
                        >
                            <InputGroup onChange={ e => dispatch({ type: "postalCodeInmmediately", value: e.target.value }) } id="postal_code" />
                            <CSSTransition in={ state.postalCode.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                <div className="alert alert-danger small liveValidateMessage"> { state.postalCode.message } </div>
                            </CSSTransition>
                        </FormGroup>


                        { !state.street.hasErrors && !state.postalCode.hasErrors && ( state.postalCode.value.length === 5 ) && !state.neighborhood.hasErrors && !state.city.hasErrors && !state.province.hasErrors ? <Button loading={ loading } onClick={ () => handleUpdateUserAddress() } large={true} style={{width:'100%'}} > ACTUALIZAR </Button> :  <Button disabled large={true} style={{width:'100%'}} > ACTUALIZAR </Button> }


                    </div>

                </Dialog>

            </div>
        )

    } else {

        return (
            <div className="row">
                <div className="spinner-container">
                    <Spinner />
                </div>
            </div>
        )

    }


}

export default Profile;
