import React, { useEffect, useState } from 'react';
import { useSelector, connect } from "react-redux";
import { fetchProducts } from '../../actions';
import { Card, Elevation, H2, Spinner, H6 } from "@blueprintjs/core";
import Select from 'react-select';
import _ from 'lodash';
import numeral from 'numeral';
import Slider from "react-slick";
import Magnifier from "react-magnifier";
import { useMediaQuery } from 'react-responsive';

import AddToCartBTN from './AddToCartBTN';
import TarjetasDePago from '../Shared/Components/TarjetasDePago';

import './styles.scss'


// SwiperCore.use([Autoplay, Thumbs]);

const ProductDetail = ({ match, fetchProducts }) => {
    const slug = match.params.slug;

    const listaProductos = useSelector( state => state.listaProductos );
    const [size, setSize] = useState({ label: "Selecciona tu talla" });
    const [price, setPrice] = useState(0);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const handleSizeChange = ( selectedSize ) => {
        setSize( selectedSize );
        setPrice( selectedSize.price )
    }

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };

    useEffect(() => {
        fetchProducts()
    },[]);

    useEffect(() => {

        setNav1(slider1);
        setNav2(slider2);

    });

    const getSizesSelector = ( array_tallas ) => {

        return(
            <Select
                value={ size }
                onChange={ handleSizeChange }
                options={ array_tallas }
                classNamePrefix='sizes'
                className={ !price ? "pristine sizes-selector" : "sizes-selector" }
            />
        )

    }

    const getPrice = ( array_tallas ) => {

        let array_precios = _.map( array_tallas,(val) => val.price );

        const allEqual = array_precios.every( (val, i, arr) => val === arr[0] );

        // Se optiene el producto con el precio mas bajo
        const lowest = _( array_tallas )
            .groupBy('id')
            .map( group => _.minBy( group, 'price' ) )
            .value();

        // Si no ha sido seleccionada ninguna talla
        if( price !== 0 ){

            return (

                <div>

                    {
                        parseInt( size.old_price ) !== 0 ? (

                            <span className="price-tooltip">antes <span className="old-price">${ numeral(size.old_price).format('0,0.00') }</span></span>

                        ) : false

                    }

                    <H2>${ numeral(price).format('0,0.00') }</H2>

                </div>

            );

        } else {

            if( lowest.length > 0 ) {

                return (

                    <div className="price-container">

                        {
                            allEqual ? (

                                parseInt( lowest[0].old_price ) !== 0 ? (

                                    <span className="price-tooltip">antes <span className="old-price">${ numeral(lowest[0].old_price).format('0,0.00') } </span></span>

                                ) : false

                            ) : (

                                <span className="price-tooltip">Desde</span>
                            )

                        }

                        { lowest.length > 0 ? (<H2>${ numeral(lowest[0].price).format('0,0.00') }</H2>) : false }

                    </div>

                )

            } else {

                return false

            }


        }

    }

    const getData = () => {

        const { loading, productos } = listaProductos;

        if( !loading ) {

            const producto = productos ? productos.find( producto => producto.slug === slug ) : false ;

            if( productos && producto ){

                let tallas = producto.offer;

                const array_tallas = _.map( tallas, (val, i) => {
                    return {
                        'value': val.id,
                        'label': val.name,
                        'price': parseFloat( val.price_value ),
                        'old_price': parseFloat( val.old_price_value ),
                        'quantity': val.quantity

                    };
                });

                const product_info = {
                    'product_id': producto.id,
                    'id': size.value,

                }

                const priceStyle = {
                    textAlign: 'right',
                    position: 'relative'
                };

                const array_images = _.map( producto.images, images => images );

                if( producto.active !== 0 ){

                    return(

                        <Card elevation={ Elevation.ZERO } className="transparent-bkg">

                            <div className="row producto-single">

                                <div className="col-lg-6">

                                    {
                                        array_images.length > 0 ? (

                                            <div className="slider-wrapper">

                                                <Slider
                                                    { ...settingsMain }
                                                    asNavFor={ nav2 }
                                                    ref={ slider => ( setSlider1(slider) ) } >

                                                    {
                                                        array_images.map( slide => {

                                                            return (

                                                                <div className="slick-slide" key={ slide.id } >
                                                                    <div>
                                                                        { isMobile ? (
                                                                            <img className="slick-slide-image" src={ slide.path } alt={ slide.title } />
                                                                        ) : (
                                                                            <Magnifier src={ slide.path } mgBorderWidth={1} mgWidth={ 250 } mgHeight={ 250 } />
                                                                        ) }
                                                                    </div>
                                                                </div>

                                                            )

                                                        })
                                                    }

                                                </Slider>

                                                <div className="thumbnail-slider-wrap mt1">

                                                    { array_images.length > 1 ? (
                                                        <Slider
                                                            { ...settingsThumbs }
                                                            asNavFor={ nav1 }
                                                            ref={ slider => ( setSlider2(slider) ) }>

                                                            {
                                                                array_images.map((slide) => {

                                                                    return (

                                                                        <div className="slick-slide" key={ slide.id } >
                                                                            <div className="square-container" >
                                                                                <img className="slick-slide-image" src={ slide.path  } alt={ slide.title } />
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }

                                                            )}

                                                        </Slider>
                                                    ) : false }

                                                </div>

                                            </div>

                                        ) : (
                                            <div className="square-container">
                                                { producto.preview_image ? (<img src={ producto.preview_image.path } alt={ producto.title } />) : "" }
                                            </div>
                                        )
                                    }


                                </div>

                                <div className="col-lg-6">

                                    <div className="row producto-single__title title">

                                        <div className="col-sm-8">
                                            <H2>{ producto.name }</H2>
                                            <H6> { producto.brand.preview_text } </H6>
                                        </div>

                                        <div className="col-sm-4">
                                            <div style={ priceStyle} >
                                                { getPrice(array_tallas) }
                                            </div>
                                        </div>

                                    </div>

                                    <div dangerouslySetInnerHTML={{__html: producto.preview_text }} className="producto-single__description" />

                                    <div className="row producto-single__sizes">

                                        <div className="col-sm-12">

                                            <table className="tallas-table">

                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Busto</th>
                                                        <th>Cintura</th>
                                                        <th>Cadera</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>XSmall <strong>(XS)</strong></td>
                                                        <td>88</td>
                                                        <td>64</td>
                                                        <td>88</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Small <strong>(S)</strong></td>
                                                        <td>92</td>
                                                        <td>70</td>
                                                        <td>92</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Medium <strong>(M)</strong></td>
                                                        <td>96</td>
                                                        <td>76</td>
                                                        <td>96</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Large <strong>(L)</strong></td>
                                                        <td>100</td>
                                                        <td>90</td>
                                                        <td>102</td>
                                                    </tr>
                                                    <tr>
                                                        <td>XLarge <strong>(XL)</strong></td>
                                                        <td>106</td>
                                                        <td>98</td>
                                                        <td>109</td>
                                                    </tr>
                                                    <tr>
                                                        <td>XLarge <strong>(XXL)</strong></td>
                                                        <td>110</td>
                                                        <td>105</td>
                                                        <td>114</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="col-sm-1" style={{alignSelf:'center'}}>
                                            <p style={{margin:'0'}}>Talla: </p>
                                        </div>

                                        <div className="col-sm-11">
                                            { getSizesSelector(array_tallas) }
                                        </div>

                                    </div>

                                    <AddToCartBTN product={product_info} size={size} />

                                    <TarjetasDePago />
                                </div>

                            </div>

                        </Card>

                  )

                } else {

                    return (

                        <div className="row">
                            <div className="spinner-container" style={{ alignItems:'center' }}>
                                ARTICULO NO DISPONIBLE
                            </div>
                        </div>

                    )

                }

            } else {

                return (

                    <div className="row">
                        <div className="spinner-container" style={{ alignItems:'center' }}>
                            ARTICULO NO ENCONTRADO
                        </div>
                    </div>

                )

            }

        } else {

            return (

                <div className="row">
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                </div>

            )

        }

    }


    return getData()

}


const mapDispatchToProps = dispatch => {
  return {
      fetchProducts: () => dispatch(fetchProducts())
  }
}

export default connect(null, mapDispatchToProps)(ProductDetail);
