import { IS_DISCOUNT } from '../actions/types';

const initialState = {
    isDiscount: null,
}

export function fetchIsDiscount( state=initialState, action ) {

    switch( action.type ){

        case IS_DISCOUNT:
            return {
                isDiscount: action.payload
            }

        default:
            return state;

    }

}
