import React from 'react';

const Instagram = () => {

    return (

        <a href="https://www.instagram.com/supersticionmx/" rel="noopener noreferrer" target="_blank">
            <div className="footer-redes__el">
                <svg className="instagrama" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                    <g>
                    <path d="M39.5,14.2c8.3,0,9.3,0,12.6,0.2c3,0.1,4.7,0.6,5.8,1.1c1.5,0.6,2.5,1.2,3.6,2.3c1.1,1.1,1.8,2.1,2.3,3.6 c0.4,1.1,0.9,2.8,1.1,5.8c0.1,3.3,0.2,4.3,0.2,12.6c0,8.3,0,9.3-0.2,12.6c-0.1,3-0.6,4.7-1.1,5.8c-0.6,1.5-1.2,2.5-2.3,3.6 c-1.1,1.1-2.1,1.8-3.6,2.3c-1.1,0.4-2.8,0.9-5.8,1.1c-3.3,0.1-4.3,0.2-12.6,0.2c-8.3,0-9.3,0-12.6-0.2c-3-0.1-4.7-0.6-5.8-1.1 c-1.5-0.6-2.5-1.2-3.6-2.3c-1.1-1.1-1.8-2.1-2.3-3.6c-0.4-1.1-0.9-2.8-1.1-5.8c-0.1-3.3-0.2-4.3-0.2-12.6c0-8.3,0-9.3,0.2-12.6 c0.1-3,0.6-4.7,1.1-5.8c0.6-1.5,1.2-2.5,2.3-3.6c1.1-1.1,2.1-1.8,3.6-2.3c1.1-0.4,2.8-0.9,5.8-1.1C30.1,14.2,31.1,14.2,39.5,14.2 M39.5,8.6c-8.5,0-9.5,0-12.9,0.2C23.3,8.9,21,9.4,19,10.2c-2.1,0.8-3.8,1.9-5.5,3.6c-1.7,1.7-2.8,3.5-3.6,5.5 c-0.8,2-1.3,4.3-1.5,7.6c-0.2,3.3-0.2,4.4-0.2,12.9s0,9.5,0.2,12.9c0.2,3.3,0.7,5.6,1.5,7.6c0.8,2.1,1.9,3.8,3.6,5.5 c1.7,1.7,3.5,2.8,5.5,3.6c2,0.8,4.3,1.3,7.6,1.5C29.9,71,31,71,39.5,71c8.5,0,9.5,0,12.9-0.2c3.3-0.2,5.6-0.7,7.6-1.5 c2.1-0.8,3.8-1.9,5.5-3.6c1.7-1.7,2.8-3.5,3.6-5.5c0.8-2,1.3-4.3,1.5-7.6c0.2-3.3,0.2-4.4,0.2-12.9s0-9.5-0.2-12.9 c-0.2-3.3-0.7-5.6-1.5-7.6c-0.8-2.1-1.9-3.8-3.6-5.5c-1.7-1.7-3.5-2.8-5.5-3.6c-2-0.8-4.3-1.3-7.6-1.5C49,8.6,47.9,8.6,39.5,8.6 M39.5,23.8c-8.9,0-16,7.2-16,16s7.2,16,16,16c8.9,0,16-7.2,16-16S48.3,23.8,39.5,23.8 M39.5,50.2c-5.7,0-10.4-4.7-10.4-10.4 c0-5.7,4.7-10.4,10.4-10.4c5.7,0,10.4,4.7,10.4,10.4C49.9,45.6,45.2,50.2,39.5,50.2 M59.9,23.1c0-2.1-1.7-3.7-3.7-3.7 c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7C58.2,26.9,59.9,25.2,59.9,23.1"/>
                    </g>
                </svg>
            </div>
        </a>

    )

}

export default Instagram;
