import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Root from './Root';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'bootstrap-4-grid/css/grid.min.css';
import './Components/Shared/styles/App.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
    <Root>
        <App />
    </Root>,
    document.getElementById('root'));
