import React from 'react';
import { Button } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from '../../actions';

import { AppToaster } from '../Shared/Toaster'

const AddToCartBTN = (props) => {

    const state = useSelector( state => state );
    const dispatch = useDispatch();

    const handleAddToCart = () => {

        dispatch( addToCart(props.product) );

        AppToaster.show({ message: "Agregado al carrito de compras.", className: state.isDark ? "bp3-dark" : "" });
    }

    const cartIds = state.cart.cart.map( e => e.product.id)

    return (
        <div className="add-to-cart">

            {
                ( cartIds.some( e => e === props.product.id ) ) ? (

                    <Button large={true} disabled>Agregado al Carrito</Button>

                ) : ( props.size.label === "Selecciona tu talla" ) ? (

                        <Button large={true} disabled>Selecciona tu talla</Button>

                    ) : (

                        <Button large={true} onClick={handleAddToCart}>Agregar al Carrito</Button>
                        
                    )

            }

        </div>

    )

}

export default AddToCartBTN;
