import { PRODUCT_FILTER } from '../actions/types';

export function fetchProductFilter( state='all', action ) {

    switch( action.type ){

        case PRODUCT_FILTER:
            return action.payload;

        default:
            return state;

    }

}
