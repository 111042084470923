import React from 'react';
import { Link } from 'react-router-dom';
import Whatsapp from './Whatsapp';
import Instagram from './Instagram'

import './styles.scss'

const Footer = () => {

    const getYear = new Date().getFullYear()

    const getData = () => {

        return (
            <footer className="footer" >

                    <div className="row">
                        <div className="col-md-10">
                            <p>&copy; { getYear } <strong>SUPERSTICIÓN.MX</strong>. Todos los derechos reservados.</p>
                            <p><Link to={ "/terminos-y-condiciones" }>Términos y Condiciones</Link> - <Link to={ "/aviso-de-privacidad" }>Aviso de privacidad</Link>. v1.3.6</p>
                        </div>
                        <div className="col-md-2 text-right footer-redes">
                            <div className="footer-redes__container">
                                <Instagram /> <Whatsapp />
                            </div>
                        </div>
                    </div>

            </footer>
        )
    }

    return getData();
}

export default Footer;
