import { FETCH_COUPONS_REQUEST, FETCH_COUPONS_SUCCESS, FETCH_COUPONS_FAILURE } from '../actions/types';

const initialState = {
    loading: false,
    coupons: [],
    error: '',
    success: false
}

export function fetchCoupons( state=initialState, action ){
    switch( action.type ){
        case FETCH_COUPONS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCH_COUPONS_SUCCESS:
            console.log("FETCH_COUPONS_SUCCESS")
            return {
                loading: false,
                coupons: action.payload,
                error: '',
                success: true
            }

        case FETCH_COUPONS_FAILURE:
            return  {
                loading: false,
                coupons:[],
                error: action.payload,
                success: false,
            }

        default:
            return state;
    }
}
