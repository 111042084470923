import React from 'react';
import { H1, H4 } from "@blueprintjs/core";

const TerminosYCondiciones = () => {

    const getData = () => {

        const moreMargin = {
            marginTop: '2rem'
        }

        return (
            <div>
                <H1 className="text-center" style={{ margin:'3rem auto' }}>Términos y Condiciones</H1>
                <p>El acceso y uso del sitio web www.supersticion.mx (en adelante "Web") atribuye la condición de usuario al visitante de la Web e implica la total aceptación sin reservas por parte del usuario de las condiciones de uso vigentes en cada momento.</p>
                <p>El presente documento constituye un contrato de adhesión para el uso del sitio web Superstición.mx (en adelante “Sitio Web” y/o “Página Web”) que celebran: por una parte, SUPERSTICIÓN MX S.A. de C.V. (En adelante “Superstición.mx” y/o “la Empresa”), en su calidad de responsable y por la otra, el Usuario, sujetándose, ambas partes, a lo establecido en este documento.</p>

                <H4 style={ moreMargin } >Aceptación de los términos y condiciones</H4>
                <p>Al ingresar y utilizar este Sitio Web, identificado con el nombre de dominio supersticion.mx, propiedad de Superstición.mx, el Usuario está aceptando los Términos y Condiciones de Uso contenidos en este contrato y declara expresamente su aceptación utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 y demás relativos del Código Civil Federal.</p>
                <p>Para los efectos del presente contrato, las partes acuerdan que por “Usuario” se entenderá a cualquier persona de cualquier naturaleza que ingrese al sitio web www.supersticion.mx y/o a cualquiera de las subpáginas que desplieguen su contenido y/o a la persona de cualquier naturaleza que se dé de alta y/o use cualquiera de los servicios que se ofrecen a través de dicha página.</p>
                <p>En caso de no aceptar en forma absoluta y completa los Términos y Condiciones de este contrato, el Usuario deberá abstenerse de acceder, utilizar y observar el Sitio Web y/o cualquier otro servicio que ofrezca Superstición.mx, así como de visitar cualquiera de las ubicaciones físicas y/o la tienda en línea Superstición.mx.</p>
                <p>En caso de que el Usuario acceda, utilice y observe el Sitio, así como en caso de que el Usuario acceda, visite, utilice y recorra la Web, se considerará como una absoluta y expresa aceptación de los Términos y Condiciones de Uso aquí estipulados, los demás documentos incorporados a los mismos por referencia, así como a las leyes y reglamento aplicables de conformidad a la legislación vigente para el uso del Sitio Web.</p>
                <p>Superstición.mx no guardará una copia individualizada del presente convenio celebrado entre el Usuario y la Empresa, por lo que se le recomienda al Usuario que guarde una copia de los presentes Términos y Condiciones de Uso para su propio expediente.</p>
                <p>En caso de que el Usuario viole lo expresado en estos Términos y Condiciones de Uso, Superstición.mx podrá cancelar su uso, así como excluir al Usuario de futuras operaciones, y/o tomar la acción legal que juzgue conveniente para sus interese</p>

                <H4 style={ moreMargin } >Uso del Sitio www.supersticion.mx</H4>
                <p>El Usuario y Superstición.mx están de acuerdo en que:</p>
                <ul>
                    <li>Para poder utilizar la Página Web el Usuario debe de tener por lo menos 18 años de edad o estar accediendo bajo la supervisión de un padre o tutor legal.</li>
                    <li>mx concede una licencia no transferible y revocable para utilizar el Sitio Web, en virtud de los Términos y Condiciones de Uso descritos, con el propósito de la compra de artículos personales vendidos en la misma Página. El Usuario sólo podrá imprimir y/o copiar cualquier información y/o imagen contenida o publicada en el sitio web Superstición.mx exclusivamente para uso personal, por lo que queda expresa y terminantemente prohibido el uso comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor. La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, imagen, documento o gráfico que aparezca en el sitio web Superstición.mx, para cualquier uso distinto al personal no comercial le está expresamente prohibido al Usuario, a menos de que cuente con la autorización previa y por escrito de Superstición.mx. Cualquier infracción de estos Términos y Condiciones de Uso dará lugar a la revocación inmediata de la licencia otorgada en este apartado, sin previo aviso.</li>
                    <li>Ciertos servicios y las características relacionadas que pueden estar disponibles en Superstición.mx pueden requerir el registro o suscripción. El Usuario reconoce que, al proporcionar la información de carácter personal, otorga a Superstición.mx la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor. Si el Usuario decide registrarse o suscribirse a cualquiera de estos servicios o funciones relacionadas, el mismo se compromete a proporcionar información precisa y actualizada acerca de si mismo, y a actualizar rápidamente esa información si hay algún cambio.</li>
                    <li>Cada Usuario del sitio es el único responsable de mantener las contraseñas y otros identificadores de cuentas seguras. El titular de la cuenta es totalmente responsable de todas las actividades que ocurran bajo su contraseña o cuenta. Por otra parte, el Usuario debe notificar la Empresa de cualquier uso no autorizado de su contraseña o cuenta. De ninguna manera, Superstición.mx será responsable, directo o indirectamente, por cualquier pérdida o daño de cualquier tipo incurridos como resultado de la falta de cumplimiento con esta sección por parte del Usuario.</li>
                    <li>Durante el proceso de registro, el Usuario acepta recibir correos electrónicos promocionales de Superstición.mx No obstante, posteriormente, puede optar por no recibir tales correos promocionales haciendo clic en el enlace en la parte inferior de cualquier correo electrónico promocional.</li>
                    <li>mx se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar: i) abusivo, difamatorio u obsceno; ii) fraudulento, artificioso o engañoso; iii) violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero; iv) ofensivo o; v) que de cualquier forma contravenga lo establecido en este contrato.</li>
                    <li>mx no presume que el contenido de su Página pueda ser legalmente visto fuera de los Estados Unidos Mexicanos. El acceso al contenido puede no ser legal para ciertas personas o en ciertos países. Si el Usuario tiene acceso al contenido desde fuera de los Estados Unidos Mexicanos, lo hace bajo su propio riesgo y es responsable por el cumplimiento de las leyes dentro de la jurisdicción en la que se encuentra el Usuario.</li>
                    <li>Se prohíbe a los Usuarios el violar o intentar violar la seguridad del Sitio Web y de los Sitios Web afiliados de Superstición.mx; quedan prohibidas al Usuario: (a) acceder a datos a los cuales el Usuario no se encuentra autorizado para utilizar o iniciar sesión en un servidor o en una cuenta para la que el Usuario no tiene acceso autorizado; (b) intentar examinar, escanear o probar la vulnerabilidad de un sistema de informática o de una red o quebrantar las medidas de seguridad o autenticación sin la debida autorización; (c) intentar interferir con el uso de cualquier otro Usuario, servicio de hospedaje o red, incluyendo, sin limitación, el transmitir un virus al Sitio Web o a los sitios web afiliados de Superstición.mx; causar una saturación de dichos sitios mediante “inundación” (flooding), “envío de correo no deseado” (spamming), “bombardeo de correo” (mailbombing) o “generación de fallas” (crashing); (d) el envío de correos electrónicos no solicitados, incluyendo promociones y/o publicidad de productos o servicios, o; (e) falsificar cualquier encabezado de paquete TCP/IP o cualquier parte de la información del encabezado en cualquier correo electrónico o publicación en grupo de noticias.</li>
                    <li>El Usuario reconoce que las violaciones del sistema de informática o de la seguridad de la red pueden generar responsabilidades civiles o penales. Superstición.mx investigará situaciones que puedan involucrar dichas violaciones y se reserva el derecho de denunciar tales acciones a las autoridades; la Empresa cooperará con la autoridad competente en la investigación de dichas violaciones en los términos establecidos en la legislación aplicable.</li>
                    <li>Para el debido ingreso al Sitio, los Usuarios deberán contar con equipos e instalaciones necesarias para su conexión a Internet (computadora, teléfono, modem, programas, etc.), siendo el uso de estos equipos total responsabilidad de los mismos.</li>
                    <li>mx está exento de cualquier responsabilidad que ocurra por interrupciones o suspensiones del servicio de acceso a Internet ocasionadas por la falla en el sistema de telecomunicaciones, en el suministro de energía eléctrica, casos fortuitos o de fuerza mayor o una acción de terceros que puedan inhabilitar los equipos que suministran el acceso a la red.</li>
                    <li>Por lo anterior, Superstición.mx no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. La Empresa tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen del Sitio o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago de daños o perjuicios, en virtud de dificultades técnicas o fallas en los sistemas o en Internet. Superstición.mx no garantiza el acceso y uso continuado o ininterrumpido del Sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a la Empresa; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. Superstición.mx no será responsable por ningún error u omisión contenidos en el Sitio.</li>
                </ul>

                <H4 style={ moreMargin } >Propiedad intelectual e industrial, y derechos de autor</H4>
                <p>Superstición.mx reconoce ser la única propietaria de los derechos de propiedad intelectual, ya sean registrados o no registrados, en el sitio www.supersticion.mx, incluyendo pero no limitado a: proyectos, software, código fuente, gráficos, fotografías, videos, imágenes, músicas, sonido, textos, logos, marcas, nombres de dominio, nombres comerciales y datos incluidos en la Página Web www.supersticion.mx. La totalidad del contenido de nuestra página también está protegido por derechos de autor como un trabajo colectivo bajo las leyes de derechos de autor en México y las convenciones internacionales. Todos los derechos reservados.</p>
                <p>A los Usuarios se les advierte que tales derechos están protegidos por la legislación vigente Mexicana e internacional relativa a la propiedad intelectual e industrial y a los derechos de autor.</p>
                <p>Está prohibida la copia, reproducción, adaptación, modificación, distribución, comercialización, licencia, envío, divulgación, comunicación pública y/o cualquier otra acción que genera una infracción de la legislación Mexicana o internacional vigente en materia de propiedad intelectual y/o industrial, así como el uso de los contenidos del Sitio sin previa autorización expresa y por escrito de Superstición.mx.</p>
                <p>En caso de que el Usuario transmita a Superstición.mx cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del Sitio Web Superstición.mx, el Usuario otorga en este acto a Superstición.mx una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.</p>
                <p>Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que el Usuario envíe o transmita a Superstición.mx, incluyendo, sin limitación alguna, preguntas, críticas, comentarios y sugerencias para renovar o mejorar el Sitio Web, ya sea que éstas hayan sido incluidas en cualquier espacio de la página señalada o en virtud de otros medios o modos de transmisión conocidos o que sean desarrollados en el futuro. Además, cuando el Usuario remite comentarios o críticas a la web, también concede a Superstición.mx el derecho a utilizar el nombre que el Usuario envíe, en el marco de dicha revisión, comentario, o cualquier otro contenido.</p>
                <p>Por lo anterior, el Usuario renuncia expresamente en este acto a llevar a cabo cualquier acción, demanda o reclamación en contra de Superstición.mx, sus afiliados o proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que el propio Usuario envíe al sitio web Superstición.mx </p>
                <p>En caso de considerar que cualquier contenido publicado en el Sitio Web es violatorio de derechos de propiedad intelectual o industrial, el Usuario podrá realizar una notificación contactando el centro de Atención al Cliente de Superstición.mx. El Usuario tendrá que indicar: i) datos personales verídicos (nombre, dirección, número de teléfono y dirección de correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido(s) protegido(s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en el sitio web referido; iv) declaración expresa y clara de que la introducción del (los) contenido(s) indicado(s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; v) declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del(los) contenido(s) constituye una violación de dichos derechos.</p>
                <p>El Sitio Web contiene vínculos a sitios web de terceros. Estos vínculos se proporcionan tan sólo como una ventaja del Sitio Web para el Usuario y no implican que Superstición.mx ha aprobado el contenido de dichos sitios web de terceros. Superstición.mx no es responsable por el contenido de sitios web vinculados de terceros y no hace ninguna declaración con respecto al contenido o la veracidad del material en los sitios de dichos terceros. Si el Usuario decide tener acceso a los sitios web de terceros por medio de estos vínculos, lo hace bajo su propia responsabilidad y riesgo.</p>

                <H4 style={ moreMargin } >Material publicitario</H4>
                <p>El Usuario reconoce y acepta que algunas partes del Sitio Web pueden contener información, imágenes, anuncios y demás material publicitario o promocional de terceros patrocinadores y anunciantes (en lo subsecuente “Material Publicitario”). Los publicistas y patrocinadores son responsables de asegurar que el Material Publicitario sometido para su inclusión en el Sitio Web cumpla las leyes y los códigos reguladores pertinentes. Superstición.mx no es responsable de ningún error o inexactitud en los materiales de publicidad.</p>
                <p>Asimismo, el Usuario reconoce y acepta en este acto que dicho Material Publicitario se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.</p>

                <H4 style={ moreMargin } >Garantía de los productos adquiridos</H4>
                <p>Superstición.mx actúa en calidad de distribuidor de fabricantes o distribuidores mayoristas que garantizan que los productos que se comercializan en el sitio web www.supersticion.mx funcionan correctamente y no presentan defectos ni vicios ocultos que puedan hacerlos peligrosos o inadecuados para un uso normal. No obstante, lo anterior, el uso que cada Usuario dé a los productos es de su exclusiva responsabilidad, sin responsabilidad alguna de Superstición.mx.</p>
                <p>La duración de la garantía contractual es de 15 días naturales por defectos de fabricación de producto.</p>
                <p>Ningún Usuario podrá solicitar una garantía más amplia de la que ahí se indique. En tales casos, Superstición.mx no estará obligada a responder por dichas garantías, ni a recolectar el o los productos averiados, en el entendido de que esta garantía única y exclusivamente aplicará dentro de nuestras áreas de cobertura de entrega actuales. Así mismo, la recolección de el o los productos averiados se realizará única y exclusivamente en la ubicación donde fueron entregados originalmente.</p>
                <p>La garantía perderá su vigencia en caso de defectos o deterioros causados por factores externos, accidentes, en especial, desgaste, uso inadecuado, o instalación y utilización no conforme a las instrucciones. Superstición.mx tendrá el derecho a realizar una verificación y análisis, ya sea por parte del área correspondiente de Superstición.mx o de terceros asignados por Superstición.mx para dicho fin, incluyendo y no limitado a la inspección visual, física y/o técnica de el o los productos averiados para validar la vigencia y en dado caso su aplicación de acuerdo a los factores antes mencionados. La descripción de los acontecimientos por parte del cliente no tendrá carácter vinculante hacia Superstición.mx para validar la aplicación de la garantía o los términos de devolución.</p>
                <p>Cualquier reclamación de garantía de los productos que hayan sido enviados fuera de nuestra área de cobertura por parte del cliente, queda a consideración del área de devoluciones. El cliente aceptará los términos y condiciones de entrega y recolección que en el apartado de Devoluciones se estipulen.</p>
                <p>Quedan excluidos de la garantía los productos transportados, modificados o reparados por el Usuario o cualquier otra persona no autorizada por Superstición.mx. La garantía no será aplicable a los vicios aparentes y los defectos de conformidad del producto, para los que cualquier reclamación deberá ser formulada por el Usuario en cuestión dentro de los 7 (s) días naturales siguientes a la entrega de los productos.</p>

                <H4 style={ moreMargin } >Información sobre productos</H4>
                <p>La información dada sobre cada producto en el sitio, en los productos exhibidos físicamente en la Tienda, así como las fotografías o vídeos relativos a los mismos y los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en el Sitio de Superstición.mx, son expuestos a modo exclusivamente orientativo. Superstición.mx no es responsable de ningún error o inexactitud en la información sobre producto.</p>

                <H4 style={ moreMargin } >Créditos y promociones</H4>
                <p>Superstición.mx informará a los Usuarios suscritos al newsletter, vía correo electrónico, todas las promociones y oportunidades futuras, con las respectivas fechas y condiciones.</p>
                <p>Las promociones tendrán términos y condiciones específicos, y los Usuarios interesados en participar serán responsables por leer y entender estos términos y condiciones.</p>
                <p>Se entiende por cupón un código generado y proporcionado por Superstición.mx el cual da derecho a el Usuario a disfrutar de un descuento económico en alguna compra realizada dentro de la Página Web de acuerdo con los términos y condiciones estipulados previamente por Superstición.mx su uso y aplicación. Los cupones de Superstición.mx no son acumulables con otros cupones de la misma tienda.</p>
                <p>Los créditos generados por las acciones promocionales de Superstición.mx (cupones, etc.) podrán ser intercambiados exclusivamente por productos físicos comercializados en la Página Web www.supersticion.mx Los créditos no podrán ser intercambiados por otros créditos ni por dinero en efectivo.</p>
                <p>Superstición.mx se reserva el derecho a suspender, anular y cancelar cualquier compra o pedido realizado por el Usuario en la Página Web en los cuales sea utilizado cualquier tipo de cupón cuyo uso no se apegue a las reglas antes mencionadas, sea usado con dolo, fuera de la vigencia ó términos y condiciones estipulados o de manera indebida por el Usuario.</p>

                <H4 style={ moreMargin } >Compra de los productos</H4>
                <p>Para poder realizar la compra de los productos el Usuario deberá realizar el pago de los productos seleccionados, impuestos y gastos de envío correspondientes a través de los proveedores de servicios de pagos que Superstición.mx ponga a disposición del Usuario en el Sitio Web. El Usuario solamente podrá comprar productos a través del Sitio Web para ser entregados en un domicilio dentro del territorio de los Estados Unidos Mexicanos dentro de las áreas de cobertura de entrega vigentes al momento de la compra.</p>
                <p>Realizada la compra por el Usuario, mediante la aceptación implícita de los Términos y Condiciones de Uso, Superstición.mx enviará un e-mail al Usuario informando los detalles de la compra realizada.</p>

                <H4 style={ moreMargin } >Pago</H4>
                <p>El pago de los productos adquiridos en el Sitio podrá realizarse por medio de cualquier de los medios de pago ofrecidos por el Sitio, siendo estos, entre otros: tarjeta de crédito, tarjeta de débito, transferencia bancaria, pago en OXXO o Paypal. La lista de los medios de pago ofrecidos puede estar sujeta a modificaciones en cualquier momento sin previo aviso para los Usuarios.</p>
                <p>El pago por medio de tarjeta de crédito o cualquier otro medio de pago online deberá de realizarse en el Sitio.</p>
                <p>El número de orden que se asigna al realizar la transacción en el Sitio es únicamente de carácter informativo y no implica la aceptación de la transacción por parte de Superstición.mx. En caso de tener algún problema con su orden, el Usuario será comunicado por correo electrónico o vía telefónica.</p>
                <p>Superstición.mx enviará la confirmación de compra vía correo electrónico. Solo después de la confirmación del pago se liberarán los productos para entrega en la dirección de entrega indicada por el Usuario.</p>
                <p>Los pagos realizados en el Sitio por medio de tarjeta de crédito, débito o Paypal están sujetos a análisis y aprobación por parte de Superstición.mx. Superstición.mx se reserva el derecho de solicitar documentos oficiales al Usuario, como medio de validación al proceso de adquisición de productos a través del Sitio, para validar la titularidad y correcto uso del medio de pago, y en dado caso, a cancelar el pago realizado según su exclusivo criterio.</p>
                <p>En caso de desconocimiento por parte de la Institución Bancaria correspondiente a los cargos efectuados por el Usuario que corresponda a través de tarjeta de crédito y derivados de operaciones realizadas en el Sitio, Superstición.mx se reserva el derecho de iniciar las acciones legales que correspondan y fincar las responsabilidades penales o civiles según sea el caso o de cualquier otra naturaleza, así como de realizar todas aquellas acciones internas que podrán ir desde hacer el cargo nuevamente a la tarjeta de crédito de dicho Usuario hasta la baja definitiva del Usuario en el Sitio, para lo cual no se necesitará autorización previa del Usuario.</p>

                <H4 style={ moreMargin } >Orden de aceptación y precios</H4>
                <p>Todos los precios de los productos que se indican a través del Sitio Web incluyen el IVA y los demás impuestos que pudiera corresponder a éstos. No obstante, estos precios no incluyen los gastos correspondientes al envío de los productos, los cuales se detallarán aparte en cada pedido y deberán ser aceptados y pagados, previamente a su envío, directa y exclusivamente por el Usuario.</p>
                <p>Precios, promociones y disponibilidad, sujetos a cambios sin previo aviso.</p>
                <p>El Usuario debe de considerar que se dan caso en los cuales una orden no puede ser procesada por diversos motivos. En ese sentido, Superstición.mx se reserva el derecho a denegar o cancelar cualquier pedido por cualquier razón, en cualquier momento. Además, Superstición.mx se reserva el derecho a solicitar al Usuario información adicional, antes de aceptar el pedido o inclusive una vez aceptado en primer instancia.</p>
                <p>Superstición.mx proporcionará la información de precios más precisa para los Usuarios, sin embargo, aún pueden producirse ciertos errores, como los casos en que el precio de un artículo no se muestra correctamente en la Página Web y/o los casos en que el precio de un artículo exhibido no se muestra correctamente en la etiqueta o no corresponda a la promoción vigente. Como tal, la Empresa se reserva el derecho a denegar o cancelar cualquier orden que contenga un artículo que haya sido comprado con un precio incorrecto. En el caso de que el precio de un artículo sea incorrecto, es posible que a discreción Superstición.mx se ponga en contacto con el Usuario para solicitar instrucciones o cancelar el pedido y le notificará de tal cancelación, así como de denegar la venta en la Tienda con el precio exhibido y hacer la debida corrección, o en caso de que la compra se haya efectuado, de cancelar tales pedidos, ya sea o no que el pedido haya sido confirmado y pago.</p>
                <p>Si por alguna razón, el precio se encuentra en $0.00 o $0.01, favor de entrar en contacto con la central de servicio al cliente de Superstición.mx. Por ninguna razón, se entenderá que éstos no tengan precio o se regalen y los pedidos que se realicen bajo esta situación, serán cancelados sin previo aviso.</p>

                <H4 style={ moreMargin } >Disponibilidad de productos</H4>
                <p>Todos los productos que se ofrecen en el Sitio están sujetos a existencias y disponibilidad, por lo que el tiempo de entrega puede variar previo aviso de nuestra parte, o incluso podrá proceder la cancelación de la orden y la devolución de los cargos del Usuario en su caso.</p>
                <p>Si el tiempo de entrega ofrecido no es de entera satisfacción del Usuario, en cualquier momento el Usuario podrá solicitar la cancelación de la orden.</p>

                <H4 style={ moreMargin } >Gastos de envío y entrega de los productos</H4>
                <p>Superstición.mx enviará los productos adquiridos por el Usuario al domicilio especificado por el Usuario para este efecto siempre y cuando dicho domicilio se encuentre dentro del área de cobertura comunicada por Superstición.mx.</p>
                <p>El tiempo de entrega inicia a partir de la confirmación del pago por parte del banco, plataforma de pagos en línea, o departamento de Crédito de Superstición.mx.</p>
                <p>Los productos serán enviados y entregados al Usuario por terceros (en lo sucesivo “Empresas Transportadoras”). En virtud de lo anterior, el Usuario acepta someterse para el envío y entrega de los productos a las políticas de entrega de las Empresas Transportadoras mismas que se incorporan por referencia a los presentes Términos y Condiciones de Uso.</p>
                <p>El plazo descrito en el Sitio es una estimación. De esta forma, es posible que tal plazo cambie por razones de logística y de fuerza mayor. En la hipótesis de que por cualquier evento fortuito o de fuerza mayor se pueda retrasar la entrega de los productos adquiridos en el Sitio, Superstición.mx estará en todo momento exento de cualquier responsabilidad.</p>
                <p>En caso de que el Usuario no haya recibido los productos en el plazo declarado por Superstición.mx deberá ponerse en contacto con la Empresa a través de la central de atención al cliente para conocer el estado de su pedido.</p>
                <p>El costo del flete, cuando aplicable, se sumará al valor de la mercancía adquirida y será calculado e informado al Usuario antes de la confirmación de la compra. El costo del flete podrá depender de factores como el peso total de la mercancía, la distancia y el tipo de entrega, y podrá ser modificado por Superstición.mx n cualquier momento sin previa autorización del Usuario.</p>
                <p>Al momento de la entrega, será solicitada a la persona que reciba la mercancía una identificación oficial (credencial de elector, pasaporte, forma migratoria FM2 o licencia de conducir), por lo que se requiere tener este documento a la mano en la entrega de la compra.</p>
                <p>Superstición.mx sugiere que el Usuario verifique la integridad y calidad de los productos recibidos en el momento de la entrega. Si el Usuario no está satisfecho con los productos, se pide que no reciba la entrega por parte de la Empresa Transportadora y que se comunique inmediatamente con el centro de atención al cliente de Superstición.mx.</p>

                <H4 style={ moreMargin } >Cancelación de la compra de los productos</H4>
                <p>El Usuario podrá cancelar la compra de Productos realizada a través del Sitio Web, siempre y cuando, los Productos adquiridos no hayan salido de las instalaciones de Superstición.mx. En caso de que los Productos ya hayan sido enviados por Superstición.mx  el Usuario no podrá cancelar la compra realizada, y tendrá que seguir el procedimiento presentado en el apartado “Devoluciones” de los presentes Términos y Condiciones. El Usuario podrá consultar el estado de la compra realizada a través de la sección de Mis Pedidos de la Cuenta del Usuario o entrando en contacto con la central de atención al cliente de Superstición.mx. En caso de que los Productos adquiridos por el Usuario no hayan sido enviados la opción de cancelación estará disponible. En caso de cancelación de la compra realizada por el Usuario la cantidad pagada por el Usuario será reembolsada de conformidad con el apartado “Reembolsos” de los presente Términos y Condiciones.</p>

                <H4 style={ moreMargin } >Devoluciones</H4>
                <p>Condiciones de Devolución:</p>
                <p>El Usuario podrá devolver los productos adquiridos a través del Sitio Web, siempre y cuando:</p>
                <ol>
                    <li>El Usuario deberá contactar al centro de atención al cliente de Superstición.mx dentro del plazo establecido solicitando la devolución de los productos y proporcionando, nombre y apellido, número de pedido, nombres de productos a ser devueltos, domicilio en el cual se recolectarán el o los productos. En caso de que la devolución se debe a que el o los productos son defectuosos o se trata de un producto diferente al adquirido a través del Sitio Web, el Usuario deberá también expresar dicha circunstancia, así como mencionar, en su caso, los defectos de los productos, (en los sucesivo la “Solicitud de Devolución”).</li>
                    <li>Una vez aprobada la Solicitud de Devolución, Superstición.mx podrá enviar al Usuario una guía para el envío a través de la Empresa Transportadora correspondiente, así como programar la fecha en la que la Empresa Transportadora recogerá los productos del domicilio proporcionado por el Usuario.</li>
                    <li>El Usuario deberá en su caso, empaquetar el producto en su caja o empaque original, imprimir y entregar la guía, así como los Productos en su empaque o caja original a la Empresa Transportadora en la fecha programada para tal efecto.</li>
                </ol>
                <p>Proceso de Devolución:</p>

                <H4 style={ moreMargin } >En devoluciones con recolectas de productos enviados por el cliente fuera de nuestra cobertura:</H4>
                <ol>
                    <li>el cliente tendrá que revisar el producto previo al envío al destino que el cliente requiera y realizar la reclamación inmediatamente en el caso de que este tenga algún defecto o daño o bien por insatisfacción del mismo.</li>
                    <li>Cualquier recolecta fuera de la cobertura de entrega se tendrá que realizar en el domicilio donde se entregó en primera instancia el producto. En el entendido que, en el caso que aplique la devolución del producto el cliente tendrá que enviarlo al destino original de entrega para poder realizar la recolecta del mismo. Cualquier daño o afectación al producto adicional que suceda durante esta transportación quedará fuera de la cobertura de garantía.</li>
                    <li>La aplicación de la garantía y los términos de devolución queda a consideración del área correspondiente. En el entendido de que se tendrá que revisar el motivo de la devolución y el cliente aceptará las condiciones que en este apartado se estipulan.</li>
                </ol>

                <H4 style={ moreMargin } >Reembolsos</H4>
                <p>Si el pago fue efectuado mediante tarjeta de débito o crédito, el reembolso se realizará a través de una devolución del cargo en la misma tarjeta que fue utilizada siempre y cuando no hayan transcurrido 30 días naturales posteriores al cargo. Si el pago fue efectuado por otros medios o ya superó los 30 días naturales posteriores al cargo, el reembolso se realizará a través de una transferencia bancaria en la cuenta del Usuario.</p>
                <p>Se le reembolsará al Usuario el costo pagado por los productos.</p>

                <p>Superstición.mx no se hace y ni se hará responsable de los gastos ocasionados por aquellas devoluciones que se produzcan sin respetar los pasos establecidos en el procedimiento presentado en el apartado “Devoluciones”.</p>
                <p>Plazo para reembolsos en caso de cancelación</p>
                <p>En caso de cancelación de la compra realizada por el Usuario de acuerdo con el apartado “Cancelación de la compra de los productos” de los presentes Términos y Condiciones, Superstición.mx deberá solicitar el reembolso al Usuario por la totalidad de la cantidad pagada por el Usuario dentro de los 7 días naturales siguientes contados a partir de la fecha de la cancelación de la compra del Usuario.</p>
                <p>Plazo para reembolsos en caso de devolución</p>
                <p>Una vez recibidos los productos devueltos por el Usuario, Superstición.mx revisará a detalle los productos devueltos.</p>
                <p>En caso de que los productos recibidos por Superstición.mx cumplan con las características establecidas en punto 2 de la sección “Condiciones de Devolución”, la Empresa tendrá la obligación de devolver el dinero pagado por el Usuario dentro de los 7 días naturales contados a partir del día en que Superstición.mx recibió los productos devueltos.</p>

                <H4 style={ moreMargin } >Responsabilidad del Usuario en relación a las transacciones</H4>
                <p>El Usuario asume la responsabilidad de todos los costes, tasas, impuestos y demandas que se derivarán del uso de este Sitio web. Los datos de acceso comunicados al Usuario para su perfil han sido concebidos exclusivamente para uso personal, y deberán tratarse con confidencialidad. El Usuario deberá modificar sus contraseñas con regularidad. Todas las transacciones realizadas mediante la cuenta de perfil serán imputadas al titular de la cuenta de perfil pertinente, y tendrán carácter vinculante.</p>
                <p>El Usuario se responsabiliza sin limitaciones de los daños directos e indirectos, así como los daños consecuentes, que pudiera ocasionar por negligencia grave o intención ilegal.</p>

                <H4 style={ moreMargin } >Cuenta del Usuario</H4>
                <p>El Usuario podrá contar con una cuenta personal (en lo sucesivo “Cuenta del Usuario”) ingresando los datos solicitados en el Sitio Web. La Cuenta del Usuario es personal, única e intransferible. A través de la Cuenta del Usuario el Usuario podrá acceder a su historial de compras y pedidos realizados, verificar el estado de sus pedidos, acceder a su carrito de compra, tener acceso a promociones y descuentos que de tiempo en tiempo informe Superstición.mx podrá personalizar el contenido que se le presente del Sitio Web de acuerdo con sus preferencias, así como utilizar cualquier tipo de funcionalidades que Superstición.mx pueda aplicar en el futuro a la Cuenta del Usuario.</p>
                <p>El Usuario solo podrá contar con una Cuenta del Usuario. En caso de que Superstición.mx detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelarlas, suspenderlas o inhabilitarlas, a su sola discreción y en cualquier momento. El Usuario será responsable por todas las operaciones efectuadas desde su Cuenta del Usuario. Está prohibida la venta, cesión, transferencia o transmisión de la Cuenta del Usuario bajo cualquier título, ya sea oneroso o gratuito.</p>
                <p>Superstición.mx se reserva el derecho rechazar el registro de una Cuenta del Usuario en caso de considerar que el Usuario no ha dado cumplimiento a la los presentes Términos y Condiciones, sin que la Empresa esté obligada a comunicar o exponer las razones de su decisión y sin que se genere derecho a indemnización o resarcimiento alguno a favor del Usuario.</p>

                <H4 style={ moreMargin } >Restricciones para menores</H4>
                <p>Superstición.mx no realiza ventas de forma intencional a menores de edad, por lo que se recomienda a los padres y tutores lleven a cabo las actividades de compra de productos o registro en el Sitio Web ellos mismos.</p>
                <p>Los padres o tutores de menores de edad serán responsables por los actos por ellos realizados según lo dispuesto por estos Términos y Condiciones de Uso, incluyendo los daños causados a terceros, acciones realizadas por ellos y que estén prohibidas por ley y por las disposiciones de este acuerdo, sin perjuicio de la responsabilidad del Usuario, siempre que éste no fuese padre o representante legal del menor infractor.</p>

                <H4 style={ moreMargin } >Responsabilidad</H4>
                <p>El Usuario en este acto se obliga a indemnizar y sacar en paz y a salvo a Superstición.mx y a sus subsidiarias, controladora, accionistas, directivos, empleados, funcionarios, consejeros y agentes frente a cualesquier acciones, procedimientos, responsabilidades, demandas, reclamaciones, pérdidas, responsabilidades, perjuicios, costos, daños, gastos y costas, así como los gastos, costos y honorarios de abogados y asesores externos que se deriven o se encuentren relacionados con la violación por parte del Usuario de: (i) los presentes Términos y Condiciones, y/o (ii) cualesquiera leyes, normas, decretos o regulaciones vigentes.</p>
                <p>Superstición.mx se reserva el derecho de asumir la defensa y el control de cualquier asunto o reclamo que implique o pudiera implicar el pago de una indemnización asociada con algún incumplimiento del Usuario. El Usuario se compromete a cooperar con Superstición.mx en el desarrollo de las defensas pertinentes.</p>

                <H4 style={ moreMargin } >Modificaciones al Sitio www.supersticion.mx</H4>
                <p>Superstición.mx podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al Usuario, realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y demás elementos de dicho sitio, sin que ello dé lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique reconocimiento de responsabilidad alguna a favor del Usuario.</p>

                <H4 style={ moreMargin } >Vigencia, terminación y modificación de los Términos y Condiciones de Uso</H4>
                <p>La Empresa, así como el Usuario, reconocen que los Términos y Condiciones son de vigencia ilimitada, y entrarán en vigor a partir de su publicación en el Sitio.</p>
                <p>Superstición.mx se reserva el derecho de efectuar alteraciones al presente documento sin necesidad de previo aviso. Por lo anterior Superstición.mx recomienda al Usuario que vuelva a leer con regularidad este documento, de forma que se mantenga siempre informado sobre eventuales modificaciones. Las alteraciones al contrato se volverán efectivas inmediatamente después de su publicación en el Sitio. Una vez realizadas las modificaciones, se presumirá que el Usuario que continúe usando el Sitio tendrá pleno conocimiento, habrá leído y consentido los Términos y Condiciones reformados. En caso de que el Usuario no acepte los términos y condiciones modificados deberá dejar de utilizar el Sitio Web.</p>
                <p>Superstición.mx podrá en cualquier momento suspender el acceso al Sitio Web y/o terminar los presentes Términos y Condiciones. La terminación de los presentes Términos y Condiciones no implicará en ningún caso para Superstición.mx que debe de indemnizar al Usuario.</p>

                <H4 style={ moreMargin } >Subsistencia</H4>
                <p>Estos Términos y Condiciones de Uso, así como los términos adicionales, constituyen el acuerdo íntegro entre las partes. Cualquier cláusula o provisión del presente contrato, así como de los términos adicionales, legalmente declarada inválida, será eliminada o modificada a elección de Superstición.mx, con la finalidad de corregir su vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.</p>

                <H4 style={ moreMargin } >Términos adicionales</H4>
                <p>Ocasionalmente, Superstición.mx podrá revisar, actualizar y/o agregar a los Términos y Condiciones de Uso del presente contrato provisiones adicionales relativas a áreas específicas o nuevos servicios que se proporcionen en o a través del Sitio Web Superstición.mx, los cuales serán publicados en las áreas específicas o nuevos servicios de dicho sitio para su lectura y aceptación. El Usuario reconoce y acepta que dichos términos adicionales forman parte integrante del presente contrato para todos los efectos legales a que haya lugar.</p>

                <H4 style={ moreMargin } >Ley aplicable y jurisdicción</H4>
                <p>Estos Términos y Condiciones de Uso se interpretarán y regirán por la legislación vigente en México, Distrito Federal, renunciando a la aplicación de la Convención sobre la Compraventa Internacional de Mercaderías.</p>

                <H4 style={ moreMargin } >Cesión de derechos</H4>
                <p>Los derechos concedidos al Usuario deben considerarse como derechos personales y el Usuario no podrá ceder o transmitirlos, ni autorizar a cualquier tercero para usarlos de cualquier manera. Superstición.mx podrá ceder total o parcialmente sus derechos y/o obligaciones a su cargo a cualquier tercero, subsidiaria o controladora de Superstición.mx sin autorización previa del Usuario. En virtud de dicha cesión, Superstición.mx quedará liberada de cualquier obligación a favor del Usuario, establecida en el presente contrato.</p>

                <H4 style={ moreMargin } >No renuncia de derechos</H4>
                <p>La inactividad por parte de Superstición.mx, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del presente contrato, en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.</p>

                <H4 style={ moreMargin } >Indemnización</H4>
                <p>El Usuario está de acuerdo en indemnizar a Superstición.mx, sus afiliados, proveedores, vendedores y asesores por cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento por parte del Usuario al presente convenio; incluyendo, sin limitación de alguna de las derivadas de:</p>
                <ul>
                    <li>Cualquier aspecto relativo al uso del sitio web Superstición.mx.</li>
                    <li>La información contenida o disponible en o a través de dicho Sitio o de injurias, difamación o cualquier otra conducta violatoria del presente contrato por parte del Usuario en el uso de la Página Web señalada.</li>
                    <li>La violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual, contenidos o disponibles en, o a través de dicho Sitio Web.</li>
                </ul>

                <H4 style={ moreMargin } >Otros</H4>
                <p>Si cualquier disposición establecida en los presentes Términos y Condiciones es ilegal, nula o de imposible ejecución en alguna jurisdicción, no afectará: (i) la legalidad, validez o ejercicio en dicha jurisdicción de cualquier otra disposición del presente convenio; o (ii) la legalidad, validez o ejercicio en cualquier otra jurisdicción de dicha o cualquier otra disposición del presente convenio.</p>
                <p>Superstición.mx podrá no ejercer alguno de los derechos y facultades conferidos en este documento, lo que no implicará en ningún caso la renuncia a los mismos, salvo reconocimiento expreso por parte de Superstición.mx, o prescripción de la acción que corresponda a cada caso.</p>
                <p>Los encabezados de las cláusulas son incorporados al mismo sólo por conveniencia y para su mejor manejo, por lo que de ninguna manera se considerarán para efectos de su interpretación, ni afectarán las obligaciones en él contenidas.</p>
                <p>Los presentes Términos y Condiciones de Uso y el Aviso de Privacidad, así como cualesquiera modificaciones y/o avisos legales que se publiquen o comuniquen, de tiempo en tiempo, por Superstición.mx a través del Sitio Web, constituyen la totalidad del acuerdo entre el Usuario y Superstición.mx en relación con los Servicios ofrecidos por Superstición.mx a través del Sitio Web.</p>

                <H4 style={ moreMargin } >Fecha de la última actualización</H4>
                <p>Se notifica que los Términos y Condiciones fueran modificados el día 10 de octubre del 2020</p>


            </div>
        )

    }

    return getData()

}

export default TerminosYCondiciones;
