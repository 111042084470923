import React, { useEffect, useState } from 'react';
import { useSelector, connect } from "react-redux";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from "styled-components";
import theme from "styled-theming";
import DarkThemeProvider from "./DarkThemeProvider";
import ScrollToTop from "./ScrollToTop";
import { withRouter } from "react-router";
import { fetchUser } from './actions';
// import { CLEAR_CART } from './actions/types';
import {Dialog} from '@blueprintjs/core';

//Components
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import ProductDetail from './Components/ProductDetail';
import Cart from './Components/Cart/Cart';
import Checkout from './Components/Checkout'
import Register from './Components/Login/Register'
// import Login from './Components/Login'
import Thankyou from './Components/Checkout/Thankyou'
import Category from './Components/Categoria'
import Rebajas from './Components/Rebajas';
import Profile from './Components/Profile'
import TerminosYCondiciones from './Components/Legal/TerminosYCondiciones'
import AvisoDePrivacidad from './Components/Legal/AvisoDePrivacidad'
import ResetPassword from './Components/Login/ResetPassword'


export const backgroundColor = theme("theme", {
    light: "#f5f8fa",
    dark: "#18191a",
});

export const textColor = theme("theme", {
    light: "#000",
    dark: "#fff",
});

const Container = styled.div`
    background-color: ${backgroundColor};
    color: ${textColor};
    min-height: 100vh;
`;


const token = localStorage.getItem( "session" );
const validSession = ( token !== null );

const App = ({ fetchUser }) => {

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const isDarkMode = useSelector( state => state.isDark);
    const HeaderWithRouter = withRouter(Header);
    
    // const dispatch = useDispatch();

    // Check if token has expired or not on first render
    useEffect(() => {

        // dispatch({ type: CLEAR_CART });
        // setIsVideoModalOpen(true);
        if(validSession){
            fetchUser()
        }

    },[]);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <DarkThemeProvider>
                <Container>

                    <div className={ isDarkMode ? "container bp3-dark" : "container" }>

                        <div className="content">
                            <HeaderWithRouter/>

                            <Switch>

                                <Route path="/" exact component={props => <Home {...props} />} />

                                <Route path="/cart" component={props => <Cart {...props} />} />

                                <Route path="/profile" component={props => <Profile {...props} />} />

                                <Route path="/checkout" component={props => <Checkout {...props} />} />

                                <Route path="/signup" component={props => <Register {...props} />} />

                                <Route path="/thankyou" component={props => <Thankyou {...props} />} />

                                <Route path="/terminos-y-condiciones" component={props => <TerminosYCondiciones {...props} />} />

                                <Route path="/aviso-de-privacidad" component={props => <AvisoDePrivacidad {...props} />} />

                                <Route path="/auth/reset-password" component={props => <ResetPassword {...props} />} />

                                <Route path="/rebajas" component={props => <Rebajas {...props} />} />
                                
                                <Route path="/item/:slug" component={props => <ProductDetail {...props} />} />

                                <Route path="/:season/:category/:slug" component={props => <ProductDetail {...props} />} />

                                <Route path="/:category/:slug" component={props => <Category {...props} />} />

                            </Switch>
                        </div>
                        <Footer />
                        <Dialog
                            isOpen={ isVideoModalOpen }
                            // onClose={ () => setIsVideoModalOpen(false) }
                            className={ isDarkMode ? 'home-video bp3-dark' : 'home-video' }
                        >
                            <button onClick={() => setIsVideoModalOpen(false)}>X Close video</button>
                            <div className="video-viewport">
                                <video controls loop autoPlay muted preload="true">
                                    <source src="./video/REEL_INVIERNOV1.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </Dialog>
                    </div>

                </Container>
            </DarkThemeProvider>
        </BrowserRouter>

    )

}

const mapDispatchToProps = dispatch => {
  return {
      fetchUser: () => dispatch( fetchUser(token) )
  }
}

export default connect(null,mapDispatchToProps)(App);
