import React, { Component } from 'react'
import Dotdotdot from 'react-dotdotdot'

class Truncate extends Component {
	componentDidUpdate() {
		let text = this.text.innerText || this.text.textContent;
		this.text.innerHTML = text.replace(/(^|\s)(#[a-zA-Z\u00C0-\u017F\d-]+)/ig, "$1<span>$2</span>")
	}

	componentDidMount() {
		let text = this.text.innerText || this.text.textContent;
		this.text.innerHTML = text.replace(/(^|\s)(#[a-zA-Z\u00C0-\u017F\d-]+)/ig, "$1<span>$2</span>")
	}

	render() {
		return (
            <Dotdotdot clamp={ 3 } useNativeClamp={ false } { ...this.props}>
                <p className="product-description" ref={(text) => (this.text = text)} >
                    { this.props.text }
                </p>
            </Dotdotdot>
		)
	}
}

export default Truncate;
