import React from 'react';
import { Card, Elevation, H4, H5 } from "@blueprintjs/core";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Truncate from '../Shared/Truncate';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import numeral from 'numeral'

const ProductCard = ({ producto, slider }) => {

    const cardStyle = {
        height: '100%',
    };

    const getCard = () => {
        const sizes = producto.offer;
        const season = producto.brand ? producto.brand.name : '';

        const array_sizes = _.map( sizes, ( val, i ) => {
            return { 'price': parseFloat(val.price_value), 'old_price': parseFloat(val.old_price_value) };
        });

        const lowest = _( array_sizes )
            .groupBy('id')
            .map( (group) => _.minBy(group, 'price') )
            .value();

        return (
            <Card interactive={ true } elevation={ Elevation.ZERO } style={ cardStyle } className={ parseInt(producto.active) === 0 ? 'no-available transparent-bkg' : 'transparent-bkg'}>

                <div className="square-container">

                    {

                        producto.preview_image ? (

                            <LazyLoadImage
                                effect="blur"
                                // placeholderSrc={ producto.preview_image.path }
                                alt={producto.name}
                                src={producto.preview_image.path } />
                        ) : false

                    }

                </div>

                <div className={ slider ? 'card-title title' : 'row card-title title'}>

                    { slider ? (

                            <div className="card-title__name">
                                <H5>{ producto.name }</H5>
                            </div>

                        ) : (
                            <div className="col-sm-7 card-title__name">
                                <H4>{ producto.name }</H4>
                            </div>
                        )

                    }

                    { lowest.length > 0 ? (
                        <div className={ slider ? '' : 'col-sm-5 card-title__price' }>
                            { parseInt( lowest[0].old_price, 10 ) === 0 ? '' : ( <p>${ numeral(lowest[0].old_price).format('0,0.00') }</p> ) }
                            <H4>${ numeral(lowest[0].price).format('0,0.00') }</H4>
                        </div>
                    ) : "" }

                </div>
                
                { producto.preview_text ? (
                        <Truncate text={ producto.preview_text } />
                    ) : ''
                }
                
                { producto.category ? (
                        <p style={{ fontSize: '10px' }}>{producto.category.name } <span className="tarjeta_season">{ season ? season : '' }</span></p>
                    ) : ''
                }

            </Card>
        )
    }

    const getData = () => {


        if( producto ){

            const seasonSlug = producto.brand ? producto.brand.slug : 'coleccion';
            const categorySlug = producto.category ? producto.category.slug : 'categoria';

            return (
                <div className={ slider ? '' : 'col-md-12 col-lg-6 col-xl-4 card-item' }>
                    { parseInt(producto.active) === 1 ? (
                        <Link to={ "/" + seasonSlug + "/" + categorySlug + "/" + producto.slug } >
                            {getCard()}
                        </Link>
                    ) : (
                        getCard()
                    )}
                </div>
            )

        }
    }

    return  getData()

}

export default ProductCard;