import { CATEGORY_FILTER } from '../actions/types';

export function fetchCategoryFilter( state='none', action ) {

    switch( action.type ){

        case CATEGORY_FILTER:
            return action.payload;

        default:
            return state;

    }

}
