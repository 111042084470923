import React, { useEffect, useState } from 'react';
import { H2, H4, Card, FormGroup, InputGroup, Tooltip, Button, Intent } from "@blueprintjs/core";
import { useImmerReducer } from 'use-immer';
import { CSSTransition } from  'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { fetchResetPassword, fetchForgotPassword } from '../../actions';
import { Link } from 'react-router-dom';

const ResetPassword = ({ location }) => {

    const token = location.pathname.substring( location.pathname.lastIndexOf('/') + 1);
    const [ showPassword, setShowPassword ] = useState(false);
    const password_reset = useSelector( state => state.password_reset );
    const password_forgot_success = useSelector( state => state.password_forgot.success );
    const password_forgot_loading = useSelector( state => state.password_forgot.loading );
    const dispatchApp = useDispatch();
    const { success, loading } = password_reset;

    const initialState = {
        email: {
            value: "",
            hasErrors: true,
            message: "",
            isUnique: false,
            checkCount: 0
        },
        password: {
            value: "",
            hasErrors: true,
            message: ""
        },
        passwordConfirmation: {
            value: "",
            hasErrors: true,
            message: ""
        }
    }

    function ourReducer( draft, action ){

        switch ( action.type ) {

            case "emailInmmediately":
                draft.email.hasErrors = false
                draft.email.value = action.value

                if (draft.email.value.trim() === "" ) {
                    draft.email.hasErrors = true;
                    draft.email.message = "El email no puede quedar vacío"
                }

                return

            case "emailAfterDelay":

                if ( !/^\S+@\S+$/.test(draft.email.value) ) {
                    draft.email.hasErrors = true
                    draft.email.message = "Debes proporcionar un email válido."
                }

                if (!draft.email.hasErrors) {
                    draft.email.checkCount++
                }

                return

            case "passwordInmmediately":
                draft.password.hasErrors = false
                draft.password.value = action.value

                if ( draft.password.value.length > 64 ) {
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña no puede superar los 64 caracteres"
                }

                if (draft.password.value === "" ) {
                    draft.password.hasErrors = true;
                    draft.password.message = "Debes proporcionar una contraseña válida."
                }

                return

            case "passwordAfterDelay":

                if( draft.password.value.length < 8 ){
                    draft.password.hasErrors = true
                    draft.password.message = "La contraseña debe tener al menos 8 caracteres"
                }

                return

            case "submitForm":
                return
            default:

        }
    }

    const [ state, dispatch ] = useImmerReducer(ourReducer, initialState);

    useEffect(() => {
        if (state.password.value) {
            const delay  = setTimeout(() => {
                dispatch({ type: "passwordAfterDelay" })
            }, 800)

            return () => clearTimeout(delay)
        }
    }, [state.password.value]);

    useEffect(() => {
        if (state.email.value) {
            const delay  = setTimeout(() => dispatch({ type: "emailAfterDelay" }), 800)
            return () => clearTimeout(delay)
        }
    }, [state.email.value]);

    const lockButton = (
        <Tooltip content={`${showPassword ? "Ocultar" : "Mostrar"} Password`}>
            <Button
                icon={showPassword ? "eye-off" : "eye-open"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={ () => { setShowPassword(!showPassword) } }
            />
        </Tooltip>
    )



    const titleStyle = {
        textAlign: 'center',
        margin: '2rem 0',
        lineHeight: '2'
    }

    const handlePasswordReset = () => {

        const params = new URLSearchParams()
        params.append('reset_password_code', token);
        params.append('password', state.password.value);

        dispatchApp( fetchResetPassword(params) )

    }

    const handlePasswordForgot = () => {

        const params = new URLSearchParams();
        params.append('email', state.email.value);

        dispatchApp( fetchForgotPassword (params) )

    }


    if (token !== "reset-password") {

        if ( success ) {

            return (
                <div className="row">

                    <div className="col-6 offset-3">

                        <div style={{ alignSelf: 'center', textAlign: 'center' }} >

                            <H4 style={ titleStyle } >TÚ CONTRASEÑA SE HA CAMBIADO CORRECTAMENTE</H4>

                            <Link to={ '/profile' } >
                                <Button large={ true }  > INICIAR SESIÓN </Button>
                            </Link>

                        </div>

                    </div>
                </div>
            )

        } else {

            return (
                <div className="row">


                    <div className="col-6 offset-3">

                        <H2 style={ titleStyle } >CAMBIO DE CONTRASEÑA</H2>

                        <Card>
                            <FormGroup
                                label="Escribe la nueva contraseña:"
                                labelInfo="*"
                            >

                                <InputGroup value={ state.password.value } type={showPassword ? "text" : "password"} onChange={ e =>  dispatch({ type: "passwordInmmediately", value: e.target.value }) } id="password" rightElement={ lockButton } />

                                <CSSTransition in={ state.password.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                    <div className="alert alert-danger small liveValidateMessage"> { state.password.message } </div>
                                </CSSTransition>

                            </FormGroup>

                            {

                                !state.password.hasErrors && ( state.password.value.length >= 8 ) ? (

                                    <Button loading={ loading } large={ true } style={{ width:'100%' }} onClick={ () => handlePasswordReset() } > CONTINUAR </Button>

                                ) : (

                                    <Button large={ true } style={{ width:'100%' }} disabled  > CONTINUAR </Button>

                                )

                            }

                        </Card>

                    </div>

                </div>

            )

        }


    } else {

        if (!password_forgot_success) {

            return (

                <div className="row">


                    <div className="col-6 offset-3">

                        <H2 style={ titleStyle } >REESTABLECE TU CONTRASEÑA</H2>

                        <Card>
                            <FormGroup
                                label="Escribe tú Email"
                                labelInfo="*"
                            >
                                <InputGroup value={ state.email.value } onChange={ e =>  dispatch({ type: "emailInmmediately", value: e.target.value }) } id="email" />
                                <CSSTransition in={ state.email.hasErrors } timeout={ 330 } classNames="liveValidateMessage" unmountOnExit >
                                    <div className="alert alert-danger small liveValidateMessage"> { state.email.message } </div>
                                </CSSTransition>
                            </FormGroup>

                            {
                                !state.email.hasErrors ? (

                                    <Button loading={ password_forgot_loading } large={ true } style={{ width:'100%' }} onClick={ () => handlePasswordForgot() } > CONTINUAR </Button>

                                ) : (

                                    <Button disabled large={ true } style={{ width:'100%' }} > CONTINUAR </Button>

                                )
                            }

                        </Card>

                    </div>

                </div>

            )

        } else {

            return (
                <div className="row">

                    <div className="col-6 offset-3">

                        <div style={{ alignSelf: 'center', textAlign: 'center' }} >

                            <H4 style={ titleStyle } >ABRE TU EMAIL DE 'SUPERSTICIÓN MX' <br /> Y SIGUE LAS INSTRUCCIONES</H4>

                            <Link to={ '/profile' } >
                                <Button large={ true }  > INICIAR SESIÓN </Button>
                            </Link>

                        </div>

                    </div>
                </div>
            )

        }

    }


}

export default ResetPassword;
