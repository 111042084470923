import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_QUANTITY } from '../actions/types';
import { CLEAR_CART } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster'
import { Intent } from "@blueprintjs/core";

const initialState = {
  cart: []
};

export function cartReducer( state = initialState, action ) {

    let cart = state.cart;

    switch( action.type ) {

        case ADD_TO_CART:

            cart.push( action.payload );

            return {
                ...state,
                cart: cart
            };

        case UPDATE_CART_QUANTITY:

            let item = cart.find( item => item.product.id ===  action.payload.productId);
            let newCart = cart.filter( item => item.product.id  !== action.payload.productId );

            item.quantity = action.payload.quantity;

            newCart.push( item );

            return {
                ...state,
                cart: newCart
            };

        case REMOVE_FROM_CART:

            if(action.payload.modelName) {
                AppToaster.show({ message: `${ action.payload.modelName } eliminado del carrito`, intent: Intent.DANGER});
            }

            return {
                ...state,
                cart: cart.filter( item => item.product.id !== action.payload.productId )
            };

        case CLEAR_CART:

            return {
                ...state,
                cart: []
            };

        default:
            return state;
    }
};

export default cartReducer;
