import React, { useEffect } from 'react';
import { Spinner } from '@blueprintjs/core';
import { connect, useSelector } from 'react-redux';
import { fetchProducts, fetchMostSelledProducts } from '../../actions';
import _ from 'lodash';

// Components
import ProductSlider from '../ProductSlider';
import ProductGrid from '../ProductGrid';

import './styles.scss'

const Home = ({ fetchProducts, fetchMostSelledProducts }) => {

    const listaProductos = useSelector( state => state.listaProductos );
    let listado_productos = listaProductos.productos;
    const { loading, success } = listaProductos;
    let listaMostSelled = useSelector( state => state.most_selled);
    
    const filterUpdated = useSelector( state => () => { return true } );
    
    
    // Se obtienen los productos activos
    listado_productos = _.filter( listado_productos, item => parseInt(item.active) === 1 );
    listado_productos = _.orderBy( listado_productos, ['updated_at'], ['desc'] );    
    listado_productos = _.orderBy( listado_productos, (obj) => parseInt(obj.brand_id), 'desc');
    
    listaMostSelled = _.filter( listaMostSelled.products, item => parseInt(item.active) === 1 );

    useEffect(() => {
        fetchProducts();
        fetchMostSelledProducts();
    },[]);

    const getData = () => {

        filterUpdated();

        if ( success ) {

            if( listado_productos.length > 0 ){
                
                const lastCollection = _.filter( listado_productos, item => item.brand_id === listado_productos[0].brand_id );
                const brandName = listado_productos[0].brand ? listado_productos[0].brand.name : '';
                const lastCollectionTitle = "ÚLTIMA COLLECIÓN " + brandName;

                return (
                    <div className='homepage'>
                        { lastCollection.length > 0 ? <ProductSlider listado_productos={lastCollection} title={lastCollectionTitle} /> : '' }
                        <ProductSlider listado_productos={listaMostSelled} title={"LOS + VENDIDOS"} />
                        <ProductGrid listado_productos={listado_productos} productFilterType={"sales"} isShowAll={false} />
                        <ProductGrid listado_productos={listado_productos} productFilterType={"collections"} isShowAll={true} />            
                    </div>
                )

            } else {

                return (
                    <div className="row">
                        <div className="spinner-container" style={{ alignItems:'center' }}>
                            NO PRODUCTS
                        </div>
                    </div>
                )
            }

        } else {

            return (
                <div className="row">
                    <div className="spinner-container" style={{ alignItems:'center' }}>
                        HUBO UN ERROR
                    </div>
                </div>
            )
        }


    }

    if ( !loading ) {

        return getData()

    } else {

        return (
            <div className="row">
                <div className="spinner-container">
                    <Spinner />
                </div>
            </div>
        )

    }


}

const mapDispatchToProps = dispatch => {
  return {
      fetchProducts: () => dispatch( fetchProducts() ),
      fetchMostSelledProducts: () => dispatch( fetchMostSelledProducts() )
  }
}

export default connect(null, mapDispatchToProps)(Home);
