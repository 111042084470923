import { FETCH_FORGOT_PASSWORD_REQUEST, FETCH_FORGOT_PASSWORD_SUCCESS, FETCH_FORGOT_PASSWORD_FAILURE } from '../actions/types';
import { AppToaster } from '../Components/Shared/Toaster'
import { Intent } from "@blueprintjs/core";

const initialState = {
    loading: false,
    success: false,
    error: ''
}

export function fetchForgotPassword( state=initialState, action ){
    switch( action.type ){

        case FETCH_FORGOT_PASSWORD_REQUEST:
            console.log("== FETCH_FORGOT_PASSWORD_REQUEST ==" )
            return {
                ...state,
                loading: true
            }

        case FETCH_FORGOT_PASSWORD_SUCCESS:

            // AppToaster.show({ message: "Tú contraseña se ha cambiado correctamente", intent: Intent.PRIMARY, });

            return {
                loading: false,
                success: true,
                error: ''
            }

        case FETCH_FORGOT_PASSWORD_FAILURE:


            console.log( "FETCH_FORGOT_PASSWORD_FAILURE" )

            if ( action.payload.response.data.error ) {

                if ( action.payload.response.data.error === "user_not_found") {

                    AppToaster.show({ message: "Email no registrado", intent: Intent.DANGER, });

                } else {

                    AppToaster.show({ message: action.payload.response.data.error, intent: Intent.DANGER, });

                }


            } else {

                const errors = [];

                for (const key of Object.keys(action.payload.response.data.errors)) {
                    action.payload.response.data.errors[key].forEach( err => {
                        errors.push(err)
                    });

                }

                errors.forEach( item => {

                    AppToaster.show({ message: item, intent: Intent.DANGER, });

                });


            }


            return  {
                loading: false,
                success: false,
                error: ''
            }

        default:
            return state;
    }
}
