import React from 'react';
import { Card, H1, H5 } from "@blueprintjs/core";
import { Redirect, Link } from 'react-router-dom';

const Thankyou = (props) => {

    const token = localStorage.getItem( "session" );
    const validSession = ( token !== null );

    const getData = () => {
        return (
            <Card className="text-center">
                <H1>¡THANK YOU!</H1>
                <H5>Puedes rastrear el status de tu pedido <Link to="/profile"><span className="btn">Aquí</span></Link></H5>
            </Card>
        )
    }

    if( props.location.key && validSession ){

        return getData();

    } else {

        return (
            <Redirect to="/" />
        )

    }


}

export default Thankyou;
