import { FETCH_BRANDS_REQUEST, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAILURE } from '../actions/types';
import { FETCH_MOSTSELLED_REQUEST, FETCH_MOSTSELLED_SUCCESS, FETCH_MOSTSELLED_FAILURE } from '../actions/types';
import { FETCH_COUPONS_REQUEST, FETCH_COUPONS_SUCCESS, FETCH_COUPONS_FAILURE } from '../actions/types';
import { TOGGLE_DARKTHEME } from '../actions/types';

import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_QUANTITY } from '../actions/types'

import { FETCH_LOGIN_USER_REQUEST, FETCH_LOGIN_USER_SUCCESS, FETCH_LOGIN_USER_FAILURE } from '../actions/types';
import { FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE } from '../actions/types';
import { FETCH_REGISTER_USER_REQUEST, FETCH_REGISTER_USER_SUCCESS, FETCH_REGISTER_USER_FAILURE } from '../actions/types';
import { FETCH_CREATE_ORDER_REQUEST, FETCH_CREATE_ORDER_SUCCESS, FETCH_CREATE_ORDER_FAILURE, FETCH_END_ORDER } from '../actions/types';
import { FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../actions/types';

import { ADDRESS_FORM } from '../actions/types'
import { FETCH_UPDATE_ADDRESS_REQUEST, FETCH_UPDATE_ADDRESS_SUCCESS, FETCH_UPDATE_ADDRESS_FAILURE } from '../actions/types';

import { FETCH_RESET_PASSWORD_REQUEST, FETCH_RESET_PASSWORD_SUCCESS, FETCH_RESET_PASSWORD_FAILURE } from '../actions/types'
import { FETCH_FORGOT_PASSWORD_REQUEST, FETCH_FORGOT_PASSWORD_SUCCESS, FETCH_FORGOT_PASSWORD_FAILURE } from '../actions/types'

import { PRODUCT_FILTER, CATEGORY_FILTER } from '../actions/types';
import { PRICE, IS_DISCOUNT } from '../actions/types';
import { CLEAR_CART } from '../actions/types';

import { API_URL } from '../config.js'
import axios from 'axios';


// PRODUCTS REQUEST

const fetchProductsRequest = () => {
    return {
        type: FETCH_BRANDS_REQUEST
    }
}

const fetchProductsSuccess = (productos) => {
    return {
        type: FETCH_BRANDS_SUCCESS,
        payload: productos
    }
}

const fetchProductsFailure = (error) => {
    return {
        type: FETCH_BRANDS_FAILURE,
        payload: error
    }
}

export const fetchProducts = () => {
    return function(dispatch){
        dispatch(fetchProductsRequest);
        axios.get(API_URL + 'catalogo')
            .then( res => {
                dispatch(fetchProductsSuccess(res.data));
            })
            .catch((error) => {
                dispatch(fetchProductsFailure(error.message))
            })
    }
}

// MOST SELLED PRODUCTS

const fetchMostSelledProductsRequest = () => {
    return {
        type: FETCH_MOSTSELLED_REQUEST
    }
}

const fetchMostSelledProductsSuccess = (productos) => {
    return {
        type: FETCH_MOSTSELLED_SUCCESS,
        payload: productos
    }
}

const fetchMostSelledProductsFailure = (error) => {
    return {
        type: FETCH_MOSTSELLED_FAILURE,
        payload: error
    }
}

export const fetchMostSelledProducts = () => {
    return function(dispatch){
        dispatch(fetchMostSelledProductsRequest);
        axios.get(API_URL + 'api/most-selled-products')
            .then( res => {
                dispatch(fetchMostSelledProductsSuccess(res.data));
            })
            .catch((error) => {
                dispatch(fetchMostSelledProductsFailure(error.message))
            })
    }
}

// COUPONS

export const fetchCoupons = () => {

    return async dispatch => {

        dispatch( { type: FETCH_COUPONS_REQUEST } );

        function onSuccess(success) {
            dispatch( { type: FETCH_COUPONS_SUCCESS, payload: success } );
            return success;
        }

        function onError(error) {
            dispatch( { type: FETCH_COUPONS_FAILURE, payload: error } );
            return error;
        }

        try {
            const success = await axios.get(API_URL + 'coupons');
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }

    }
}

// DARK MODE
export const toggleDarkTheme = () => {
    return {
        type: TOGGLE_DARKTHEME
    }
}


// HOME FILTERS
export const fetchProductFilter = (filter) => {
    return {
        type: PRODUCT_FILTER,
        payload: filter
    }
}

export const fetchCategoryFilter = (filter) => {
    return {
        type: CATEGORY_FILTER,
        payload: filter
    }
}

// SHOW FORM STEP
export const fetchSetAddressForm = (val) => {
    return {
        type: ADDRESS_FORM,
        payload: val
    }
}

// CART

export const addToCart = (product) => {

    return {
        type: ADD_TO_CART,
        payload: {
            product,
            quantity: 1
        }
    }
};

export const removeFromCart = (productId, modelName) => {

    return {
        type: REMOVE_FROM_CART,
        payload: {
            productId,
            modelName
        }
    }
};

export const updateCartQuantity = (productId, quantity) => {

  return {
      type: UPDATE_CART_QUANTITY,
      payload: {
          productId,
          quantity
      }
  }
};

export const clearCart = () => {

    return {
        type: CLEAR_CART
    }
};


// PRICE OBJECT
export const fetchPrice = (price) => {
    return {
        type: PRICE,
        payload: price
    }
}

export const fetchIsDiscount = (value) => {
    return {
        type: IS_DISCOUNT,
        payload: value
    }
}


// USER

export const fetchRegisterUser = user => {

    return async dispatch => {

        dispatch( { type: FETCH_REGISTER_USER_REQUEST } );

        function onSuccess(success) {
            dispatch({ type: FETCH_REGISTER_USER_SUCCESS, payload: success });

            const login_email = user.get('email')
            const login_password = user.get('password')

            const user_login = new URLSearchParams()
            user_login.append('login', login_email);
            user_login.append('password', login_password);

            dispatch( fetchLoginUser(user_login) )

            return success;
        }

        function onError(error) {
            dispatch({ type: FETCH_REGISTER_USER_FAILURE, payload: error });
            return error;
        }

        try {
            const success = await axios.post(API_URL + 'api/auth/register', user);
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }

    }
}

export const fetchLoginUser = user => {

    return async dispatch => {

        dispatch( { type: FETCH_LOGIN_USER_REQUEST } );

        function onSuccess(success) {
            dispatch( { type: FETCH_LOGIN_USER_SUCCESS, payload: success } );
            return success;
        }

        function onError(error) {
            dispatch( { type: FETCH_LOGIN_USER_FAILURE, payload: error } );
            return error;
        }

        try {
            const success = await axios.post(API_URL + 'api/auth/login', user);
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }

    }
}

export const fetchUser = token => {

    return async dispatch => {

        dispatch( { type: FETCH_LOGIN_USER_REQUEST } );

        await axios.get(API_URL + 'api/auth/me?token=' + token)
            .then( res => {
                dispatch( { type: FETCH_USER_SUCCESS, payload: res } );
            })
            .catch((error) => {
                dispatch( { type: FETCH_USER_FAILURE, payload: error } );
            })

    }
}

export const fetchUserProfile = user_id => {

    const token = localStorage.getItem( "session" );

    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    return async dispatch => {

        dispatch( { type: FETCH_USER_PROFILE_REQUEST } );

        await axios.get(API_URL + 'api/user-orders', authorization)
            .then( res => {
                const user_order = res.data.find( producto => producto.id === user_id );
                dispatch( { type: FETCH_USER_PROFILE_SUCCESS, payload: user_order.orders } );
            })
            .catch( error => {
                console.log("ERROR", error)
                dispatch( { type: FETCH_USER_PROFILE_FAILURE, payload: error } );
            })

    }
}


// Edit user address

export const fetchUpdateAddress = address => {

    const token = localStorage.getItem( "session" );

    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    return async dispatch => {

        dispatch( { type: FETCH_UPDATE_ADDRESS_REQUEST } );

        function onSuccess(success) {
            console.log("success -->", success)
            dispatch( { type: FETCH_UPDATE_ADDRESS_SUCCESS, payload: success } );
            return success;
        }

        function onError(error) {
            console.log("error -->", error)
            dispatch( { type: FETCH_UPDATE_ADDRESS_FAILURE, payload: error } );
            return error;
        }

        try {
            const success = await axios.put(API_URL + 'api/user', address, authorization);
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }

    }
}

// RESET-PASSWORD

export const fetchResetPassword = params => {

    return async dispatch => {

        dispatch( { type: FETCH_RESET_PASSWORD_REQUEST } );

        function onSuccess(success) {

            dispatch( { type: FETCH_RESET_PASSWORD_SUCCESS, payload: success } );
            return success;

        }

        function onError(error) {

            dispatch( { type: FETCH_RESET_PASSWORD_FAILURE, payload: error } );
            return error;

        }

        try {

            const success = await axios.post( API_URL + 'api/auth/reset-password', params );
            return onSuccess(success);

        } catch (error) {

            return onError(error);

        }

    }
}

// FORGOT-PASSWORD

export const fetchForgotPassword = email => {

    return async dispatch => {

        dispatch( { type: FETCH_FORGOT_PASSWORD_REQUEST } );

        function onSuccess(success) {

            dispatch( { type: FETCH_FORGOT_PASSWORD_SUCCESS, payload: success } );
            return success;

        }

        function onError(error) {

            dispatch( { type: FETCH_FORGOT_PASSWORD_FAILURE, payload: error } );
            return error;

        }

        try {

            const success = await axios.post( API_URL + 'api/auth/forgot-password', email );
            return onSuccess(success);

        } catch (error) {

            return onError(error);

        }

    }
}


// ORDER

export const fetchCreateOrder = order => {

    const token = localStorage.getItem( "session" );

    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    return async dispatch => {

        dispatch({ type: FETCH_CREATE_ORDER_REQUEST });

        function onSuccess(success) {

            dispatch({ type: FETCH_CREATE_ORDER_SUCCESS, payload: success });
            dispatch({ type: CLEAR_CART });
            dispatch(fetchIsDiscount(0));
            
            return success;
        }

        function onError(error) {

            dispatch({ type: FETCH_CREATE_ORDER_FAILURE, payload: error });
            return error;
        }

        try {
            const success = await axios.post(API_URL + 'api/create-order', order, authorization);
            return onSuccess(success);
        } catch (error) {
            return onError(error);
        }

    }
}

export const endOrder = () => {
    return {
        type: FETCH_END_ORDER
    }
}
