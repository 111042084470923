import React from 'react';
import { H2,H5 } from "@blueprintjs/core";
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCard';
import Slider from "react-slick";

import './styles.scss'

const ProductSlider = ({listado_productos, title, hero}) => {

    const isDarkMode = useSelector( state => state.isDark);

    const getData = () => {
    
        const displayProducts = listado_productos.map( ( producto,i ) => <ProductCard key={ i } producto={ producto } slider={true} /> )

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4.5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    arrows: false,
                    settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 991,
                    arrows: false,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                    }
                    }
            ]
        };

        return (
            
            <div className='mostSelledProducts'>
                <div className='section-title'>
                    <H5 className={ isDarkMode ? 'dark' : 'white' }><span>{title}</span></H5>
                </div>
                { hero ? (
                    <div className="section-hero" >
                        <H2>SS 24</H2>
                        <img src={hero} alt={title} />
                    </div>
                )  : ''}
                <Slider {...settings}>
                    { displayProducts }
                </Slider>
            </div>
            
        )
    }

    return (
        <section className='section'>
            { getData() }
        </section>
    )

}

export default ProductSlider;