export const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAILURE = 'FETCH_BRANDS_FAILURE';
export const FETCH_MOSTSELLED_REQUEST = 'FETCH_MOSTSELLED_REQUEST';
export const FETCH_MOSTSELLED_SUCCESS = 'FETCH_MOSTSELLED_SUCCESS';
export const FETCH_MOSTSELLED_FAILURE = 'FETCH_MOSTSELLED_FAILURE';
export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE';
export const TOGGLE_DARKTHEME = 'TOGGLE_DARKTHEME';
export const PRODUCT_FILTER = 'PRODUCT_FILTER';
export const PRICE = 'PRICE';
export const IS_DISCOUNT = 'IS_DISCOUNT';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const FETCH_LOGIN_USER_REQUEST = 'FETCH_LOGIN_USER_REQUEST';
export const FETCH_LOGIN_USER_SUCCESS = 'FETCH_LOGIN_USER_SUCCESS';
export const FETCH_LOGIN_USER_FAILURE = 'FETCH_LOGIN_USER_FAILURE';
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_REGISTER_USER_REQUEST = 'FETCH_REGISTER_USER_REQUEST';
export const FETCH_REGISTER_USER_SUCCESS = 'FETCH_REGISTER_USER_SUCCESS';
export const FETCH_REGISTER_USER_FAILURE = 'FETCH_REGISTER_USER_FAILURE';
export const FETCH_CREATE_ORDER_REQUEST = 'FETCH_CREATE_ORDER_REQUEST';
export const FETCH_CREATE_ORDER_SUCCESS = 'FETCH_CREATE_ORDER_SUCCESS';
export const FETCH_CREATE_ORDER_FAILURE = 'FETCH_CREATE_ORDER_FAILURE';
export const FETCH_END_ORDER = 'FETCH_END_ORDER';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CLEAR_CART = 'CLEAR_CART';
export const ADDRESS_FORM = 'ADDRESS_FORM';
export const FETCH_RESET_PASSWORD_REQUEST = 'FETCH_RESET_PASSWORD_REQUEST';
export const FETCH_RESET_PASSWORD_SUCCESS = 'FETCH_RESET_PASSWORD_SUCCESS';
export const FETCH_RESET_PASSWORD_FAILURE = 'FETCH_RESET_PASSWORD_FAILURE';
export const FETCH_FORGOT_PASSWORD_REQUEST = 'FETCH_FORGOT_PASSWORD_REQUEST';
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS';
export const FETCH_FORGOT_PASSWORD_FAILURE = 'FETCH_FORGOT_PASSWORD_FAILURE';
export const FETCH_UPDATE_ADDRESS_REQUEST = 'FETCH_UPDATE_ADDRESS_REQUEST';
export const FETCH_UPDATE_ADDRESS_SUCCESS = 'FETCH_UPDATE_ADDRESS_SUCCESS';
export const FETCH_UPDATE_ADDRESS_FAILURE = 'FETCH_UPDATE_ADDRESS_FAILURE';
