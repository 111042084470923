import { PRICE } from '../actions/types';

const initialState = {
    price: null,
}

export function fetchPrice( state=initialState, action ) {

    switch( action.type ){

        case PRICE:
            return {
                price: action.payload
            }

        default:
            return state;

    }

}
