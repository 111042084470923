import React, { useState } from 'react';
import { H5, H6, Checkbox, Button, Intent, RadioGroup, Radio } from '@blueprintjs/core';
import Select from 'react-select';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from "react-redux";
import Slider from "react-slick";

import ProductCard from '../ProductCard';

import './styles.scss'

const ProductGrid = ({listado_productos, productFilterType, isShowAll}) => {
    
    const [selected, setSelected] = useState([]);
    const [categorySelector, setCategorySelector] = useState({ label: "Filtrar por categoría" });
    const [selectedCollection, setSelectedCollection] = useState(0);
    const [showAll, setShowAll] = useState(isShowAll);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
    const isDarkMode = useSelector( state => state.isDark);

    // CATEGORY FILTER
    const onChangeHandler = id => () => {
        selected.includes(id) ? setSelected(selected.filter(x => x !== id)) : setSelected([...selected, id]);
    };

    const onRemove  = () => {
        setSelected([]);
        setCategorySelector({ label: "Filtrar por categoría" });
    };

    // CATEGORY HANDLER (mobile)
    const handleCategorySelector = ( selectedCategory ) => {
        setCategorySelector(selectedCategory)
    }

    // COLLECTION HANLDER
    const handleCollectionSelector = ( e ) => {
        setSelectedCollection(parseInt(e.value));
    }

    const getSizesSelector = ( array_checks ) => {

        const removeBTNSelectorStyle = {
            position: 'absolute',
            right: 0,
            zIndex: 99,
            top: 0,
            botttom: 0,
        }

        return(

            <div style={{ position:'relative' }}>

                    <Select
                        value={ categorySelector }
                        onChange={ handleCategorySelector }
                        options={ array_checks }
                        classNamePrefix='sizes'
                        className="sizes-selector"
                    />

                    { categorySelector.id ? <Button minimal icon="delete" style={ removeBTNSelectorStyle } onClick={ onRemove } intent={ Intent.DANGER } large={ true } ></Button> : false }
            </div>

        )

    }

    const getSectionTitle = (listado_productos) => {
        if(listado_productos.length > 0) {
            if(productFilterType === 'collections'){
                return (
                    <div className='section-title'>
                        <H5 className={ isDarkMode ? 'dark' : 'white' }><span>{ listado_productos[0].brand ? listado_productos[0].brand.preview_text : 'COLECCIONES' }</span></H5>
                    </div>
                )
            } else if (productFilterType === 'sales'){
                 return (
                     <div className='section-title'>
                         <H5 className={ isDarkMode ? 'dark' : 'white' }><span>REBAJAS</span></H5>
                     </div>
                 )
            }
        }
    }

    const getData = () => {

        const all = _.filter( listado_productos, item => item.active === 1 );
        const sales = _.filter( listado_productos, item => item.offer.some( sizes => sizes.discount_price_value > 0 ) );
        const hot = _.filter( listado_productos, item => item.additional_category.some( s => s.slug === 'hot' ) );

        switch ( productFilterType ) {

            case 'sales':
                listado_productos = sales
                break;
            case 'hot':
                listado_productos = hot
                break;
            default:
                listado_productos = all
                break;
        }

        
        ////////////////////////////////////
        // COLLECTIONS LIST

        let array_collection = _.map( listado_productos, ( val, i ) => {

            if (val.brand) {

                return {
                    'id': val.brand.id,
                    'slug': val.brand.slug,
                    'label': val.brand.name,
                    'value': val.brand.slug,
                    'active': val.brand.active
                }

            }
        });

        array_collection = _.without(array_collection, undefined);
        array_collection = _.uniqBy(array_collection, 'id');
        array_collection = _.orderBy(array_collection, (obj) => parseInt(obj.id), 'desc');

        const collectionFiltered = _.filter( listado_productos, i => parseInt(i.brand_id) === selectedCollection );

        const getLastCollectionID = array_collection[0] ? parseInt(array_collection[0].id) : 10 ;
        let lastCollection = _.filter( listado_productos, i => parseInt(i.brand_id) === getLastCollectionID );

        // set last COLLECTION to default listado_productos
        if(productFilterType === 'collections') {
            listado_productos = (selectedCollection !== 0) ? collectionFiltered : lastCollection
        }

        ////////////////////////////////////
        // CATEGORIES LIST

        let array_checks = _.map( listado_productos, ( val, i ) => {

            if (val.category) {
                return {
                    'id': val.category.id,
                    'name': val.category.slug,
                    'label': val.category.name,
                    'checked': false,
                    'value': val.category.slug,
                };
            }

        });

        array_checks = _.uniqBy(array_checks, 'id');
        array_checks = _.orderBy( array_checks, ['label'], ['asc'] );


        // ARRAY CATEGORIES

        let combinedArr = [];

        if (selected.length > 0) {

            selected.forEach( id => {

                let arr1 = _.filter( listado_productos, i => i.category_id === parseInt(id) );

                combinedArr.push(arr1)

            });

        }

        // Reduce category list
        let reduce_categories = _.reduce(combinedArr, ( f, o ) => f.concat(o), []).reverse();
        // reduce_categories = _.orderBy( reduce_categories, ['name'], ['asc'] );

        // MOBILE CATEGORY SELECTOR
        const categoryFiltered = _.filter( listado_productos, i => i.category_id === parseInt(categorySelector.id) )

        ////////////////////////////////////

        // Display items
        let set_display;

        if (selectedCollection !== 0) {

            set_display = (reduce_categories.length > 0) ? reduce_categories : (
                isMobile ? (categoryFiltered.length > 0 ? categoryFiltered : listado_productos) : collectionFiltered
            )

        } else {

            set_display = (reduce_categories.length > 0) ? reduce_categories : productFilterType === 'collections'  ? lastCollection : listado_productos;

        }

        const displayProductsGrid = set_display.map( ( producto,i ) => <ProductCard key={ producto.id } producto={ producto } slider={false} /> )
        const displayProductsSlider = set_display.map( ( producto,i ) => <ProductCard key={ producto.id } producto={ producto } slider={true} /> )

        // console.log("displayProductsGrid", set_display);

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4.5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                    }
                }
            ]
        };

        if (displayProductsGrid.length > 0 ){
            return (
                <div className={ !isTablet && !showAll ? 'gridContainer gridContainer--onlyfirst' : 'gridContainer' }>
    
                    { getSectionTitle(set_display) }
    
                    <div className="row">
    
                        <div className="col-md-3 col-lg-3 col-xl-2">
                            { productFilterType === 'collections' ? (
                                <div className="row side-filters">
                                    <div className="col-sm-12">
                                        <H6>COLECCIÓN:</H6>
                                        <RadioGroup
                                            onChange={ v => handleCollectionSelector(v.target)}
                                            selectedValue={ selectedCollection && selectedCollection !== 0 ? selectedCollection.toString() : getLastCollectionID.toString() }
                                        >
    
                                            {
                                                array_collection.map(i => <Radio label={ "Temporada " + i.label} value={ i.id } key={ i.id } /> )
                                            }
    
                                            {/*<Radio label="All" value="0" />*/}
    
                                        </RadioGroup>
                                    </div>
                                
                                </div>
                            ) : '' }
                            <div className="row side-filters">
                                <div className="col-sm-12">
    
                                    {/* <H6>CATEGORÍA:</H6> */}
    
                                    { !isMobile ? (
    
                                            array_checks.map(i => (
    
                                                <Checkbox
                                                    key={ i.id }
                                                    name={ i.name }
                                                    label={ i.label }
                                                    checked={ selected.includes( i.id ) }
                                                    onChange={ onChangeHandler( i.id ) }
                                                />
    
                                            ))
    
                                        ) : getSizesSelector(array_checks)
                                    }
    
    
                                </div>
                                <div className="col-sm-12">
                                    { selected.length > 0 ? <Button minimal icon="delete" onClick={ onRemove } intent={ Intent.DANGER } >Clear categories</Button> : "" }
                                </div>
                            </div>
                        </div>
    
                        <div className="col-md-9 col-lg-9 col-xl-10 catalog-grid">
                            
                            {
                                isTablet ? (
                                    <Slider {...settings}>
                                        { displayProductsSlider }
                                    </Slider>
                                ) : (
                                    <div className="row">
                                        { displayProductsGrid }
                                    </div>
                                )
                            }
    
                            {
                                !isTablet && !showAll && set_display.length > 0 ?  (
    
                                    <Button className="full-width" onClick={ () => { setShowAll(true) } }>VER TODAS LAS OFERTAS</Button>
                                   
                                ) : ''
                            }
    
                        </div>
    
                    </div>
                </div>
            )
        }
        
    }

    return (
        <section className='section'>
            { getData() }
        </section>
    )

}

export default ProductGrid;
